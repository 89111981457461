export const HEROKU_AUTHORIZATION = 'Bearer 9f9cdb30-a2f6-47d2-8508-fd4f9034f837';

export const HEROKU_REST_URL = '/restHelper';

export const HEROKU_LOYALTY_URL = '/persistLoyalty';

export const HEROKU_REDEEM_URL = '/processRedeem';

export const HEROKU_VOUCHER_URL = '/processVoucher';

export const HEROKU_RECEIPT_URL = '/addReceipt';

export const HEROKU_CASE_URL = '/addCase';

export const HEROKU_GAMIFICATIONSRECEIVE_URL = '/addGamificationsReceived';

export const HEROKU_INVOICE_URL = '/addInvoice';

export const HEROKU_VALIDATE_USER_URL = '/validateUser';

export const HEROKU_EMAIL_VALIDATE_CODE_URL = '/emailValidateCode';

export const HEROKU_UPDATE_PASSWORD_DATA_URL = '/updatePasswordData/';


export const HEROKU_INVOICE_REANALYSIS_REQUEST_URL = '/requestInvoiceReanalysis';

export const HEROKU_INVOICE_DELETE_URL = '/deleteInvoice';

export const HEROKU_TERM_MEMBER_URL = '/currentSalesTeamMember';

export const HEROKU_MANAGEMENT_TEAM_URL = '/managementSalesTeam';

export const HEROKU_REST_BUSINESS_URL = '/requestRestBusiness';

export const HEROKU_SET_BUSINESS_CYCLE_STATUS_URL = '/setBusinessCycleStatus';

export const HEROKU_SET_POINT_ANTECIPATION_STATUS_URL = '/setPointAntecipationStatus';

export const HEROKU_GET_PERFORMANCE_URL = '/performance';

export const HEROKU_ACCEPT_AGREEMENT = '/acceptAgreement';

export const HEROKU_BUSINESS_DEPARTMENT_URL = '/updateDepartment';

