import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';

export const useCreateContactAction = () => {
  const returnNameDocument = (action) => {
    const actinsTypeNews = [
      'ACTION_CLICK_CARD_PRODUCT',
      'ACTION_CLICK_CARD_REDEEM',
      'ACTION_CLICK_CARD_TRAIL',
      'ACTION_CLICK_CARD_MISSION',
      'ACTION_CLICK_STEP_TRAIL',
      'ACTION_CLICK_CARD_REDEEM_HOME',
      'ACTION_CLICK_CARD_TRAIL_HOME',
      'ACTION_CLICK_CARD_MISSION_HOME',
      'ACTION_STARTUP_MESSAGE',
      'ACTION_SHARING_NEWS',
      'ACTION_REDEEM_SUCCESS',
      'ACTION_DOWNLOAD',
      'ACTION_FAVORITE',
      'ACTION_UNFAVORITE',
    ];
    const actinsTypeAdvertising = ['ACTION_CLICK_ADS'];

    if (actinsTypeNews.includes(action)) {
      return 'Other';
    }
    if (actinsTypeAdvertising.includes(action)) {
      return 'Advertising';
    }
    return 'Other';
  };

  const createContactAction = async (props) => {
    // userDataHelper,
    // cgny2__Action__c = null,
    // cgny2__GenericId__c = null,
    // cgny2__Event__c = null,
    // cgny2__Value__c = null,
    // cgny2__AdvertisingItem__c = null,
    // cgny2__Gallery__c = null,
    // cgny2__News__c = null,
    // cgny2__Survey__c = null,
    // cgny2__SurveyQuestionOption__c = null,
    // cgny2__SurveyQuestion__c = null,
    // cgny2__Voucher__c = null,
    // appUser
    const documentName = returnNameDocument(props.cgny2__Action__c);

    const userData = await props.userDataHelper.getUser();
    if (!userData || process.env.STOP_CONTACT_ACTIONS) {
      return {
        erro: true,
        message: 'não foi possível gerar essa ação',
      };
    }

    let firebaseId = null;
    if (props.appUser) {
      firebaseId = props.appUser['FirebaseId__c'];
    } else {
      firebaseId = await props.userDataHelper.getUserFirebaseId();
    }
    const { userDataHelper, ...rest } = props;
    try {
      const contactActionData = {
        ContactAction: {
          cgny2__ActionDate__c: new Date().toISOString(),
          'cgny2__Contact__r.cgny2__FirebaseId__c': firebaseId,
          // cgny2__Action__c: null,
          // cgny2__GenericId__c: null,
          // cgny2__Event__c: null,
          // cgny2__AdvertisingItem__c: null,
          // cgny2__Gallery__c: null,
          // cgny2__News__c: null,
          // cgny2__Survey__c: null,
          // cgny2__SurveyQuestionOption__c: null,
          // cgny2__SurveyQuestion__c: null,
          // cgny2__Voucher__c: null,
          cgny2__Value__c: null,
          Value__c: null,
          Document__c: null,
          Campaign__c: null,
          CampaignItem__c: null,
          Product__c: null,
          ProductItem__c: null,
          tvs_Partner__c: null,
          ...rest,
        },
        ActionDate: serverTimestamp(),
      };

      const actionDocRef = doc(
        props.userDataHelper.db,
        'ContactActions',
        documentName
      );
      const actionDocData = await getDoc(actionDocRef);

      if (!actionDocData.exists()) {
        await setDoc(actionDocRef, {});
      }

      let DocReference = collection(
        props.userDataHelper.db,
        'ContactActions',
        documentName,
        'Pending'
      );
      await addDoc(DocReference, contactActionData);
      return {
        erro: false,
        message: 'Ação criada com sucesso !',
      };
    } catch (err) {
      return {
        erro: true,
        message: 'não foi possível gerar essa ação',
      };
    }
  };

  return {
    createContactAction,
  };
};
