import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useGetDataGamification } from '../hooks/useGetDataGamification';
import { collection, onSnapshot, query } from 'firebase/firestore';
import db from '../config/db/firebasedb';
import { CampaignMemberContext } from './CampaignMemberData';

export const GamificationContext = createContext({});

export const GamificationProvider = ({ children, ...props }) => {
  const { userCampaignId } = useContext(CampaignMemberContext);
  const { gamificationData } = useGetDataGamification(props?.data?.user);
  const [trails, setTrails] = useState([]);
  const [trailsNotGrouped, setTrailsNotGrouped] = useState([]);
  const [trailsCategories, setTrailsCategories] = useState([]);
  const [missions, setMissions] = useState([]);
  const [missionsGrouped, setMissionsGrouped] = useState([]);
  const [missionsCategories, setMissionsCategories] = useState([]);
  const [documentDownload, setDocumentDownload] = useState([]);

  const segmentedCampaign = useCallback((campaignType) => {
    const campaign = gamificationData?.filter(
      (item) => item?.Campaign__r?.Type === campaignType
    );

    const campaignSegmentada = campaign.filter(
      (item) =>
        item.OnlyCampaignMembers__c &&
        userCampaignId?.includes(item.Campaign__c)
    );

    const campaignNaoSegmentada = campaign.filter(
      (item) => !item.OnlyCampaignMembers__c
    );

    return [...campaignSegmentada, ...campaignNaoSegmentada];
  }, [userCampaignId, gamificationData]);

  const groupBy = (arr, prop) => {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  };

  const trailsNotCompleted = useCallback(() => {
    const trilhasL = segmentedCampaign('Trilha');
    setTrailsNotGrouped(trilhasL);
    const trilhaAgrupada = groupBy(trilhasL, 'Campaign__c');
    return trilhaAgrupada;
  }, [segmentedCampaign]);

  const categoriesList = useCallback((data) => {
    const uniqueCategories = [
      ...new Set(
        data
          ?.map((benefit) => benefit?.Family__c)
          .filter(Boolean)
          .sort()
      ),
    ];
    return uniqueCategories;
  }, []);

  const downloadDocuments = useCallback(() => {
    const queryDocument = query(collection(db, 'Document'));
    onSnapshot(queryDocument, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        const dataDoc = doc.data();
        data.push(dataDoc);
      });
      setDocumentDownload(data);
    });
  }, []);

  useEffect(() => {
    if (!gamificationData) return;
    const trilhasInProgres = trailsNotCompleted();
    setTrails(trilhasInProgres);
    setTrailsCategories(categoriesList(trailsNotGrouped));

    const missoes = segmentedCampaign('Missão');
    setMissions(missoes);
    setMissionsGrouped(groupBy(missoes, 'Family__c'));
    setMissionsCategories(categoriesList(missoes));

    downloadDocuments();
  }, [gamificationData]);

  return (
    <GamificationContext.Provider
      value={{
        trails,
        trailsNotGrouped,
        setTrails,
        trailsCategories,
        setTrailsCategories,
        missions,
        missionsGrouped,
        setMissions,
        missionsCategories,
        setMissionsCategories,
        documentDownload,
      }}
    >
      {children}
    </GamificationContext.Provider>
  );
};
