import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import db from '../config/db/firebasedb';
import { CampaignMemberContext } from './CampaignMemberData';
import { providerHelper } from '../components/utils/providerHelper';

export const BenefitContext = createContext();

export const BenefitProvider = ({ children }) => {
  const { userCampaignId, user } = useContext(CampaignMemberContext);
  const [allBenefits, setAllBenefits] = useState([]);
  const [allBenefitsGrouped, setAllBenefitsGrouped] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const sortBenefits = (rewardList) => {
    rewardList = rewardList?.sort((a, b) => a.Order__c - b.Order__c);
    const benefitsGroupedList = providerHelper.groupBy(
      rewardList,
      'ProductFamilyName__c'
    );
    return benefitsGroupedList;
  };

  const categories = useCallback((data) => {
    const uniqueCategories = [
      ...new Set(
        data
          ?.map((benefit) => benefit?.ProductFamilyName__c)
          .filter(Boolean)
          .sort()
      ),
    ];
    setAllCategories(uniqueCategories);
  }, []);

  const benefitQuery = () => {
    const q = query(
      collection(db, 'Reward'),
      where('Tvs_Subtype__c', '==', 'Benefit'),
      where('Valid__c', '==', true)
    );

    onSnapshot(q, (querySnapshot) => {
      const data = providerHelper.getList(querySnapshot, user);
      setAllBenefits(data);
      categories(data);
      const sortedBenefits = sortBenefits(data);
      setAllBenefitsGrouped(sortedBenefits);
      return benefitQuery;
    });
  };

  useEffect(() => {
    if (!userCampaignId) return;
    benefitQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCampaignId]);

  return (
    <BenefitContext.Provider
      value={{
        allBenefits,
        allBenefitsGrouped,
        allCategories,
        setAllCategories,
      }}>
      {children}
    </BenefitContext.Provider>
  );
};
