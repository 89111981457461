import React from 'react';
import { ButtonContainer } from './style.js';
import { PropsButton } from './type';

export const Button = ({
  disabled,
  loading,
  children,
  type,
  size,
  color,
  onClick,
}: PropsButton) => {
  return (
    <ButtonContainer>
      <button
        className={color}
        disabled={disabled}
        type={type}
        onClick={onClick}>
        {children}
      </button>
      {loading ? <div className='loading-bar'>Carregando</div> : null}
    </ButtonContainer>
  );
};
