import React, { useEffect, useRef } from "react";
import { InputContainer } from "./style";
import { PropsInput } from "./types";

export const Input = ({ inputReference, label, type, initialValue, mask, error, ...rest }: PropsInput) => {
  const maskPhone = (value: string) => (
    value
      ?.replace(/\D/g, "")
      ?.replace(/(\d{2})(\d)/, "($1) $2")
      ?.replace(/(\d{4})(\d{4})(\d)*/, "$1-$2$3")
      ?.replace(/(\d{4})-(\d{1})(\d{4})(\d)*/, "$1$2-$3")
  );
  // const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {

    if(initialValue) {
      if(inputReference.current) {
        inputReference.current.value = initialValue
      }
    }
  }, [initialValue]);

  const typeMask = {
    phone: maskPhone
  }

  const onChange = () => {
    if(mask){
      if(inputReference.current) {
        inputReference.current.value = typeMask[mask](inputReference.current.value)
      }
    }
  }

  return (
    <InputContainer>
      <input ref={inputReference} onInput={onChange} type={type} {...rest} required />
      <label>
        <div className={'label-bg'}/>
        <span className={'label-show'}>{label || 'Input'}</span>
        <span className={'label-placeholder'}>{label || 'Input'}</span>
      </label>
    <p className="error">{error || null}</p>
    {/* {props.input?.append && <div className="append">{props.input?.append}</div>} */}
  </InputContainer>
  )
}
