import styled from '@emotion/styled';
import { useState, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { BREAKPOINTS } from '../configs/consts';
import ToggleSlider from '../Molecules/ToggleSlider';
import ToolHelper from '../utils/ToolHelper';
import useOutsideAction from '../utils/useOutsideAction';
import { getAuth, signOut } from 'firebase/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const HeaderDropMenuItem = (props) => {
  const history = useHistory();
  const { config, checkboxData, hideOn = [] } = props;

  const className = useMemo(() => hideOn.join(' '), [hideOn]);

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then((data) => {
        global.userFirebaseId = null;
        history.push('/');
        window.location.reload();
      })
      .catch((err) => {
        window.alert('Ocorreu algum erro ao sair !');
        console.log(err);
      });
  };

  return (
    <StyledHeaderDropMenuItem className={className} config={config}>
      {props.checkboxData ? (
        <span className={'label'} config={config}>
          {props.label}
        </span>
      ) : props.logout ? (
        <span onClick={() => logout()} className={'label'}>
          {props.label}
        </span>
      ) : (
        <Link
          className={'label'}
          to={props.url}
          config={config}
          target={props.target}>
          {props.label}
        </Link>
      )}
      {checkboxData && (
        <ToggleSlider
          config={config}
          isChecked={checkboxData.isChecked}
          onToggle={checkboxData.onToggle}
        />
      )}
    </StyledHeaderDropMenuItem>
  );
};

const HeaderDropMenu = (props) => {
  const { isOpen, menuItems } = props;
  const wrapperRef = useRef(null);

  let config = useMemo(
    () => ToolHelper.getThemedConfig(props, 'HeaderDropMenu.preset'),
    [props]
  );

  return (
    <StyledHeaderDropMenu config={config} isOpen={isOpen} ref={wrapperRef}>
      <ul>
        <div className={'main-items'}>
          {menuItems?.map((menuItem) => (
            <HeaderDropMenuItem
              key={menuItem.label}
              label={menuItem.label}
              target={menuItem.target}
              url={menuItem.url}
              hideOn={menuItem.hideOn}
              config={config}
            />
          ))}

          <HeaderDropMenuItem
            key={10}
            label={'Sair'}
            target={'Sair'}
            logout={true}
          />
        </div>
      </ul>
    </StyledHeaderDropMenu>
  );
};

export default HeaderDropMenu;

/* style */

const StyledHeaderDropMenu = styled.div`
  position: absolute;
  background: ${(props) =>
    props.config?.themeLayout?.background || 'transparent'};
  top: clamp(50px, calc(9vw), 40px);
  right: 0;
  padding: 20px 55px;
  border-bottom-left-radius: ${(props) =>
    (props.config?.layout?.BOX_BORDER_BOTTOM_LEFT_RADIUS || 0) * 2}px;
  border-bottom-right-radius: ${(props) =>
    (props.config?.layout?.BOX_BORDER_BOTTOM_RIGHT_RADIUS || 0) * 2}px;
  border-bottom: 4px solid rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow: hidden;
  transition: transform 200ms linear;
  transform-origin: top;
  transform: scaleY(${(props) => (props.isOpen ? 1 : 0)});

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    width: 100%;
    left: 0;
  }

  ul {
    display: flex;
    /* .mobile-items{
      display: none;

      @media (max-width: ${BREAKPOINTS.PHONE}px){
        display: block;
      }
    } */
  }

  @media (max-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    top: 75px;
  }
`;

const StyledHeaderDropMenuItem = styled.li`
  width: 200px;
  padding-right: 20px;

  .label {
    display: inline-block;
    padding: 0.7em 0;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    color: ${(props) => props.config?.themeLayout?.title};
    cursor: initial;
  }

  span.label {
    color: #363636;
  }
  span.label:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  a.label {
    width: 100%;
    color: ${(props) => props.config?.themeLayout?.link};
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    &.mobile {
      display: none;
    }
  }

  @media (min-width: ${BREAKPOINTS.PHONE +
    1}px) and (max-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.tablet {
      display: none;
    }
  }

  @media (min-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.desktop {
      display: none;
    }
  }
`;
