// import { GUTTER } from '../configs/consts'
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { X } from 'react-feather';

const GUTTER = 20;

const RightPanel = (props) => {
  const [show, setShow] = useState(false);

  const closePanel = () => {
    props.control.setIsOpen(false);
  }
  
  useEffect(() => {
    if (props.control.isOpen) {
      document.body.style.overflow = 'hidden';
      setShow(props.control.isOpen);
    } else {
      setTimeout(() => {
        document.body.style.overflow = 'initial';
        setShow(props.control.isOpen);
      }, 300)
    }
  }, [props.control.isOpen])

  return ( show &&
    <StyledRightPanel {...props} gutter={GUTTER} show={show}>
      <div className='outside' onClick={closePanel} />
      <div className={`window ${props.control.isOpen ? '' : 'close-panel'}`}>
        <div className='content-area'>
          <div className='close-button' onClick={closePanel}>
            <X strokeWidth={3}/>
          </div>
          {props.control.content}
        </div>
      </div>
    </StyledRightPanel>
  )
}

const StyledRightPanel = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: fixed;
  z-index:9999;
  width:100vw;
  height:100vh;
  top: 0;

  .outside {
    display: flex;
    flex: 1;
    background-color: rgba(255, 255, 255, 0.5);
    min-width: 20px;
    backdrop-filter: blur(2px);
  }

  .window{
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100vh;
    max-width: ${props => props.control.maxWidth || (props.gutter * 10)}px;
    animation: slide-in 300ms linear forwards;

    &.close-panel {
      animation: slide-out 300ms linear forwards;

      @keyframes slide-out {
        from {
          right: 0;
        }
        to {
          right: -${props => props.control.maxWidth || (props.gutter * 10)}px;
        }
      }
    }

    @keyframes slide-in {
      from {
        right: -${props => props.control.maxWidth || (props.gutter * 10)}px;
      }
      to {
        right: 0;
      }
    }

    .close-button {
      display: flex;
      position: absolute;
      top: 16px;
      right: 16px;
      height: 40px;
      width: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;
      user-select: none;
      background-color: #EEE;
      /* color: #0897E9; */
      color: rgb(8, 151, 233);
    }

    .content-area {
      display: flex;
      flex: 1;
      background-color: white;
      box-shadow: -20px 0px 100px rgba(0,0,0,0.24);
      overflow-y: auto;
    }
  }
`

export default RightPanel;