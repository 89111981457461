import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { createContext, useEffect, useState } from 'react';
import db from '../config/db/firebasedb';

export const PartnerContext = createContext({});

export const PartnerProvider = ({ children }) => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, 'Partners'),
      where('TVS_Active__c', '==', true)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setPartners(data);
    });
    return unsubscribe;
  }, []);
  return (
    <PartnerContext.Provider
      value={{
        partners,
      }}>
      {children}
    </PartnerContext.Provider>
  );
};
