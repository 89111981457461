import React, { useState, useEffect, useMemo, useCallback } from 'react';

import FormHelper from '../utils/FormHelper';
import ToolHelper from '../utils/ToolHelper';
import Form from '../Molecules/Form';
import StyledLink from '../Molecules/StyledLink';
import SocialLogin from './SocialLogin';
import FormBox from '../Molecules/FormBox';

const SignUpBox = (props) => {
  const { dataHelper, history, message, routes, popup, } = props;
  const [loading, setLoading] = useState(0);

  const config = useMemo(() => ToolHelper.getThemedConfig(props,'LoginBox.preset'),[props.config]);

  const inputs = Signupform(props);

  const handleSignUp = async (formData) => {
    let username = formData['Username'];

    let response = await props.userHelper.checkUserExists({username},'signup');
    if(response?.success && response?.code){
      switch(response.code){
        case 'user-exists':
          inputs.Username.focusError(config.strings.form.validation.USED_USERNAME);
        break;
        case 'user-needs-password-definition':
          return {
            success: true,
            redirect: [routes.accessCode.path,{UserFirebaseId:response.UserFirebaseId}],
          }
        break;
        case 'user-does-not-exist':
          return {
            success: true,
            redirect: [routes.signUpFull.path,{username}],
          }
        break;
      }
    }
    else{
      message.add({type:'error',message:config.strings.form.message.GENERIC_ERROR});
    }
  }

  return (
    <FormBox
      {...props}
      config={config}
      title={config.strings.screen.signUp.TITLE}
      inside={
        <>
          <Form
            message={message}
            popup={popup}
            history={history}
            dataHelper={dataHelper}
            config={config}
            inputs={inputs}
            handleSend={handleSignUp}
            loading={loading}
            setLoading={setLoading}
            asideSend={<StyledLink to={props.routes.login.path} type={'aside'} config={config}>{config.strings.screen.signUp.BACK_TO_LOGIN}</StyledLink>}
            sendLabel={config.strings.screen.signUp.SEND_TO_SIGNUP_FULL}
            showHeader={props.showHeader}
          />
          <SocialLogin {...props} config={config}/>
        </>
      }
    />
  )
}

export default SignUpBox;

/* form */

const Signupform = (props) => {
  return {
    Username: FormHelper.useFormInput({
      id: 'username',
      label: props.config.strings.form.label.USERNAME,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_EMAIL,
        },
        // {
        //   type: 'email',
        //   errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
        // },
        // {
        //   type: 'custom',
        //   errorMessage: props.config.strings.form.validation.USED_EMAIL,
        //   customFunction: async (formInput,validation,aInputs) => {
        //     return !(await props.userHelper.authEmailExists(formInput.getValue()));
        //   }
        // },
      ],
      inputOptions: {
        spellCheck: false,
      },
      mask: 'username',
      maskOptions: ['text','cpf','cnpj'],
    }),
    /*
    Password: FormHelper.useFormInput({
      id: 'password',
      label: props.config.strings.form.label.PASSWORD,
      type: 'password',
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_PASSWORD,
      }],
    }),
    PasswordConfirmation: FormHelper.useFormInput({
      id: 'password-confirmation',
      label: props.config.strings.form.label.CONFIRM_PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CONFIRM_PASSWORD,
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.IDENTICAL_PASSWORD,
          customFunction: async (formInput,validation,aInputs) => {
            let isValid = true;

            aInputs.every((input) => {
              if(input.id === 'password' && input.getValue() !== formInput.getValue()){
                isValid = false;
                return false;
              }

              return true;
            })

            return isValid;
          }
        },
      ],
    }),
    TermsConfirmation: FormHelper.useFormInput({
      id: 'terms-confirmation',
      label: props.config.strings.form.label.TERMS_AND_PRIVACY,
      type: 'checkbox',
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_TERMS_CHECKBOX,
      }],
    }),
    */
  }
}
