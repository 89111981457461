import styled from "@emotion/styled";

export const ButtonContainer = styled.div`
  position: relative;

  button {
    display: flex;
    background-color: #0897E9;
    width: 100%;
    border: 0;
    padding: 10px;
    color: #FFF;
    text-align: right;
    border-radius: 80px;
    font-weight: 700;
    padding: 10px 20px;
    font-size: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    /* outline: none; */
    /* border-bottom: 4px solid #000; */

    /* &.primary {
      background-color: #0897E9;
    } */
    &.secondary {
      background-color: #CCC;
    }
    &.tertiary {
      background-color: #363636;
    }

    a {
      color: #FFF;
    }

    &:disabled {
      background-color: #EEEEEE;
      /* color: #FFFFFF; */
      /* opacity: 0.5; */

      &:hover {
        cursor: not-allowed;
      }
    }

    &:hover {
      cursor: pointer;
      /* opacity: 0.5; */
    }

  }

  .loading-bar{

    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 15px;
    font-weight: 700;

    margin-top: 20px;
    /* border-bottom-left-radius: 50px; */
    border-radius: 50px;
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    background: repeating-linear-gradient(to right, #000 0%, #0897E9 50%, #0897E9 100%);
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 1s infinite;

    /* animation-fill-mode: forwards;
    animation-timing-function: linear; */

    @keyframes gradient { 
      0%   { background-position: 0 0; }
      100% { background-position: -200% 0; }
    }
  }
`