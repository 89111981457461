import styled from '@emotion/styled';

export const InputContainer = styled.div`
  position: relative;

  input,
  textarea,
  select {
    font-weight: bold;
    font-size: 1em;
    padding: 0.8em;
    display: block;
    height: 2.5em;
    width: 100%;
    border: none;
    border: 1px solid #000;
    border-radius: 5px;
    /* background: ${(props) => props.config?.themeLayout?.background}; */
    /* color: ${(props) => props.config?.themeLayout?.text}; */
    /* font-family: ${(props) => props.config?.layout?.FONT_FAMILY}; */

    &:focus {
      outline: none;
      border-color: ${(props) => props.config?.themeLayout?.label};
    }

    &:focus ~ label,
    &:valid ~ label,
    &:disabled ~ label {
      top: -10px;
      font-size: 0.9em;
      color: ${(props) => props.config?.themeLayout?.label};
      padding: 0px 2px;

      .label-show {
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  textarea {
    height: 8em;
    max-height: 8em;
    max-width: 100%;
  }

  label {
    color: ${(props) => props.config?.themeLayout?.subtext};
    font-size: 1em;
    font-weight: bold;
    position: absolute;
    pointer-events: none;
    left: 0.8em;
    top: 0.6em;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    color: #363636;

    .label-bg {
      position: absolute;
      top: 9px;
      left: -2px;
      width: calc(100% + 4px);
      height: 3px;
      /* background: ${(props) => props.config?.themeLayout?.background}; */
      background: #fff;
    }

    .label-show {
      position: absolute;
      z-index: 2;
    }

    .label-placeholder {
      opacity: 0;
    }
  }

  p.error {
    position: relative;
    padding-top: 2px;
    font-size: 0.8em;
    color: red;
  }

  div.append {
    padding-top: 8px;
    text-align: end;
  }

  &.compressed {
    input,
    textarea,
    select {
      padding: 0.5em;
      font-size: 0.9em;
      /* padding-right:2.5em; */

      &:focus ~ label,
      &:valid ~ label,
      &:disabled ~ label {
        top: -8px;
        font-size: 0.7em;
      }
    }

    select {
      padding-right: 2.5em;
    }

    label {
      top: 0.6em;
      left: 0.55em;
      font-size: 0.9em;

      .label-bg {
        top: 7px;
      }
    }

    p.error {
      position: relative;
      font-size: 0.7em;
    }
  }

  &.hidden {
    display: none;
  }
`;
