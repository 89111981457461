import React, { useState, useEffect, useMemo } from 'react';

import ToolHelper from '../utils/ToolHelper';
import Form from '../Molecules/Form';
import StyledLink from '../Molecules/StyledLink';
import FormBox from '../Molecules/FormBox';
import Avatar from '../Molecules/Avatar';
import styled from '@emotion/styled';
import FormHelper from '../utils/FormHelper';
import { Button as FunctionButton } from '../../components/Button/index.tsx';
import { Button } from '../Button/index.tsx';
import {
  HEROKU_AUTHORIZATION,
  HEROKU_CASE_URL,
  HEROKU_VALIDATE_USER_URL,
  HEROKU_EMAIL_VALIDATE_CODE_URL,
} from '../utils/URLconsts';
import { BREAKPOINTS } from '../configs/consts';

const SignUpFullBox = (props) => {
  const { dataHelper, history, message } = props;
  const [loading, setLoading] = useState(false);
  const [loadingCreateCase, setLoadingCreateCase] = useState(false);
  const [loadingEmailValidate, setLoadingEmailValidate] = useState(false);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [emailValidateCode, setEmailValidateCode] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const [errorEmailValidateCode, setErrorEmailValidateCode] = useState('');
  const [responseSaveUser, setResponseSaveUser] = useState();

  const [userData, setUserdata] = useState();
  const [erros, setErros] = useState(false);
  const [showCaseCreateConfirmed, setShowCaseCreateConfirmed] = useState(false);
  const [showEmailValidate, setShowEmailValidate] = useState(false);
  const [showValidatedEmail, setShowValidatedEmail] = useState(false);
  const [adminAccount, setAdminAccount] = useState();
  const [leadData, setLeadData] = useState();

  const config = useMemo(
    () => ToolHelper.getThemedConfig(props, 'LoginBox.preset'),
    [props.config]
  );

  const Username = history?.location?.state?.username;
  const Password = history?.location?.state?.password;
  const UID = history?.location?.state?.UID;

  const values = {
    Username: history?.location?.state?.username,
    LastName: history?.location?.state?.lastName,
    FirstName: history?.location?.state?.firstName,
  };

  const inputs = props.config.Userform(props, values);

  const onChange = (value) => {
    if (value && value != state) {
      setState(value);

      if (state) {
        inputs.LoyaltyCity__c.setValue('');
        setCity();
      }
    }
  };

  const onChangeEmailValidateCode = (event, index) => {
    const value = event.target.value;

    if (/^[0-9]*$/.test(value)) {
      const newCode = [...emailValidateCode];
      newCode[index] = value;

      if (index < 5 && value !== '') {
        document.getElementById(`code-input-${index + 1}`).focus();
      }

      setEmailValidateCode(newCode);
    }
  };

  const loadUserData = async () => {
    let userData = await props.userHelper.getUser();

    if (!userData) {
      message.add({
        type: 'error',
        body: config.strings.form.message.USER_NOT_FOUND,
        persist: true,
      });
      history.push(props.routes.home.path);
    } else {
      let usernameType = userData?.Username
        ? FormHelper.detectStringContent(userData.Username)
        : 'email';

      // Preenchendo os campos
      Object.entries(inputs).map(([key, input]) => {
        let value = userData[key];

        switch (key) {
          case 'Username':
            if (usernameType !== 'text') {
              input.setType('hidden');
            }
            break;
          case 'Email':
            // Se o e-mail não for real, não preenche
            if (userData.AuthRegisterEmail && !userData.EmailIsReal) {
              value = '';
            }
            break;
          case 'LoyaltyCity__c':
            setCity(value);
            break;
          default:
            if (input.mask === 'date') {
              value = props.dataHelper.dateDisplayFormat(value);
            }
        }
        if (key === 'loyaltyStreet') {
          input.setValue(userData[key || 'LoyaltyStreet__c']);
        }
        input.setValue(value);
      });
    }
  };

  useEffect(() => {
    if (state) {
      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
        .then((res) => res.json())
        .then((results) => {
          const cities = results
            .map(({ nome }) => ({
              value: nome,
              label: nome,
            }))
            .sort((a, b) => {
              return a?.label?.localeCompare(b?.label);
            });

          setCitiesOptions(cities);
        });
    }
  }, [state]);

  useEffect(() => {
    if (props.editProfile) {
      loadUserData();
    }
  }, []);

  if (inputs?.LoyaltyState__c) {
    inputs.LoyaltyState__c.onChange = onChange;
  }

  if (inputs.LoyaltyCity__c) {
    inputs.LoyaltyCity__c.options = citiesOptions;
    inputs.LoyaltyCity__c.startValue = city;
  }

  const validateUser = async () => {
    const nameInputValue = inputs.FirstName.getValue();
    const cpfInputValue = inputs.CPF__c.getValue();
    const cnpjInputValue = inputs.CNPJ_CPF__c.getValue();
    const emailInputValue = inputs.Email.getValue().toLowerCase();

    const dataRequest = {
      name: nameInputValue,
      cpf: cpfInputValue,
      cnpj: cnpjInputValue,
      email: emailInputValue,
    };

    const valideUser = await props.userHelper.dataHelper.getHerokuUrl(
      HEROKU_VALIDATE_USER_URL
    );

    const request = await fetch(valideUser, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: HEROKU_AUTHORIZATION,
      },
      body: JSON.stringify(dataRequest),
    });

    const result = await request.json();

    return result;
  };

  const varifyEmailValidateCode = async () => {
    const requestBody = {
      email: inputs.Email.getValue().toLowerCase(),
      validateCode: emailValidateCode.join(''),
    };

    const validateCode = await props.userHelper.dataHelper.getHerokuUrl(
      HEROKU_EMAIL_VALIDATE_CODE_URL
    );

    const request = await fetch(validateCode, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: HEROKU_AUTHORIZATION,
      },
      body: JSON.stringify(requestBody),
    });

    const result = await request.json();

    return result;
  };

  const saveUser = async (userData) => {
    const response = await props.userHelper.saveUser(userData);

    if (response.success) {
      global.isAuthenticated = true;

      if (props.editProfile) {
        return message.add({
          type: 'success',
          body: config.strings.form.message.DATA_IS_SAVED,
        });
      }

      return {
        success: true,
        redirect: props.routes.home.path,
      };
    }
    if (response?.error?.code === 'auth/username-already-in-use') {
      message.add({
        type: 'error',
        body: config.strings.form.validation.USED_USERNAME,
        persist: true,
      });
      return;
    }
    if (response?.error?.code === 'auth/email-already-in-use') {
      inputs.Email.focusError(config.strings.form.validation.USED_EMAIL);
      return;
    }
    if (response?.error?.code === 'auth/cpfcnpj-already-in-use') {
      inputs.CNPJ_CPF__c.focusError(
        config.strings.form.validation.USED_CPFCNPJ
      );
      return;
    }
    if (response?.error?.code === 'no-uid') {
      message.add({
        type: 'error',
        body: config.strings.form.message.NO_UID,
        persist: true,
      });
      return;
    }
    return message.add({
      type: 'error',
      body: config.strings.form.message.GENERIC_ERROR,
      persist: true,
    });
  };

  // Esta função só dispara quando o form está validado
  const handleSignUp = async (formData) => {
    setErros(false);

    const cpfInput = inputs.CPF__c;
    const cnpjInput = inputs.CNPJ_CPF__c;
    const emailInput = inputs.Email;

    const validateErrors = {
      cpf: false,
      cnpj: false,
      email: false,
    };

    const resultValidateUser = await validateUser();

    if (resultValidateUser.status === 'ERROR') {
      if (resultValidateUser.cnpjMsg) {
        setAdminAccount(
          resultValidateUser.admRegistered
            ? resultValidateUser.admRegistered
            : null
        );
        cnpjInput?.setError(resultValidateUser.cnpjMsg);
        validateErrors.cnpj = true;
      }
      if (resultValidateUser.emailMsg) {
        emailInput?.setError(resultValidateUser.emailMsg);
        validateErrors.email = true;
      }
      if (resultValidateUser.cpfMsg) {
        cpfInput?.setError(resultValidateUser.cpfMsg);
        validateErrors.cpf = true;
      }
    }

    const promoCodeInput = inputs.promotionalCode;
    const promoCodeInputValue = inputs.promotionalCode.getValue();
    if (promoCodeInputValue.length > 0) {
      const promoCodeList = await dataHelper.getPromeCode();
      const promotionalCode = promoCodeList.filter(
        (data) => data.Name === promoCodeInputValue
      );
      if (promotionalCode.length > 0) {
        if (
          promotionalCode[0]?.Remaining__c === 0 ||
          promotionalCode[0]?.Valid__c === false
        ) {
          promoCodeInput.setError('Voucher indisponível ou já utilizado!');
          return;
        }
      }
      // if(promotionalCode.length >= 0) {
      //   if(promotionalCode[0]?.Remaining__c === 0 ||  promotionalCode[0]?.Valid__c === false ) {
      //     promoCodeInput.setError('Voucher indisponível ou já utilizado!');
      //     return
      //   }
      //   return promoCodeInput.setError('Voucher indisponível ou já utilizado!');
      // }
      // return
    }

    const userData = {
      Username,
      Password,
      UID,
      ...formData,
    };

    if (validateErrors.cpf || validateErrors.cnpj || validateErrors.email) {
      const newFormData = { ...formData };
      delete newFormData.Password;
      delete newFormData.PasswordConfirmation;
      delete newFormData.Content1;
      delete newFormData.Content2;
      delete newFormData.LoyaltyPostalCode__c;
      delete newFormData.Username;
      delete newFormData.acg_AddressComplement__c;
      delete newFormData.loyaltyStreet;
      delete newFormData.numberApartament;
      setLeadData(newFormData);
      setErros(true);
      return;
    }

    setUserdata(userData);

    setShowEmailValidate(true);
  };

  const handleProfileUpdate = async (formData) => {
    setErros(false);

    const userData = {
      Username,
      Password,
      UID,
      ...formData,
    };

    const responseSaveUser = await saveUser(userData);

    return responseSaveUser;
  };

  const createCase = async () => {
    setLoadingCreateCase(true);

    let caseUrl = await props.userHelper.dataHelper.getHerokuUrl(
      HEROKU_CASE_URL
    );
    const email = inputs.Email.getValue().toLowerCase();
    const phone = inputs.LoyaltyPhone__c.getValue();
    const firstName = inputs.FirstName.getValue();
    const lastName = inputs.LastName.getValue();
    const cpf = inputs.CPF__c.getValue();
    const cnpj = inputs.CNPJ_CPF__c.getValue();

    const requestBody = {
      ContactName: `${firstName} ${lastName}`,
      SuppliedEmail: email,
      Suppliedcpf: cpf,
      Phone: phone,
      Type: 'Outros',
      Reason: 'Outros',
      Subject: 'Solicitação de Acesso/Cadastro ao BITS',
      Description: `Estou tentando me cadastrar/ Logar no BITS, mas não consigo com CNPJ: ${cnpj}`,
      RecordTypeId: '012Dn000000DaOY',
      Lead: leadData,
    };

    const response = await fetch(caseUrl, {
      method: 'POST',
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: HEROKU_AUTHORIZATION,
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      setShowCaseCreateConfirmed(true);
    }

    setLoadingCreateCase(false);
  };

  const handleRegistrationErrors = () => {
    if (adminAccount) {
      return modalExistingAdminAccount();
    } else {
      if (showCaseCreateConfirmed) {
        return modalShowCaseCreateConfirmed();
      } else {
        return modalCaseCreateConfirmation();
      }
    }
  };

  const handleEmailValidate = async () => {
    setLoadingEmailValidate(true);
    setErrorEmailValidateCode('');

    const result = await varifyEmailValidateCode();

    if (result.status === 'error') {
      setLoadingEmailValidate(false);
      setEmailValidateCode(['', '', '', '', '', '']);
      setErrorEmailValidateCode('Código inválido! Tente novamente.');
      return;
    }

    const saveData = {
      ...userData,
      EmailValidated__c: true,
    };

    const responseSaveUser = await saveUser(saveData);

    setResponseSaveUser(responseSaveUser);
    setShowEmailValidate(false);
    setShowValidatedEmail(true);
  };

  const handleRedirect = () => {
    if (responseSaveUser?.redirect) {
      if (responseSaveUser?.redirect === '/') {
        window.location.reload(false);
        return;
      }
    }
  };

  const modalExistingAdminAccount = () => {
    return (
      <>
        <h1>Já existe usuário cadastrado para esta empresa!</h1>
        <span className='text'>
          Entre em contato com o administrador da sua empresa através do email:{' '}
          <strong>{adminAccount}</strong>
        </span>
        <div className='sectionButton'>
          <Button
            type='button'
            onClick={() => {
              props.popup.setIsOpen(false);
            }}>
            <span>Fechar</span>
          </Button>
        </div>
      </>
    );
  };

  const modalCaseCreateConfirmation = () => {
    return (
      <>
        <h1>Falta pouco para você fazer parte do BITS!</h1>
        <span className='text'>
          Por algum motivo, encontramos algumas inconsistências com seu
          cadastro, sentimos muito e já estamos trabalhando para melhorar 😉
          <br />
          <br />
          Podemos registrar as suas informações para avaliar o que pode ter
          acontecido?
        </span>
        <div className='sectionButtons'>
          <Button
            color='secondary'
            type='button'
            onClick={() => {
              setErros(false);
              setLoading(false);
            }}>
            <span>Não, quero abrir mão dos benefícios gratuitos!</span>
          </Button>
          <FunctionButton
            loading={loadingCreateCase}
            onClick={() => createCase()}>
            <span>Sim, quero fazer parte do BITS!</span>
          </FunctionButton>
        </div>
      </>
    );
  };

  const modalShowCaseCreateConfirmed = () => {
    return (
      <>
        <h1>Seu contato está em análise!</h1>
        <span className='text'>
          Nosso time está analisando sua solicitação e em breve você receberá um
          status por email.
        </span>
        <div className='sectionButton'>
          <Button
            type='button'
            onClick={() => {
              setShowCaseCreateConfirmed(false);
              props.popup.setIsOpen(false);
            }}>
            <span>Fechar</span>
          </Button>
        </div>
      </>
    );
  };

  const modalValidateEmail = () => {
    return (
      <>
        <h1
          style={{
            textAlign: 'center',
          }}>
          Falta pouco para validar sua conta!
        </h1>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0.3em',
            marginBottom: '0.8em',
          }}>
          Por favor, valide seu e-mail. Confira o código
          <br />
          {`que enviamos para ${inputs.Email.getValue()} e digite abaixo:`}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1em',
            marginBottom: '0.3em',
          }}>
          {emailValidateCode.map((digit, index) => (
            <input
              key={index}
              type='text'
              id={`code-input-${index}`}
              value={digit}
              onChange={(e) => onChangeEmailValidateCode(e, index)}
              maxLength='1'
              style={{
                width: '40px',
                height: '55px',
                marginRight: '5px',
                textAlign: 'center',
                fontSize: '1.5em',
                borderRadius: '4px',
                border: '1px solid #999',
              }}
            />
          ))}
        </div>
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            fontSize: '0.8em',
            marginBottom: '2em',
          }}>
          {errorEmailValidateCode}
        </p>
        <FunctionButton
          loading={loadingEmailValidate}
          onClick={() => handleEmailValidate()}>
          <span>Enviar</span>
        </FunctionButton>
        <p style={{ marginTop: '1em', color: '#777' }}>
          Não recebeu o código?
          <StyledLink onClick={() => validateUser()} to={'#'} config={config}>
            {' '}
            Reenviar código
          </StyledLink>
        </p>
      </>
    );
  };

  const modalValidatedEmail = () => {
    return (
      <>
        <h1>E-mail confirmado com sucesso!</h1>
        <span
          className='text'
          style={{
            textAlign: 'center',
            marginBottom: '1.7em',
            fontSize: '1.2em',
            fontWeight: 'bold',
          }}>
          Agora você pode aproveitar o BITS.
          <br />
          Acumule BITS TOTVS...
          <br />
          Realizando Missões.
          <br />
          Participando de Trilhas.
          <br />
          Comprando mais produtos.
          <br />E troque seus pontos benefícios.
        </span>
        <div>
          <Button
            type='button'
            onClick={() => {
              handleRedirect();
            }}>
            <span>Acessar</span>
          </Button>
        </div>
      </>
    );
  };

  return props.editProfile ? (
    <Form
      history={history}
      dataHelper={dataHelper}
      data={props.data}
      config={config}
      layout={'compressed'}
      inputs={inputs}
      handleSend={handleProfileUpdate}
      loading={loading}
      setLoading={setLoading}
      asideSend={
        <StyledLink
          to={
            props.editProfile
              ? props.routes.home.path
              : props.routes.signUp.path
          }
          type={'aside'}
          config={config}>
          {config.strings.BACK}
        </StyledLink>
      }
      sendLabel={config.strings.form.label.SEND}
      mode={props.editProfile ? 'update' : 'create'}
      saveLabel={config.strings.form.label.SAVE}
    />
  ) : (
    <>
      <PopupConfirmation showModal={showEmailValidate}>
        {modalValidateEmail()}
      </PopupConfirmation>

      <PopupConfirmation showModal={showValidatedEmail}>
        {modalValidatedEmail()}
        <style>{`.css-zhqgif .close-button { display: ${
          showValidatedEmail ? 'none' : 'flex'
        }}`}</style>
      </PopupConfirmation>

      <PopupConfirmation showModal={erros && !showEmailValidate}>
        {handleRegistrationErrors()}
      </PopupConfirmation>

      <div
        style={
          erros || showEmailValidate || showValidatedEmail
            ? { display: 'none' }
            : { display: 'block' }
        }>
        <FormBox
          {...props}
          config={config}
          title={
            props.editProfile ? '' : config.strings.screen.signUpFull.TITLE
          }
          inside={
            <StyledSignUpFullBox config={config}>
              {props.editProfile && (
                <div className={'avatar-container'}>
                  <div className={'avatar-holder'}>
                    <Avatar
                      userHelper={props.userHelper}
                      config={config}
                      data={props.data}
                      showIcon={true}
                    />
                  </div>
                </div>
              )}
              <Form
                // setFormValid={setFormValid}
                // setErros={setErros}
                history={history}
                dataHelper={dataHelper}
                data={props.data}
                config={config}
                layout={'compressed'}
                inputs={inputs}
                handleSend={handleSignUp}
                loading={loading}
                setLoading={setLoading}
                asideSend={
                  <StyledLink
                    to={'/cadastro-completo'}
                    type={'aside'}
                    config={config}>
                    {config.strings.BACK}
                  </StyledLink>
                }
                sendLabel={config.strings.form.label.SEND}
                mode={props.editProfile ? 'update' : 'create'}
                popup={props.popup}
              />
            </StyledSignUpFullBox>
          }
        />
      </div>
    </>
  );
};

export default SignUpFullBox;

/* style */
const StyledSignUpFullBox = styled.div`
  margin-top: 20px;

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar-holder {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }
  }
`;
const PopupConfirmation = styled.div`
  padding: 20px;
  border-radius: 10px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  display: ${(props) => (props.showModal ? 'flex' : 'none')};
  border: 1px solid #0897e9;

  /* margin: 0 auto; */

  h1 {
    margin-top: 0;
    text-align: center;
    color: #363636;
  }

  .text {
    color: #363636;
    text-align: justify;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }

  .sectionButtons {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    /* justify-content: space-between; */
    gap: 15px;
  }

  .sectionButton {
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    .sectionButtons {
      /* flex-direction: column; */
      grid-template-columns: repeat(1, 1fr);

      /* button {
        width: 100%;
      } */
    }
  }
`;
