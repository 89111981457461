import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import logo from '../../assets/logo.png';
import SignUpBox from '../../components/Login/SignUpBox'; 
import style from './style';

function SignUpBase(props) {
  const data = {
    logo,
    ...props.data
  }

  const styles = style(props);
  
  return (
    <div style={styles.fullContainer}>
      <SignUpBox {...props} data={data}/>
    </div>
  );
}

const SignUp = withRouter(SignUpBase)

export default SignUp;