import styled from '@emotion/styled';
import React, { useEffect } from 'react';

const FormContent = (props) => {
  return (
    <StyledFormContent config={props.config} className={`input__group ${props.layout} ${props.input?.type}-input`}>
      <div className={'content'}>
        {props.input.content}
      </div>
    </StyledFormContent>
  )
}

export default FormContent;

const StyledFormContent = styled.div`
  .content{
    font-size: 0.9em;
    color: ${props => props.config?.themeLayout?.text};
  }
`