import styled from '@emotion/styled';
import React from 'react';

import fbBtn from '../assets/fbBtn.png';
import googleBtn from '../assets/googleBtn.png';

const SocialLogin = (props) => {
  if(!props.config.appconfig.hasSocialLogin){
    return null;
  }

  const googleLogin = () => {
    props.userHelper.googleLogin(callbackLogin)
  }

  const facebookLogin = () => {
    props.userHelper.facebookLogin(callbackLogin)
  }
  
  const callbackLogin = (result) => {
    if(result.success){
      if(result.isNewUser){
        let firstName;
        let lastName;
        let aName = result?.user?.providerData[0]?.displayName?.split(' ');
        if(aName){
          lastName = aName.pop();
          firstName = aName.join(' ');
        }

        props.history.push(props.routes.signUpFull.path,{email:result.user.providerData[0].email,firstName,lastName});
      }
      else{
        props.history.push(props.routes.home.path);
      }
    }
  };

  return <StyledSocialLogin config={props.config}>
    <div className={'social-separator'}>
      <div className={'social-separator-line'}/>
      <span className={'social-separator-label'}>{props.config?.strings?.components?.socialLogin.OR}</span>
    </div>
    <div className={'social-list'}>
      <div className={'social-list-title'}>
        {props.config?.strings?.components?.socialLogin.ACCESS_USING}
      </div>
      <div className={'social-list-links'}>
        <a onClick={facebookLogin} className={'social-list-link'}><img className={'social-list-logo'} src={fbBtn}/></a>
        <a onClick={googleLogin} className={'social-list-link'}><img className={'social-list-logo'} src={googleBtn}/></a>
      </div>
    </div>
  </StyledSocialLogin>;
}

export default SocialLogin;

/* style */

const StyledSocialLogin = styled.div`
  margin-top: 30px;

  .social-separator {
    position: relative;
    display: flex;
    justify-content: center;

    .social-separator-line {
      position: absolute;
      top: 0.5em;
      width: 100%;
      height: 1px;
      background: ${props => props.config?.themeLayout?.subtext};
      opacity: 0.3;
    }

    .social-separator-label {
      position: relative;
      background: ${props => props.config?.themeLayout?.background};
      color: ${props => props.config?.themeLayout?.text};
      padding: 0 10px;
      font-size: 0.9em;
    }
  }

  .social-list {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .social-list-title {
      display: flex;
      align-items: center;
      padding-right: 20px;
      color: ${props => props.config?.themeLayout?.text};
    }

    .social-list-links {
      display: flex;
      flex-direction: row;
      align-items: center;

      .social-list-link {
        display: flex;
        padding-left: 10px;
        justify-content: center;
        cursor: pointer;

        .social-list-logo {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
`