import { GUTTER } from '../configs/consts'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { RiCloseFill } from 'react-icons/ri'

const Popup = (props) => {
  const [messagePopup, setMessagePopup] = useState('');

  useEffect(() => {
    setMessagePopup(props?.control?.content?.props?.isMessagePopup)
  }, [props]);
  
  const closePopup = () => {
    props.control.setContent('');
    props.control.setIsOpen(false);
  }

  if(props.control.isOpen) {
    document.body.style.overflow = "hidden"
  }
  else{
    document.body.style.overflow = "initial"
  }

  return (
    <StyledPopup className='ctn-styled-popup' {...props} gutter={GUTTER}>
      {/* <div className={'outside'} onClick={closePopup} /> */}
        <div className={'window'}>
          <div
            className={'close-button'}
            onClick={closePopup}>
              { !messagePopup && <RiCloseFill /> }
          </div>
          <div className={'content-area'}>
            {props.control.content}
          </div>
      </div>
    </StyledPopup>
  )
}



const StyledPopup = styled.div`
  display: ${props => props.control.isOpen ? 'flex' : 'none'};
  position: fixed;
  /* background: rgba(255,255,255,0.5); */
  background: rgb(56 56 56 / 94%);
  top:0;
  left:0;
  width:100vw;
  height:100%;
  z-index:99990;
  align-items:center;
  align-content:center;
  flex-direction: column;
  /* margin: 10px auto; */

  .outside {
    position: fixed;
    top: 0; 
    left: 0;
    width: 100vw;
    /* height: 100vh; */
    z-index: 1;
  }

  .ctn-sub-container-product{
    border: 1px solid #0897e9;
    border-radius:10px;
    background-color:#eee;
    /* margin-top:40px; */
    margin-bottom:40px;
    overflow-y: auto;
    /* max-height: calc(100vh - 100px); */
  }
  
  .close-button{

    /* position: absolute;
    top: -20px;
    right: 0; */
    display: flex;
    justify-content: flex-end;
    /* margin: 0 auto; */
    width: 100%;
    /* height: 40px; */
    /* text-align: rig; */
    /* line-height: 1em; */
    /* border-radius: 1em; */
    //background-color: white;
    font-size: 36px;
    //color: ${props => props.control?.themeLayout?.link || '#767676'};
    /* color: #363636; */
    color: #FFF;
    cursor: pointer;
    /* user-select: none; */
  }
  
  .window{
    //box-shadow: 6px 0px 20px 1px #3636364d;
    position: relative;
    overflow: hidden;
    //background: white;
    margin: auto;
    /* padding-left:5px; */
    /* padding-right:5px; */
    padding: 0 20px;
    //border-radius:10px;
    z-index: 999;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    /* height: 500px; */

    .content-area{
      max-width: 1024px;
      overflow: auto;
      max-height: calc(100vh - 150px);
      margin-bottom: 50px;

      &::-webkit-scrollbar {
        display: none;
      }
    } 
    .video-responsive{
      text-align:center;
    }
    .video-responsive iframe{
      max-width:100%;
    }
    .ctn-btn-controls{
      max-width:100%;
    }  
  }
`

export default Popup;