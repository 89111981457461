import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from './App.js';
import './assets/fonts/fonts.css';
import './assets/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { ShowModalLoginProvider } from './provider/ShowModalLogin';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(function (registration) {})
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

const routing = (
  <BrowserRouter>
    <ShowModalLoginProvider>
      <App />
    </ShowModalLoginProvider>
  </BrowserRouter>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
