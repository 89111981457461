import { useEffect, useState } from "react"
import db from '../config/db/firebasedb';
import { doc, getDoc } from "firebase/firestore";

export const useEnanbleRegister = () => {
  const [enableRegister, setEnableRegister] = useState(false);

  useEffect(() => {
    const getEnableRegister = async () => {
      const enableRegisterQuery = doc(db,'AppSetting', 'EnableRegister'); 
      const enableRegister = await getDoc(enableRegisterQuery);
      
      setEnableRegister(enableRegister.data()?.TextValue__c);
    }

    getEnableRegister();
  });


  return { enableRegister }
}