import React, { useEffect, useState } from 'react';
import style, { StyledCheckbox } from './assets/Checkbox_style';

const Checkbox = (props) => {
  const [ isChecked, setIsChecked ] = useState(false);
  const styles = style(props);

  const updateValue = (e) => {
    setIsChecked(!isChecked);

    if(props.onChange){
      props.onChange(e);
    }
    else{
      props.input.ref.current.value = props.input?.ref?.current?.checked ? 1 : 0;
    }
  }

  useEffect(() => {
    setIsChecked(props.check);
  },[props.check])

  const id = props.id || 'checkbox' + Math.floor(Math.random() * 10000);

  return <div style={styles.container}>
    <StyledCheckbox config={props.config}>
      <input 
        type={'checkbox'}
        id={id}
        ref={props.input?.ref}
        checked={isChecked}
        onChange={updateValue}
      />
      <span/>
      <span className={'check-text'} dangerouslySetInnerHTML={{__html:props.input?.label}} />
    </StyledCheckbox>
    <p style={styles.errorMessage}>{props.input?.error}</p>
  </div>;
}

export default Checkbox;