import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import db from '../config/db/firebasedb';
import { CampaignMemberContext } from './CampaignMemberData';
import { providerHelper } from '../components/utils/providerHelper';

export const ProductsContext = createContext();

export const ProductsProvider = ({ children, ...props }) => {
  const { userCampaignId, user } = useContext(CampaignMemberContext);
  const [allProducts, setAllProducts] = useState([]);
  const [allProductsGrouped, setAllProductsGrouped] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const sortProducts = (rewardList) => {
    rewardList = rewardList?.sort((a, b) => a.Order__c - b.Order__c);
    const productsGroupedList = providerHelper.groupBy(
      rewardList,
      'ProductFamilyName__c'
    );
    return productsGroupedList;
  };

  const categories = useCallback((data) => {
    const uniqueCategories = [
      ...new Set(
        data
          ?.map((benefit) => benefit?.ProductFamilyName__c)
          .filter(Boolean)
          .sort()
      ),
    ];
    setAllCategories(uniqueCategories);
  }, []);

  const productQuery = async () => {
    const q = query(
      collection(db, 'Reward'),
      where('Tvs_Subtype__c', '==', 'Totvs Product or Service'),
      where('Valid__c', '==', true)
    );
    onSnapshot(q, (querySnapshot) => {
      const data = providerHelper.getList(querySnapshot, user);
      setAllProducts(data);
      categories(data);
      const sortedBenefits = sortProducts(data);
      setAllProductsGrouped(sortedBenefits);
      return productQuery;
    });
  };

  useEffect(() => {
    if (!userCampaignId) return;
    productQuery();
  }, [userCampaignId]);

  return (
    <ProductsContext.Provider
      value={{
        allProducts,
        allProductsGrouped,
        allCategories,
        setAllCategories,
      }}>
      {children}
    </ProductsContext.Provider>
  );
};
