import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import logo from '../../assets/logo.png';
import SignUpFullBox from '../../components/Login/SignUpFullBox'; 
import style from './style';

function SignUpFullBase(props) {
  const data = {
    logo,
    ...props.data
  }

  const styles = style(props);

  useEffect(() => {
    // props.setShowHeader(false);
  },[])

  return (
    <div>
      <SignUpFullBox {...props} data={data} />
    </div>
  );
}

const SignUpFull = withRouter(SignUpFullBase)

export default SignUpFull;