import styled from '@emotion/styled';
import { BREAKPOINTS } from '../configs/consts';

const GridItem = styled.div(props => {

  let style = {
    gridColumn: 'span ' + (props.desktop || 4),
    ...props.style
  };

  style[`@media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px)`] = {
    gridColumn: 'span ' + (props.tablet || props.desktop || 4),
  }

  style[`@media (max-width: ${BREAKPOINTS.PHONE}px)`] = {
    gridColumn: 'span ' + (props.phone || props.tablet || props.desktop || 8),
  }

  if(props.className === 'hide'){
    style['display'] = 'none';
  }

  return style;
})

export default GridItem;