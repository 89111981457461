import { lazy } from 'react';

export const routesO = {
  // any state paths
  terms: {
    path: '/termos-de-uso',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Terms')),
    exact: true,
    meta: {
      title: 'Termos de Uso',
    },
  },
  privacy: {
    path: '/politica-de-privacidade',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Privacy')),
    exact: true,
    meta: {
      title: 'Política de Privacidade',
    },
  },
  accessCode: {
    path: '/codigo-de-acesso',
    access: 'public',
    component: lazy(() => import('../screens/Login/AccessCode')),
    exact: true,
    meta: {
      title: 'Código de Acesso',
    },
  },
  definePassword: {
    path: '/definir-senha',
    access: 'public',
    component: lazy(() => import('../screens/Login/DefinePassword')),
    exact: true,
    meta: {
      title: 'Definir Senha',
    },
  },
  //SYNGENTA
  caseNew: {
    path: '/fale-conosco',
    access: 'public',
    component: lazy(() => import('../screens/Case/CaseNew')),
    exact: true,
    meta: {
      title: 'Fale Conosco - Enviar contato',
    },
  },
  homeDeslogada: {
    path: '/home',
    access: 'public',
    component: lazy(() => import('../screens/Home/Home')),
    exact: true,
    meta: {
      title: 'Home',
    },
  },
  testepdf: {
    path: '/testepdf',
    access: 'public',
    component: lazy(() => import('../screens/Tests/Pdf')),
    exact: true,
    meta: {
      title: 'Teste PDF',
    },
  },
  logout: {
    path: '/logout',
    access: 'public', // logout é public pra ignorar restrições de código de acesso e termos de uso
    component: lazy(() => import('../screens/Login/Logout')),
    exact: true,
    meta: {
      title: 'Logout',
    },
  },

  // logged paths
  home: {
    path: '/',
    access: 'public',
    componentByType: {
      default: lazy(() => import('../screens/Home/HomeV2')),
      // Parceiro: lazy(() => import('../screens/Home/HomePartner'))
    },
    exact: true,
    meta: {
      title: 'Início',
    },
  },
  gameDashboard: {
    path: '/dashboard',
    access: 'logged',
    component: lazy(() => import('../screens/Gamefication/GameDashboard')),
    exact: true,
    meta: {
      title: 'Dashboard',
    },
  },
  pointList: {
    path: '/extrato',
    access: 'logged',
    component: lazy(() => import('../screens/Gamefication/PointList')),
    exact: true,
    meta: {
      title: 'Meu extrato',
      description: 'Extrato de pontos no programa Acessa Agro',
    },
  },
  acumularBits: {
    path: '/acumular-bits',
    access: 'logged',
    component: lazy(() => import('../components/AcumularBit')),
    exact: true,
    meta: {
      title: 'Acumular Bits',
      description: 'Acumular Bits DESC',
    },
  },
  reward: {
    path: '/resgatar-beneficios',
    access: 'logged',
    component: lazy(() => import('../screens/Reward/RewardListNew')),
    // component: lazy(() => import('../screens/Reward/RewardList')),
    exact: true,
    meta: {
      title: 'Prêmios',
    },
  },
  produtos: {
    path: '/produtos',
    access: 'logged',
    // component: lazy(() => import('../screens/Product/ProductList')),
    component: lazy(() => import('../screens/Product/ProductsListNew.js')),
    exact: true,
    meta: {
      title: 'Prêmios',
    },
  },
  productDetails: {
    path: '/produtos/:productId',
    access: 'logged',

    component: lazy(() => import('../screens/Product/ProductDetails/index.js')),
    exact: true,
    meta: {
      title: 'Produto',
    },
  },
  benefit: {
    path: '/beneficios',
    access: 'logged',
    component: lazy(() => import('../screens/Comp/Comp.tsx')),
    exact: true,
    meta: {
      title: 'Benefícios',
    },
  },
  challenge: {
    path: '/desafios',
    access: 'logged',
    component: lazy(() => import('../screens/Challenge/ChallengeList')),
    exact: true,
    meta: {
      title: 'Desafios',
    },
  },
  news: {
    path: '/noticias',
    access: 'logged',
    component: lazy(() => import('../screens/News/NewsList')),
    exact: true,
    meta: {
      title: 'Notícias',
    },
  },
  article: {
    path: '/noticias/:articleId',
    access: 'logged',
    component: lazy(() => import('../screens/News/Article')),
    exact: true,
    meta: {
      title: 'Artigos',
    },
  },
  howToEarn: {
    path: '/como-ganhar',
    access: 'logged',
    component: lazy(() => import('../screens/HowToEarn/HowToEarnList')),
    exact: true,
    meta: {
      title: 'Como ganhar',
    },
  },
  missionDetail: {
    path: '/missoes/:missionId',
    access: 'logged',
    component: lazy(() =>
      import('../screens/Missions/MissionDetails/index.js')
    ),
    exact: true,
    meta: {
      title: 'Missão',
    },
  },
  benefitsDetails: {
    path: '/resgatar-beneficios/:productId',
    access: 'logged',
    component: lazy(() => import('../screens/Benefits/BenefitsDetails.js')),
    exact: true,
    meta: {
      title: 'Produto',
    },
  },
  referFriend: {
    path: '/indique',
    access: 'logged',
    component: lazy(() => import('../screens/Login/ReferFriend')),
    exact: true,
    meta: {
      title: 'Indique um amigo',
    },
  },
  // download: {
  //   path: '/baixe',
  //   access: 'logged',
  //   component: lazy(() => import('../screens/Comp/Comp.tsx')),
  //   exact: true,
  //   meta: {
  //     title: 'Baixe o app',
  //   },
  // },
  download: {
    path: '/download',
    access: 'logged',
    component: lazy(() => import('../screens/Downloads/DownloadPage.js')),
    exact: true,
    meta: {
      title: 'Download',
    },
  },
  downloadDetails: {
    path: '/download/:downloadId',
    access: 'logged',
    component: lazy(() => import('../screens/Downloads/DownloadDetails.js')),
    exact: true,
    meta: {
      title: 'Download',
    },
  },
  profile: {
    path: '/meu-cadastro',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Meu cadastro',
    },
  },
  leaderships: {
    path: '/meu-cadastro/minhas-liderancas',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Minhas Lideranças',
    },
  },
  // achievements: {
  //   path: '/meu-cadastro/minhas-conquistas',
  //   access: 'logged',
  //   component: lazy(() => import('../screens/Login/Profile')),
  //   exact: true,
  //   meta: {
  //     title: 'Minhas Conquistas',
  //   },
  // },
  photo: {
    path: '/meu-cadastro/foto',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Meu cadastro - Foto',
    },
  },
  uploadDocs: {
    path: '/upload-documents',
    access: 'logged',
    component: lazy(() => import('../screens/Case/UploadDocument.js')),
    exact: true,
    meta: {
      title: 'Upload Document',
    },
  },
  closeAccount: {
    path: '/meu-cadastro/encerrar-conta',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Encerrar conta',
    },
  },
  changePassword: {
    path: '/meu-cadastro/alterar-senha',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Alterar senha',
    },
  },
  survey: {
    path: '/pesquisa',
    access: 'logged',
    component: lazy(() => import('../screens/Survey/SurveyList')),
    exact: true,
    meta: {
      title: 'Pesquisa',
    },
  },
  surveyRespond: {
    path: '/pesquisa/:surveyId',
    access: 'logged',
    component: lazy(() => import('../screens/Survey/SurveyRespond')),
    exact: true,
    meta: {
      title: 'Responder pesquisa',
    },
  },
  quiz: {
    path: '/quiz',
    access: 'logged',
    component: lazy(() => import('../screens/Survey/SurveyRespond')),
    exact: true,
    meta: {
      title: 'Responder quiz',
    },
  },
  quizRespond: {
    path: '/quiz/:quizId',
    access: 'logged',
    component: lazy(() => import('../screens/Survey/SurveyRespond')),
    exact: true,
    meta: {
      title: 'Responder quiz',
    },
  },
  /*
  changePassword: {
    path: '/alterar-senha',
    access: 'logged',
    component: lazy(() => import('../screens/Login/ChangePassword')),
    exact: true,
    meta: {
      title: 'Alterar senha',
    },
  },
  */
  termsAgreement: {
    path: '/conferencia-termos-de-uso',
    access: 'logged',
    component: lazy(() => import('../screens/Login/TermsAgreement')),
    exact: true,
    meta: {
      title: 'Conferência dos Termos de Uso',
    },
  },
  caseList: {
    path: '/fale-conosco/lista',
    access: 'logged',
    component: lazy(() => import('../screens/Case/CaseList')),
    exact: true,
    meta: {
      title: 'Fale conosco',
    },
  },
  caseDetail: {
    path: '/fale-conosco/detalhe/:caseId',
    access: 'logged',
    component: lazy(() => import('../screens/Case/CaseDetail')),
    exact: true,
    meta: {
      title: 'Fale conosco - Detalhe',
    },
  },
  invoices: {
    path: '/notas-fiscais',
    access: 'logged',
    component: lazy(() => import('../screens/Invoices/InvoiceList')),
    exact: true,
    meta: {
      title: 'Notas fiscais',
    },
  },
  // produtos: {
  //   path: '/produtos',
  //   access: 'logged',
  //   component: lazy(() => import('../components/ProdutosTotvs')),
  //   // component: lazy(() => import('../screens/Invoices/InvoiceList')),
  //   exact: true,
  //   meta: {
  //   title: 'Produtos Totvs',
  //   },
  // },
  parceiros: {
    path: '/parceiros',
    access: 'logged',
    component: lazy(() => import('../screens/Partner')),
    // component: lazy(() => import('../screens/Invoices/InvoiceList')),
    exact: true,
    meta: {
      title: 'Vantagens do BITS',
    },
  },
  // BENEFÍCIO PARCEIRO BITS
  partnerBenefit: {
    path: '/parceiro/:partnerId/:benefitId',
    access: 'logged',
    component: lazy(() => import('../screens/PartnerBenefit')),
    exact: true,
    meta: {
      title: 'BENEFÍCIO PARCEIRO BITS',
    },
  },

  focalPointList: {
    path: '/minha-equipe',
    access: 'logged',
    component: lazy(() => import('../screens/FocalPoint/FocalPointList')),
    exact: true,
    meta: {
      title: 'Minha equipe',
    },
  },
  focalPointTermList: {
    path: '/historico-de-termos',
    access: 'logged',
    component: lazy(() => import('../screens/FocalPoint/FocalPointTermList')),
    exact: true,
    meta: {
      title: 'Histórico de termos',
    },
  },
  businessCycle: {
    path: '/aprovacao-de-valores',
    access: 'logged',
    component: lazy(() => import('../screens/BusinessCycle/BusinessCycleList')),
    exact: true,
    meta: {
      title: 'Aprovação de valores',
    },
  },
  results: {
    path: '/meus-resultados',
    access: 'logged',
    component: lazy(() => import('../screens/Results/ResultsList')),
    exact: true,
    meta: {
      title: 'Meus resultados',
    },
  },
  distributors: {
    path: '/distribuidores',
    access: 'logged',
    component: lazy(() => import('../screens/Distributors/DistributorsList')),
    exact: true,
    meta: {
      title: 'Distribuidores',
    },
  },
  incomeReports: {
    path: '/informe-de-rendimentos',
    access: 'logged',
    component: lazy(() => import('../screens/IncomeReport/IncomeReportList')),
    exact: true,
    meta: {
      title: 'Informe de rendimentos',
    },
  },
  missoes: {
    path: '/missoes',
    access: 'logged',
    // component: lazy(() => import('../components/Missoes')),

    component: lazy(() =>
      import('../screens/Missions/Mission/MissionListNew.js')
    ),
    exact: true,
    meta: {
      title: 'Missões',
    },
  },
  duvidas: {
    path: '/duvidas',
    access: 'public',
    component: lazy(() => import('../components/Duvidas')),
    // component: lazy(() => import('../screens/IncomeReport/IncomeReportList')),
    exact: true,
    meta: {
      title: 'Duvidas',
    },
  },
  trilhas: {
    path: '/trilhas',
    access: 'logged',
    // component: lazy(() => import('../components/Trilhas')),
    component: lazy(() => import('../screens/Trails/TrailListNew.js')),
    exact: true,
    meta: {
      title: 'Missões',
    },
  },
  trailPreview: {
    path: '/trilhas/:trilhaId',
    access: 'logged',
    component: lazy(() => import('../screens/Trails/TrailPreview')),
    exact: true,
    meta: {
      title: 'Trilha',
    },
  },

  beneficios: {
    path: '/resgatar-beneficios-BKP',
    access: 'logged',
    component: lazy(() => import('../components/ResgatarBeneficios')),
    exact: true,
    meta: {
      title: 'Resgatar Benefícios',
    },
  },
  // resgatar: {
  //   path: '/resgatar-resgatar',
  //   access: 'logged',
  //   component: lazy(() => import('../components/ResgatarBeneficio')),
  //   exact: true,
  //   meta: {
  //     title: 'Resgatar Benefícios',
  //   },
  // },

  // exclusively unlogged paths
  login: {
    path: '/login',
    access: 'unlogged',
    // component: lazy(() => import('../screens/Login/Login')),
    component: lazy(() => import('../screens/Home/HomeV2')),
    exact: true,
    meta: {
      title: 'Entrar',
    },
  },
  sso: {
    path: '/sso',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/Sso')),
    exact: true,
    meta: {
      title: 'SSO',
    },
  },
  resetPassword: {
    path: '/redefinir-senha',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/ForgotPassword')),
    exact: true,
    meta: {
      title: 'Redefinir senha',
    },
  },
  // signUp: {
  //   path: '/cadastro',
  //   access: 'unlogged',
  //   component: lazy(() => import('../screens/Login/SignUp')),
  //   exact: true,
  //   meta: {
  //     title: 'Cadastro',
  //   },
  // },
  signUpFull: {
    path: '/cadastro-completo',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/SignUpFull')),
    exact: true,
    meta: {
      title: 'Cadastro - Preencha seus dados',
    },
  },
  SignUpFullEvento: {
    path: '/cadastro/:code?',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/SignUpFullEvento.tsx')),
    exact: true,
    meta: {
      title: 'Cadastro - Preencha seus dados',
    },
  },
  universoTotvs: {
    path: '/universototvs2023',
    access: 'public',
    component: lazy(() => import('../components/UniversoTotvs/index.tsx')),
    exact: true,
    meta: {
      title: 'Cadastro - Preencha seus dados',
    },
  },
  Lumx: {
    path: '/lumx',
    access: 'public',
    component: lazy(() => import('../components/Lumx/index.tsx')),
    exact: true,
    meta: {
      title: 'Cadastro - Preencha seus dados',
    },
  },
  Player1: {
    path: '/player1',
    access: 'public',
    component: lazy(() => import('../components/Player1/index.tsx')),
    exact: true,
    meta: {
      title: 'Cadastro - Preencha seus dados',
    },
  },
};

const routes = Object.entries(routesO).map(([key, value]) => value);

export default routes;
