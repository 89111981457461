import {
  collection,
  getDoc,
  getDocs,
  doc,
  query,
  where,
  onSnapshot,
  addDoc,
  setDoc,
  serverTimestamp,
  orderBy,
  limit,
  startAfter,
  writeBatch,
} from 'firebase/firestore';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  reauthenticateWithCredential,
  updatePassword,
  deleteUser,
  signInWithCustomToken,
} from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
import moment from 'moment';

import GameficationHelper from './GameficationHelper';
import { POINT_ACTIONS, FAKE_DOMAIN } from './consts';
import {
  HEROKU_AUTHORIZATION,
  HEROKU_GAMIFICATIONSRECEIVE_URL,
  HEROKU_LOYALTY_URL,
  HEROKU_CASE_URL,
  HEROKU_INVOICE_URL,
  HEROKU_INVOICE_REANALYSIS_REQUEST_URL,
  HEROKU_INVOICE_DELETE_URL,
  HEROKU_ACCEPT_AGREEMENT,
  HEROKU_UPDATE_PASSWORD_DATA_URL,
} from './URLconsts';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import FormHelper from './FormHelper';
import ToolHelper from './ToolHelper';

export default class UserHelper {
  constructor(app, db, getConfigFile) {
    this.app = app;
    this.db = db;

    getConfigFile(`utils/UserHelper/config.js`).then((importedFile) => {
      let customCfgClass = importedFile?.default;

      if (customCfgClass && typeof customCfgClass === 'function') {
        this.customCfg = new customCfgClass();
      }
    });
  }

  setDataHelper(dataHelper) {
    this.dataHelper = dataHelper;
  }
  async findUserFirebaseDoc(userData) {
    if (!userData?.username.toLowerCase() && !userData?.userFirebaseId) {
      return false;
    }

    let { username, userFirebaseId } = userData;

    let foundUserFirebaseId = userFirebaseId;

    if (FormHelper.detectStringContent(username) === 'cpf') {
      username = ToolHelper.getDigits(username).toLowerCase();
    }

    if (!userFirebaseId) {
      let usernameDoc = await getDoc(doc(this.db, 'Usernames', username));

      if (usernameDoc?.exists()) {
        foundUserFirebaseId = usernameDoc.data().UserFirebaseId;
      }
    }

    if (foundUserFirebaseId) {
      let userFirebaseDocs = await getDoc(
        doc(this.db, 'UserFirebaseId', foundUserFirebaseId)
      );

      return userFirebaseDocs.data();
    }
    // -- END -----------------------------------------------------------------------
    else {
      // -- TODO - Quando Usernames estiver 100% populado, pode excluir esta condicional
      //           Pois quando estiver, se não existir em Usernames, não existirá em UserFirebaseId
      let usernameType = FormHelper.detectStringContent(username);
      if (usernameType === 'email') {
        let userFirebaseQ = query(
          collection(this.db, 'UserFirebaseId'),
          where('Email', '==', username.toLowerCase())
        );
        let userFirebaseDocs = await getDocs(userFirebaseQ);

        let foundUser = false;
        userFirebaseDocs.forEach((doc) => {
          foundUser = doc.data();
        });

        return foundUser;
      }
    }

    return false;
  }

  async postSignInProcess(userCredential) {
    let user = userCredential.user;
    let userData = await this.getUserDataByEmail(user.email);
    let hasUserDoc = !!userData;

    if (userData?.acg_ProgramOptOut__c) {
      await this.logoutAsync();

      return {
        success: false,
        error: {
          code: 'auth/user-disabled',
        },
      };
    }

    if (userData?.ExpiredPassword__c && !userData?.EmailValidated__c) {
      await this.logoutAsync();

      return {
        success: false,
        userData,
        error: {
          code: 'auth/password-expired',
        },
      };
    }

    return {
      success: true,
      user: userCredential,
      hasUserDoc,
    };
  }

  async userLogin(username, password) {
    const auth = getAuth();

    let foundUserFirebaseDoc = await this.findUserFirebaseDoc({ username });

    if (this.customCfg?.beforeLogin) {
      let result = await this.customCfg.beforeLogin(
        foundUserFirebaseDoc,
        password
      );

      if (result.success) {
        username = result.replaceEmail || username;
        password = result.replacePassword || password;
      } else {
        return result;
      }
    }

    if (foundUserFirebaseDoc) {
      try {
        let authEmail = foundUserFirebaseDoc.Email;
        let userCredential = await signInWithEmailAndPassword(
          auth,
          authEmail,
          password
        );

        return await this.postSignInProcess(userCredential);
      } catch (error) {
        return {
          success: false,
          error,
        };
      }
    }

    return {
      success: false,
      error: {
        code: 'auth/user-not-found',
      },
    };
  }

  async loginRedefinePassword(username, password) {
    let userFirebaseDoc = await this.findUserFirebaseDoc({ username });

    if (userFirebaseDoc) {
      let authEmail = userFirebaseDoc.Email;
      let auth = getAuth();
      let userCredential = await signInWithEmailAndPassword(
        auth,
        authEmail,
        password
      );

      return {
        success: true,
        user: userCredential,
      };
    }
  }

  async userLoginWithToken(token) {
    const auth = getAuth();

    try {
      let userCredential = await signInWithCustomToken(auth, token);
      return await this.postSignInProcess(userCredential);
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }

  async userLoginByFirebaseId(userFirebaseId, password) {
    let userFirebaseRef = doc(this.db, 'UserFirebaseId', userFirebaseId);
    let userFirebaseDoc = await getDoc(userFirebaseRef);

    if (userFirebaseDoc.exists()) {
      let data = userFirebaseDoc.data();
      return this.userLogin(data.Email, password);
    }

    return {
      success: false,
      error: {
        code: 'auth/user-not-found',
      },
    };
  }

  getAuthUser() {
    const auth = getAuth();
    return auth?.currentUser;
  }

  async getUserFirebaseId() {
    let user = this.getAuthUser();

    if (!user) return null;

    if (!global.userFirebaseId) {
      const userEmail =
        user?.email.toLowerCase() ||
        user?.providerData[0]?.email.toLowerCase() ||
        null;

      if (!userEmail) {
        return null;
      }

      let userData = await this.getUserDataByEmail(userEmail);

      if (userData.FirebaseId__c) {
        global.userFirebaseId = userData.FirebaseId__c;
        return global.userFirebaseId;
      }

      return null;
    } else {
      return global.userFirebaseId;
    }
  }

  async getUserDataByEmail(email) {
    let userQ = query(
      collection(this.db, 'Users'),
      where('Email', '==', email)
    );
    let userDocs = await getDocs(userQ);

    if (userDocs.size === 0) {
      userQ = query(
        collection(this.db, 'Users'),
        where('AuthRegisterEmail', '==', email)
      );
      userDocs = await getDocs(userQ);
    }

    if (userDocs.size > 0) {
      let result = false;
      userDocs.forEach((doc) => {
        result = doc.data();
      });
      return result;
    }

    return false;
  }

  async getCollectionUsers(email) {
    const userQ = query(
      collection(this.db, 'Users'),
      where('Email', '==', email)
    );
    const userDocs = await getDocs(userQ);

    return userDocs;
    // return doc(this.db,'Users',userFirebaseId);
  }

  async getUserRef() {
    let userFirebaseId = await this.getUserFirebaseId();
    if (!userFirebaseId) return null;

    return doc(this.db, 'Users', userFirebaseId);
  }

  async getUserFirebaseIdRef() {
    let userFirebaseId = await this.getUserFirebaseId();
    if (!userFirebaseId) return null;

    return doc(this.db, 'UserFirebaseId', userFirebaseId);
  }

  async getUser() {
    let userRef = await this.getUserRef();

    if (userRef) {
      let userDoc = await getDoc(userRef);

      if (userDoc.exists()) return userDoc.data();
    }

    return null;
  }

  async getUserFirebaseIdData(UserFirebaseId) {
    if (!UserFirebaseId) {
      UserFirebaseId = await this.getUserFirebaseId();
    }
    if (!UserFirebaseId) return null;

    let userFirebaseIdDoc = await getDoc(
      doc(this.db, 'UserFirebaseId', UserFirebaseId)
    );

    if (userFirebaseIdDoc.exists()) {
      return userFirebaseIdDoc.data();
    }

    return null;
  }

  async snapUser(callback) {
    let userRef = await this.getUserRef();
    let unsub;
    if (userRef) {
      unsub = onSnapshot(userRef, (snapshot) => {
        if (snapshot?.id) {
          callback({
            ...snapshot.data(),
            UserFirebaseId: snapshot.id,
          });
        } else {
          callback({});
        }
      });
    }
    let t = {};
    t['user' + new Date().getTime()] = unsub;

    return t;
  }

  // userType é o que possivelmente redefine as configurações e layouts do usuário.
  // Chama customCfg pois isso pode variar de projeto para projeto.
  async setUserType(user) {
    let hasSetUserType = false;

    if (this.customCfg?.setUserType) {
      let result = await this.customCfg.setUserType(user, { userHelper: this });

      if (result.userType) {
        global.userType = result.userType;
        hasSetUserType = true;
      }

      if (result.types) {
        global.userTypes = result.userTypes;
      }

      if (result.user) {
        user = result.user;
      }
    }

    if (!hasSetUserType) global.userType = user.MilestoneCategory__c;
  }

  getMessaging() {
    if (!this.messaging) this.messaging = getMessaging(this.app);

    return this.messaging;
  }

  addStartUpMessageReadUser = async (startUpMessageId, startUpMessage) => {
    try {
      let userId = await this.getUserFirebaseId();

      let viewRef = doc(
        this.db,
        'Users',
        userId,
        'StartUpMessageRead',
        startUpMessageId
      );
      let viewDoc = await getDoc(viewRef);

      if (viewDoc.exists()) {
        return false;
      }

      let viewData = {
        ActionDate: new Date(),
        Id: startUpMessage.Id,
        Name: startUpMessage.Name,
      };

      setDoc(viewRef, viewData, { merge: true });
    } catch (error) {
      console.error('Erro ao adicionar message:', error);
    }
  };

  getStartUpMessageRead = async (startUpMessageId) => {
    let userId = await this.getUserFirebaseId();

    if (userId) {
      let viewRef = doc(
        this.db,
        'Users',
        userId,
        'StartUpMessageRead',
        startUpMessageId
      );
      let viewDoc = await getDoc(viewRef);

      return viewDoc.data();
    }

    return null;
  };

  async getNotificationTopics() {
    let topicsQ = query(
      collection(this.db, 'NotificationTopic'),
      orderBy('Order__c')
    );
    let topicsDoc = await getDocs(topicsQ);

    let data = [];

    if (topicsDoc.docs.length) {
      data = topicsDoc.docs.every((doc) => {
        let docData = doc.data();

        if (docData.FirebaseId__c != null && docData.FirebaseId__c != '') {
          data.push(docData);
        }

        return true;
      });
    }

    return data;
  }

  getMessagingToken(callback) {
    this.getMessaging();

    getToken(this.messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY })
      .then(async (currentToken) => {
        const userFirebaseId = await this.getUserFirebaseId();

        let ref = collection(
          this.db,
          'Users',
          userFirebaseId,
          'NotificationTokens'
        );

        await addDoc(ref, {
          messagingToken: currentToken,
          messagingTokenTimestamp: serverTimestamp(),
        });

        // let allTopics = await this.getNotificationTopics();
        // TODO - O registro de token em um tópico não pode ser feito pelo firebase web. É necessário criarmos um acesso no Heroku para encaminhar esta requisição.

        callback({ currentToken });
      })
      .catch((err) => {
        callback({ err });
      });
  }

  async authEmailExists(email) {
    const auth = getAuth();
    const result = await fetchSignInMethodsForEmail(auth, email);

    return result.length > 0;
  }

  async firestoreEmailExists(email) {
    return !!(await this.getUserDataByEmail(email));
  }

  async cpfExists(cpf) {
    let cleanCpf = cpf.replace(/\D/g, '');

    let user = await this.getUser();
    if (user?.CNPJ_CPF__c && user.CNPJ_CPF__c === cleanCpf) {
      return false;
    }

    let cpfQ = doc(this.db, 'CPF', cleanCpf);
    let cpfDoc = await getDoc(cpfQ);

    return cpfDoc.exists();
  }

  handlePromoCode = async (promoCode) => {
    let promoCodeQ = query(
      collection(this.db, 'Users'),
      where('ReferralCode__c', '==', promoCode)
    );
    let promoCodeDocs = await getDocs(promoCodeQ);

    if (promoCodeDocs.size > 0) {
      promoCodeDocs.forEach((doc) => {
        this.redeemGenerator = doc.data();
      });

      return true;
    }

    return false;
  };

  editUserData = async (userData, userCredential) => {
    let result;
    let userUID;
    if (userData?.UID) {
      userUID = userData.UID;
    } else if (userCredential?.user?.uid) {
      userUID = userCredential.user.uid;
    } else {
      return {
        success: false,
        error: {
          code: 'no-uid',
        },
      };
    }

    const Topics__c = this.subscribeAllTopics();

    let userId = await this.getUserFirebaseId();

    let firebaseIdDoc;
    let firebaseIdGetDoc;

    if (userId) {
      firebaseIdDoc = doc(this.db, 'UserFirebaseId', userId);
      firebaseIdGetDoc = await getDoc(firebaseIdDoc);
    }

    if (!userId || !firebaseIdGetDoc.exists()) {
      // Criando UserFirebaseId
      firebaseIdDoc = await addDoc(collection(this.db, 'UserFirebaseId'), {
        UID: userUID,
        Email: userData.AuthRegisterEmail.toLowerCase(),
      });
    }

    // Atualizando doc CPF
    if (userData.CNPJ_CPF__c) {
      let cleanCpf = userData.CNPJ_CPF__c.replace(/\D/g, '');
      let cpfQ = doc(this.db, 'CPF', cleanCpf);

      await setDoc(cpfQ, {
        Email: userData.Email.toLowerCase() || '',
        CPF: cleanCpf,
        UserFirebaseId: firebaseIdDoc.id,
      });
    }

    // Cria/Atualiza Usernames
    let originalUserData = await this.getUser();
    await this.insupdateUsernames(firebaseIdDoc.id, originalUserData, userData);

    // Atualizando doc UserFirebase
    let firebaseIdData = {
      UserFirebaseId: firebaseIdDoc.id,
    };

    if (userData.UserFirebaseIdData) {
      firebaseIdData = {
        ...firebaseIdData,
        ...userData.UserFirebaseIdData,
      };
    }
    await setDoc(firebaseIdDoc, firebaseIdData, { merge: true });
    // Criando código de acesso
    if (!userId) {
      this.sendAccessCode(firebaseIdDoc.id);
      userData.HasAgreedToTerms = false;
    }

    // Atualizando doc User
    userData.UID = userUID;
    userData.FirebaseId__c = firebaseIdDoc.id;
    // userData.MilestoneCategory__c = 'Parceiro';

    await this.setUserData(userData);

    // Criando registros de loyalty
    try {
      result = await this.updateLoyalty();
    } catch (e) {
      console.log('updateLoyalty fail', e);
    }

    if (this.redeemGenerator) {
      // Contact Action para o usuário que compartilhou o código
      await GameficationHelper.addRow(
        this,
        POINT_ACTIONS.REFER_SENDER,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.redeemGenerator
      );
      // Contact Action para o usuário que está se cadastrando
      await GameficationHelper.addRow(this, POINT_ACTIONS.REFER_RECEIVER);
    }

    return {
      result,
      success: true,
      user: userData,
    };
  };

  insupdateUsernames = async (firebaseId, originalUserData, userData) => {
    if (userData.CNPJ_CPF__c) {
      await this.setUsernameDoc(
        firebaseId,
        ToolHelper.getDigits(originalUserData?.CNPJ_CPF__c),
        ToolHelper.getDigits(userData.CNPJ_CPF__c),
        FormHelper.detectStringContent(userData.CNPJ_CPF__c)
      );
    }

    if (userData.Email) {
      await this.setUsernameDoc(
        firebaseId,
        originalUserData?.Email.toLowerCase(),
        userData.Email.toLowerCase(),
        'email'
      );
    }

    if (
      userData.Username &&
      userData.Username !== userData.CNPJ_CPF__c &&
      userData.Username !== userData.Email
    ) {
      await this.setUsernameDoc(
        firebaseId,
        originalUserData?.Username.toLowerCase(),
        userData.Username.toLowerCase(),
        'text'
      );
    }
  };

  setUsernameDoc = async (firebaseId, originalUsername, username) => {
    const batch = writeBatch(this.db);

    batch.set(doc(this.db, 'Usernames', username.toLowerCase()), {
      UserFirebaseId: firebaseId,
    });

    if (originalUsername?.length && originalUsername !== username) {
      batch.delete(doc(this.db, 'Usernames', originalUsername));
    }

    await batch.commit();
  };

  // Cria e registra um código de acesso para o usuário atual.
  sendAccessCode = async (UserFirebaseId) => {
    const herokuDomain = await this.dataHelper.getHerokuDomain();
    const sendAccessCodeUrl = herokuDomain + '/generateAccessCode';
    const herokuData = {
      UserFirebaseId,
    };
    var result = {
      error: 'unknown-error',
    };

    const response = await fetch(sendAccessCodeUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: HEROKU_AUTHORIZATION,
      },
      body: JSON.stringify(herokuData),
    });

    if (response.ok) {
      result = response.json();
    }

    return result;
  };

  // Retorna um array com os Ids dos termos que o usuário já aceitou
  getUserAcceptedTerms = async () => {
    // let userDocRef = await this.getUserRef();
    // let userTermsQ = query(collection(userDocRef,'MemberTerm'),where('IsDeleted','==',false),orderBy('CreatedDate','desc'));

    let userRef = await this.getUserRef();
    let userTermsQ = query(
      collection(userRef, 'MemberTerm'),
      where('IsDeleted', '==', false)
    );

    let userTermsDoc = await getDocs(userTermsQ);

    let data = [];

    if (userTermsDoc.docs.length) {
      data = userTermsDoc.docs.map((doc) => {
        const docData = doc.data();

        if (!docData.acg_ProgramDateOptOut__c) {
          return docData.acg_AcceptanceTermVersion__c;
        }
      });
    }

    return data;
  };

  getUserPendingTerms = async (user) => {
    if (!user) {
      user = await this.getUser();

      if (!user) {
        return false;
      }
    }

    // busca AcceptanceTermVersions para o usuário
    let allTerms = await this.dataHelper.getTermVersions(
      user.LoyaltyCategory__c
    );

    // busca termos que usuário aceitou
    let userAcceptedTerms = await this.getUserAcceptedTerms();

    // filtra todos os termos que ainda não foram aceitos
    let filteredTerms = allTerms.filter(
      (obj) => !userAcceptedTerms.includes(obj.Id)
    );

    return filteredTerms;
  };

  // Verifica se o usuário atual precisa validar o código de acesso.
  isAccessCodeValid = async (user) => {
    if (!user) {
      return false;
    }

    return !user.AccessCodeRequireValidation;
  };

  // Verifica se o usuário precisa definir sua senha
  isRequiredPasswordDefinition = async (UserFirebaseId) => {
    const userFirebaseData = await this.getUserFirebaseIdData(UserFirebaseId);
    return userFirebaseData?.PasswordDefinitionIsRequired;
  };

  isRequiredTermsAgreement = async (user) => {
    let pendingTerms = await this.getUserPendingTerms(user);
    return !!pendingTerms.length;
  };

  acceptAgreement = async () => {
    const userRef = await this.getUserRef();
    const user = await this.getUser();

    if (!user?.LoyaltyEmail__c) {
      return {
        success: false,
      };
    }

    // TODO - Trocar HasAgreedToTerms para acg_AcceptanceDate__c quando o envio estiver OK
    //        (no caso esse setDoc poderá ser deletado)
    await setDoc(
      userRef,
      {
        HasAgreedToTerms: true,
      },
      { merge: true }
    );

    var sfUserData = {
      cpf: user.CNPJ_CPF__c,
      email: user.LoyaltyEmail__c.toLowerCase(),
      lastName: user.LastName,
      acceptanceDevice: 'Site',
    };

    try {
      var loyaltyUrl = await this.dataHelper.getHerokuUrl(HEROKU_LOYALTY_URL);
      // Comentado pois ainda não sei quais são os parâmetros a serem enviados
      var response = await fetch(loyaltyUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(sfUserData),
      });

      return {
        success: true,
      };
    } catch (error) {
      console.log('erro no envio', error);
    }

    return {
      success: false,
    };
  };

  redirectMissionAndTrail = (props) => {
    const { location } = props.history;
    if (location?.state?.product && !props.popup.isOpen) {
      if (props.popup?.content) props.popup.setIsOpen(true);
    }
  };

  acceptAgreementV2 = async (termId) => {
    let user = await this.getUser();

    if (!user?.Id || !termId) {
      return {
        success: false,
      };
    }

    const clientIP = await this.getClientIP();
    const acceptanceDevice = 'Site';

    var sfUserData = {
      contactId: user.Id,
      termId,
      clientIP,
      acceptanceDevice,
    };

    try {
      var acceptAgreementUrl = await this.dataHelper.getHerokuUrl(
        HEROKU_ACCEPT_AGREEMENT
      );
      // Comentado pois ainda não sei quais são os parâmetros a serem enviados
      var response = await fetch(acceptAgreementUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(sfUserData),
      });

      if (response.ok) {
        let jresponse = await response.json();

        if (jresponse.id) {
          let firebaseId = jresponse.id.slice(0, 15);
          let userDocRef = await this.getUserRef();
          let memberTermDoc = doc(userDocRef, 'MemberTerm', firebaseId);

          await setDoc(memberTermDoc, {
            Id: jresponse.id,
            IsDeleted: false,
            acg_Member__c: user.Id,
            acg_AcceptanceTermVersion__c: termId,
            acg_IPDevice__c: clientIP,
            acg_AcceptanceDevice__c: acceptanceDevice,
          });

          return {
            success: true,
          };
        }
      }
    } catch (error) {
      console.log('erro no envio', error);
    }

    return {
      success: false,
    };
  };

  getClientIP = async () => {
    if (global.ipAddress) {
      return global.ipAddress;
    }

    try {
      const ipFetch = await fetch('https://api.ipify.org?format=json');

      if (ipFetch.ok) {
        const ipRes = await ipFetch.json();
        global.ipAddress = ipRes.ip;
        return global.ipAddress;
      }
    } catch (e) {
      return null;
    }

    return null;
  };

  // Gera uma string de 6 dígitos onde os 2 últimos são números para conferência
  generateRandomAccessCode = (length = 6) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let charactersLength = characters.length;
    let charSum = 0;
    for (let i = 0; i < length - 2; i++) {
      let chosenChar = characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
      result += chosenChar;
      charSum += chosenChar.charCodeAt(0);
    }

    return result + (charSum + '').slice(-2);
  };

  // Valida o código do usuário no firebase
  validateAccessCode = async (UserFirebaseId, AccessCode) => {
    var herokuDomain = await this.dataHelper.getHerokuDomain();
    var validateAccessCodeUrl = herokuDomain + '/validateAccessCode';
    var herokuData = {
      UserFirebaseId,
      AccessCode,
    };

    try {
      var response = await fetch(validateAccessCodeUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(herokuData),
      });
      if (!response.error) return true;
    } catch (e) {}

    return false;
  };

  // Busca em Email, CPF_CNPJ__c e Username por usuário em uso. Só retorna false caso todos estejam livres.
  usernamesExists = async (userData, userFirebaseId) => {
    if (userData.Email) {
      let usernameDoc = await getDoc(doc(this.db, 'Usernames', userData.Email));
      let emailExists = false;

      // -- TODO - Quando Usernames estiver 100% populado, pode excluir esta condicional
      //           Pois quando estiver, se não existir em Usernames, não existirá em UserFirebaseId
      if (!usernameDoc.exists()) {
        let userFirebaseQ = query(
          collection(this.db, 'UserFirebaseId'),
          where('Email', '==', userData.Email)
        );
        let userFirebaseDocs = await getDocs(userFirebaseQ);

        if (userFirebaseDocs.size > 0) {
          userFirebaseDocs.forEach((doc) => {
            if (doc.data().UserFirebaseId !== userFirebaseId) {
              emailExists = true;
            }
          });
        }
      } else {
        if (usernameDoc.data().UserFirebaseId !== userFirebaseId) {
          emailExists = true;
        }
      }

      if (emailExists) {
        return {
          success: false,
          error: {
            code: 'auth/email-already-in-use',
          },
        };
      }
    }

    if (userData.CNPJ_CPF__c) {
      let cleanCpf = ToolHelper.getDigits(userData.CNPJ_CPF__c);
      let usernameDoc = await getDoc(doc(this.db, 'Usernames', cleanCpf));

      if (
        usernameDoc.exists() &&
        usernameDoc.data().UserFirebaseId !== userFirebaseId
      ) {
        return {
          success: false,
          error: {
            code: 'auth/cpfcnpj-already-in-use',
          },
        };
      }
    }

    if (userData.Username) {
      let usernameDoc = await getDoc(
        doc(this.db, 'Usernames', userData.Username)
      );

      if (
        usernameDoc.exists() &&
        usernameDoc.data().UserFirebaseId !== userFirebaseId
      ) {
        return {
          success: false,
          error: {
            code: 'auth/username-already-in-use',
          },
        };
      }
    }

    return false;
  };

  saveUser = async (userData) => {
    let originalUserData = await this.getUser();

    // Verifica se algum username está em uso
    let usernameResult;
    if (
      (usernameResult = await this.usernamesExists(
        userData,
        originalUserData?.FirebaseId__c
      ))
    ) {
      return usernameResult;
    }

    userData.EmailIsReal = !!userData.Email?.length;
    // userData.Password = userData.OriginalPassword = userData.Password || Math.random().toString();

    const auth = getAuth();

    if (originalUserData?.UID) {
      userData.UID = originalUserData.UID;
    }

    if (!userData.Email?.length && originalUserData?.Email) {
      userData.Email = originalUserData.Email;
    }

    if (userData.LoyaltyBirthdate__c === '') {
      userData.LoyaltyBirthdate__c = moment('01/01/2000').format('DD/MM/YYYY');
    }

    if (!userData.UID) {
      if (userData.Email) {
        userData.UsernameType = 'email';
        userData.AuthRegisterEmail = userData.Email.toLowerCase();
      } else {
        // verifica tipo de usuário
        let username_type = FormHelper.detectStringContent(userData.Username);
        userData.UsernameType = username_type;

        switch (username_type) {
          case 'cpf':
          case 'cnpj':
            userData.AuthRegisterEmail =
              ToolHelper.getDigits(userData.Username) + FAKE_DOMAIN;
            break;
          case 'text':
            userData.AuthRegisterEmail = userData.Username + FAKE_DOMAIN;
            break;
          // Inesperado entrar aqui, mantendo por redundancia
          case 'email':
            userData.AuthRegisterEmail = userData.Username;
            userData.EmailIsReal = true;
            break;
        }
      }

      try {
        let userCredential = await createUserWithEmailAndPassword(
          auth,
          userData.AuthRegisterEmail,
          userData.Password
        );

        if (this.customCfg?.afterCreateUser) {
          let result = await this.customCfg.afterCreateUser(userData, {
            userHelper: this,
          });
          if (result.success) {
            if (result.userData) {
              userData = result.userData;
            }
          } else {
            // rollback criação de usuário
            let user = this.getAuthUser();

            if (user) {
              await deleteUser(user);
            }

            return result;
          }
        }

        return this.editUserData(userData, userCredential);
      } catch (error) {
        return {
          success: false,
          error,
        };
      }
    } else {
      return this.editUserData(userData);
    }
  };

  // Parâmetros de entrada:
  // - username OU userFirebaseId (em userData)
  // Parâmetros de saída:
  // - success: define se processo de verificação foi feito com sucesso
  // - code: [user-exists,user-needs-password-definition,user-does-not-exist]
  //
  // > Se user-needs-password-definition, o usuário será logado com uma senha fixa.
  //   Neste caso, é necessário registrar que usuário precisa autenticar seu acesso e definir sua senha.
  checkUserExists = async (userData, screen) => {
    let { username, userFirebaseId } = userData;

    if (!username && !userFirebaseId) {
      return {
        success: false,
      };
    }

    let userFirebaseDoc = await this.findUserFirebaseDoc(userData);

    // custom checkUserExists
    if (this.customCfg?.checkUserExists) {
      let result = await this.customCfg.checkUserExists(
        userData,
        userFirebaseDoc,
        screen,
        this
      );
      if (
        result === false ||
        (typeof result === 'object' && (!result.success || result.haltProcess))
      ) {
        if (typeof result === 'object') {
          result.UserFirebaseId = userFirebaseDoc?.UserFirebaseId;
        }

        return result;
      }
    }

    if (userFirebaseDoc) {
      return {
        success: true,
        result: 'user-exists',
        UserFirebaseId: userFirebaseDoc?.UserFirebaseId,
      };
    }

    return {
      success: true,
      result: 'user-does-not-exist',
      UserFirebaseId: userFirebaseDoc?.UserFirebaseId,
    };
  };

  // Por enquanto a única forma de definir a senha, sem saber a senha antiga, é através de funções customizadas
  definePassword = async (UserFirebaseId, AccessCode, password) => {
    // custom definePassword
    if (this.customCfg?.definePassword) {
      let result = await this.customCfg.definePassword(
        UserFirebaseId,
        AccessCode,
        password,
        this
      );

      if (result.userData) {
        await this.editUserData(result.userData);
      }

      if (result.doLogin && result.username) {
        await this.userLogin(result.username, password);
      }

      if (
        result === false ||
        (typeof result === 'object' && (!result.success || result.haltProcess))
      ) {
        return result;
      }
    }

    return {
      success: true,
    };
  };

  setUserData = async (userData) => {
    let userDoc = { ...userData };

    delete userDoc.Password;
    delete userDoc.PasswordConfirmation;
    delete userDoc.OriginalPassword;
    delete userDoc.userFirebaseIdData;

    if (userDoc.Email || userDoc.AuthRegisterEmail) {
      userDoc.LoyaltyEmail__c = userDoc.Email =
        userDoc.Email.toLowerCase() || userDoc.AuthRegisterEmail;
    }

    if (userDoc.LoyaltyBirthdate__c)
      userDoc.LoyaltyBirthdate__c = moment(
        userDoc.LoyaltyBirthdate__c,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');

    if (userDoc.CNPJ_CPF__c)
      userDoc.CNPJ_CPF__c = ToolHelper.getDigits(userDoc.CNPJ_CPF__c);

    if (userData.FirebaseId__c) global.userFirebaseId = userData.FirebaseId__c;

    const ref = await this.getUserRef();
    const docResponse = setDoc(
      ref,
      {
        ...userDoc,
        lastSignInTime: serverTimestamp(),
      },
      { merge: true }
    );
    return docResponse;
  };

  subscribeAllTopics = async () => {
    /*
        TODO - integrar isso a buscar o token
        var notificationsTopics = await this.getTopics();
        var topics = '';
    
        for(i = 0; i < notificationsTopics.length; i++) {
          docData = notificationsTopics[i];
          topics += docData.FirebaseId__c + ';';
          getMessaging().subscribeToTopic(token, docData.FirebaseId__c);
        }
    
        return topics
    */
  };

  getTopics = async () => {
    let topicsQ = query(
      collection(this.db, 'NotificationTopic'),
      orderBy('Order__c')
    );
    let topicsDocs = await getDocs(topicsQ);

    let topics = [];
    if (topicsDocs.size > 0) {
      topicsDocs.forEach((doc) => {
        let docData = doc.data();

        if (docData.FirebaseId__c !== null && docData.FirebaseId__c !== '')
          topics.push(docData);
      });
    }

    return topics;
  };

  snapUserPoints = (callback) => {
    let pointsQ = query(
      collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'),
      orderBy('CreatedDate', 'desc')
    );

    let unsub = onSnapshot(pointsQ, (snapshot) => {
      let points = this.dataHelper.getList(snapshot);
      callback(points);
    });

    return unsub;
  };

  getUserPoints = async (qty, startAfterDoc = null) => {
    let userDocRef = await this.getUserRef();
    let pointsQ = query(
      collection(userDocRef, 'PointStatement'),
      orderBy('CreatedDate', 'desc'),
      limit(qty)
    );
    if (startAfterDoc) {
      pointsQ = query(pointsQ, startAfter(startAfterDoc));
    }
    let pointsDoc = await getDocs(pointsQ);

    let data = [];
    let lastDoc = null;

    if (pointsDoc.docs.length) {
      data = pointsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      });
    }

    return { data, lastDoc };
  };

  snapDepartment = async (callback) => {
    let departmentsQ = query(
      collection(
        this.db,
        'Users',
        await this.getUserFirebaseId(),
        'Departments'
      )
    );
    let unsub;
    if (departmentsQ) {
      unsub = onSnapshot(departmentsQ, (snapshot) => {
        let departments = this.dataHelper.getList(snapshot);
        callback(departments);
      });
    }

    return { departments: unsub };
  };

  // getDepartment = async () => {
  //   let userDocRef = await this.getUserRef();
  //   let departmentQ = query(collection(userDocRef,'Departments'));
  //   let departmentsDoc = await getDocs(departmentQ);

  //   let data = [];
  //   let lastDoc = null;

  //   if(departmentsDoc.docs.length){
  //     data = departmentsDoc.docs.map((doc) => {
  //       lastDoc = doc;
  //       return doc.data();
  //     })
  //   }

  //   return { data , lastDoc };
  // }

  googleLogin = (callback) => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    provider.addScope('email');

    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);

        const token = credential.accessToken;

        const user = result.user;

        // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
        let email = user?.providerData[0]?.email;

        const isNewUser = !email || !(await this.firestoreEmailExists(email));

        callback({
          success: true,
          isNewUser,
          user,
        });
      })
      .catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error);

        callback({
          success: false,
          error,
        });
      });
  };

  facebookLogin = (callback) => {
    const provider = new FacebookAuthProvider();

    provider.addScope('email');
    provider.setCustomParameters({
      display: 'popup',
    });

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;

        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
        let email = user?.providerData[0]?.email;

        const isNewUser = !email || !(await this.firestoreEmailExists(email));

        callback({
          success: true,
          isNewUser,
          user,
        });
      })
      .catch((error) => {
        const credential = FacebookAuthProvider.credentialFromError(error);

        callback({
          success: false,
          error,
        });
      });
  };

  recoverPassword = async (username) => {
    let userFirebaseDoc = await this.findUserFirebaseDoc({ username });

    if (!userFirebaseDoc) {
      return {
        success: false,
      };
    }

    let email = userFirebaseDoc.Email;

    const auth = getAuth();

    // custom beforeRecoverPassword
    if (this.customCfg?.beforeRecoverPassword) {
      let result = await this.customCfg.beforeRecoverPassword(
        username,
        userFirebaseDoc,
        'recoverpassword',
        this
      );
      if (
        result === false ||
        (typeof result === 'object' && (!result.success || result.haltProcess))
      ) {
        if (
          result?.code === 'user-needs-password-definition' ||
          result?.code === 'user-exists'
        ) {
          // await this.sendAccessCode(userFirebaseDoc.UserFirebaseId);
        }

        if (typeof result === 'object' && userFirebaseDoc?.UserFirebaseId) {
          result.UserFirebaseId = userFirebaseDoc.UserFirebaseId;
        }

        return result;
      }
    }

    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      UserFirebaseId: userFirebaseDoc.UserFirebaseId,
    };
  };

  updatePasswordData = async (accountId) => {
    const updatePasswordDataUrl = await this.dataHelper.getHerokuUrl(
      HEROKU_UPDATE_PASSWORD_DATA_URL
    );

    const request = await fetch(updatePasswordDataUrl + accountId, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: HEROKU_AUTHORIZATION,
      },
    });

    const result = await request.json();

    return result;
  };

  // userData: currentPassword / newPassword
  changePassword = async (userData) => {
    let user = this.getAuthUser();

    // custom beforeChangePassword
    if (this.customCfg?.beforeChangePassword) {
      let customData = { ...userData, Email: user.email };
      let result = await this.customCfg.beforeChangePassword(customData);

      if (result.haltProcess || !result.success) {
        return result;
      }
    }

    const {
      Account: { Id: accountId },
    } = await this.getUserDataByEmail(user.email);

    try {
      await this.updatePasswordData(accountId);

      await updatePassword(user, userData.NewPassword);

      // Garante que não há mais flag de obrigar troca de senha
      const userFirebaseIdRef = await this.getUserFirebaseIdRef();

      await setDoc(
        userFirebaseIdRef,
        {
          PasswordDefinitionIsRequired: false,
        },
        { merge: true }
      );

      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  };

  logout = (successCallback, errorCallback) => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        global.userFirebaseId = null;

        successCallback();
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  logoutAsync = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);

      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  };

  updateLoyalty = async () => {
    let userDocRef = await this.getUserRef();
    let userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    if (userDoc.exists()) {
      let userData = { ...data };
      return await this.createLoyalty(userData);
    }

    return false;
  };

  createLoyalty = async (userData) => {
    try {
      let userDocRef = await this.getUserRef();
      setDoc(
        userDocRef,
        { lastCreateLoyaltyTime: serverTimestamp() },
        { merge: true }
      );

      if (!userData.LoyaltyEmail__c || !userData.LastName) {
        return;
      }

      let sfUserData = {
        firstName: userData.FirstName,
        lastName: userData.LastName,
        phone: userData.LoyaltyPhone__c,
        birthdate: userData.LoyaltyBirthdate__c,
        loyaltyStreet: userData.loyaltyStreet,
        district: userData.acg_AddressDistrict__c,
        city: userData.LoyaltyCity__c,
        state: userData.LoyaltyState__c,
        country: userData.LoyaltyCountry__c,
        postalCode: userData.LoyaltyPostalCode__c,
        addressNumber: userData.numberApartament,
        addressComplement: userData.acg_AddressComplement__c,
        email: userData.LoyaltyEmail__c
          ? userData.LoyaltyEmail__c.toLowerCase()
          : '',
        emailValidated: userData.EmailValidated__c || false,
        facebookId: userData.FacebookId__c,
        googleId: userData.GoogleId__c,
        twitterId: userData.TwitterId__c,
        firebaseId: userData.FirebaseId__c,
        messagingToken: userData.MessagingToken__c,
        topics: userData.Topics__c,
        profissao: userData.Profissao__c,
        areaAtuacao: userData.AreaAtuacao__c,
        lojaRelacionamento: userData.LojaRelacionamento__c,
        userCategory: userData.LoyaltyCategory__c,
        cpf: userData.CPF__c,
        cnpj: userData.CNPJ_CPF__c,
        personDepartment: userData.PersonDepartment,
        promotionalCode: userData.promotionalCode,
        departament: userData.Department,
        MilestoneCategory__c:
          userData.MilestoneCategory__c || 'BITSTOTVER Iniciante',
      };

      if (userData.persistLoyaltySync) {
        sfUserData.persistLoyaltySync = userData.persistLoyaltySync;
      }

      var loyaltyUrl = await this.dataHelper.getHerokuUrl(HEROKU_LOYALTY_URL);
      var response = await fetch(loyaltyUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(sfUserData),
      });

      console.warn('#createLoyaltyResponse: ', response);

      return await response.json();
    } catch (error) {
      console.warn('createLoyaltyException', error);
    }

    return false;
  };

  setLastTimeOnline = async () => {
    const userData = await this.getUser();

    if (!userData) return null;

    const dateRef = moment().format('YYYY-MM-DD');
    const lastOnline = userData.lastTimeOnline
      ? moment(userData.lastTimeOnline.toDate()).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');

    const lastTimeNull = userData.lastTimeOnline == null;
    const isNew = dateRef > lastOnline;

    if (lastTimeNull || isNew) {
      await GameficationHelper.addRow(this, POINT_ACTIONS.DAILY_LOGIN);
    }

    let userDocRef = await this.getUserRef();

    if (userDocRef)
      await setDoc(
        userDocRef,
        { lastTimeOnline: serverTimestamp() },
        { merge: true }
      );
  };

  setProgramOptOut = async () => {
    const userData = await this.getUser();

    if (!userData) return null;

    const sfUserData = {
      cpf: userData.CNPJ_CPF__c,
      email: userData.LoyaltyEmail__c.toLowerCase(),
      lastName: userData.LastName,
      programOptOut: true,
    };

    try {
      const loyaltyUrl =
        (await this.dataHelper.getHerokuDomain()) +
        '/contactAdminBO/deleteUser';
      // const loyaltyUrl =  'http://localhost:3333' + '/contactAdminBO/deleteUser'
      // var loyaltyUrl = await this.dataHelper.getHerokuUrl(HEROKU_LOYALTY_URL);

      const response = await fetch(loyaltyUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(sfUserData),
      });

      return await response.json();
    } catch (error) {
      console.log('erro no envio HTTP', error);
    }
  };

  uploadAvatar = async (img) => {
    const userFirebaseId = await this.getUserFirebaseId();
    const storage = getStorage();
    const storageRef = ref(storage, `ProfilePictures/${userFirebaseId}.jpeg`);
    const metadata = { contentType: 'image/jpeg' };

    uploadBytes(storageRef, img, metadata).then((snapshot) => {
      getDownloadURL(storageRef).then(async (url) => {
        const ref = await this.getUserRef();

        const docResponse = setDoc(
          ref,
          {
            photoURL: url,
          },
          { merge: true }
        );

        return url;
      });
    });
  };

  setDocumentViewed = async (documentId) => {
    let userId = await this.getUserFirebaseId();

    if (documentId && userId) {
      let viewRef = doc(this.db, 'Users', userId, 'View', documentId);
      let viewDoc = await getDoc(viewRef);

      if (!viewDoc.exists()) {
        await GameficationHelper.addRow(
          this,
          POINT_ACTIONS.EVENT_CLICK_NEWS,
          documentId
        );
      } else {
        return false;
      }

      let viewData = {
        userId,
        lastViewDate: serverTimestamp(),
        documentId,
      };

      setDoc(viewRef, viewData, { merge: true });
    }
  };

  setBannerViewed = async (bannerId) => {
    await GameficationHelper.addRow(
      this,
      POINT_ACTIONS.EVENT_CLICK_ADS,
      bannerId
    );

    return true;
  };

  getFinishedSurveyIds = async (isQuiz) => {
    const finishedQ = collection(
      this.db,
      'Users',
      await this.getUserFirebaseId(),
      isQuiz ? 'QuizAnswers' : 'SurveyAnswers'
    );
    const finishedDoc = await getDocs(finishedQ);
    const finishedSurveys = this.dataHelper.docsToArray(finishedDoc);

    let out = finishedSurveys.map((finishedSurvey) => finishedSurvey.Id);
    return out;
  };

  getSurveyQuestionAnswers = async (isQuiz) => {
    const answersQ = collection(
      this.db,
      'Users',
      await this.getUserFirebaseId(),
      isQuiz ? 'QuizAnswersOption' : 'SurveyAnswersOption'
    );
    const answersDoc = await getDocs(answersQ);
    const answers = this.dataHelper.docsToArray(answersDoc);

    return answers;
  };

  surveySendAnswer = async (
    survey,
    question,
    selectedOptionIds, // array de strings
    textAnswer
  ) => {
    let cgny2__Action__c =
      POINT_ACTIONS.ACTION_VOTE_SURVEY_ANSWER +
      question.RecordType.DeveloperName.toUpperCase();
    let rightAnswer = false;
    const isQuiz = survey.FirebasePath__c === 'Quiz';

    if (
      typeof selectedOptionIds === 'object' &&
      selectedOptionIds?.length > 0
    ) {
      for (let i = 0; i < selectedOptionIds.length; i++) {
        const optId = selectedOptionIds[i];

        if (isQuiz) {
          if (optId === question.RightAnswer__c) {
            rightAnswer = true;
            cgny2__Action__c = POINT_ACTIONS.ACTION_QUIZ_RIGHT_ANSWER;
          } else {
            cgny2__Action__c = POINT_ACTIONS.ACTION_QUIZ_WRONG_ANSWER;
          }
        }

        await GameficationHelper.addRow(
          this,
          cgny2__Action__c,
          question.Id, //cgny2__GenericId__c,
          undefined, //cgny2__Event__c,
          textAnswer, //cgny2__Value__c,
          undefined, //cgny2__AdvertisingItem__c,
          undefined, //cgny2__Gallery__c,
          undefined, //cgny2__News__c,
          question.Survey__c, //cgny2__Survey__c,
          optId, //cgny2__SurveyQuestionOption__c,
          question.Id, //cgny2__SurveyQuestion__c,
          undefined //cgny2__Voucher__c
        );
      }
    } else {
      // Uma resposta pode ter apenas Answer e não ter opções selecionadas
      await GameficationHelper.addRow(
        this,
        cgny2__Action__c,
        question.Id, //cgny2__GenericId__c,
        undefined, //cgny2__Event__c,
        textAnswer, //cgny2__Value__c,
        undefined, //cgny2__AdvertisingItem__c,
        undefined, //cgny2__Gallery__c,
        undefined, //cgny2__News__c,
        question.Survey__c, //cgny2__Survey__c,
        undefined, //cgny2__SurveyQuestionOption__c,
        question.Id, //cgny2__SurveyQuestion__c,
        undefined //cgny2__Voucher__c
      );
    }

    let tmpQuestionAnswer = {
      Id: question.Id,
      question,
      rightAnswer,
      actionDate: serverTimestamp(),
    };

    if (survey.Status__c !== 'Test') {
      let questionDoc = doc(
        this.db,
        'Users',
        await this.getUserFirebaseId(),
        isQuiz ? 'QuizAnswersOption' : 'SurveyAnswersOption',
        question.Id
      );
      setDoc(questionDoc, tmpQuestionAnswer);
      //   this.props.surveyQuestionAnswersRef.doc(question.Id).set(tmpQuestionAnswer);
    }
  };

  surveySendClosure = async (survey) => {
    const isQuiz = survey.FirebasePath__c === 'Quiz';
    let surveyDoc = doc(
      this.db,
      'Users',
      await this.getUserFirebaseId(),
      isQuiz ? 'QuizAnswers' : 'SurveyAnswers',
      survey.Id
    );

    setDoc(surveyDoc, {
      Id: survey.Id,
      actionDate: serverTimestamp(),
    });

    const cgny2__Action__c = isQuiz
      ? POINT_ACTIONS.ACTION_QUIZ_COMPLETE
      : POINT_ACTIONS.ACTION_VOTE_SURVEY_COMPLETE;
    const cgny2__GenericId__c = survey.Id;

    GameficationHelper.addRow(
      this,
      cgny2__Action__c,
      cgny2__GenericId__c,
      undefined, //cgny2__Event__c,
      undefined, //cgny2__Value__c,
      undefined, //cgny2__AdvertisingItem__c,
      undefined, //cgny2__Gallery__c,
      undefined, //cgny2__News__c,
      survey.Id, //cgny2__Survey__c,
      undefined, //cgny2__SurveyQuestionOption__c,
      undefined, //cgny2__SurveyQuestion__c,
      undefined //cgny2__Voucher__c
    );
  };

  snapCases = async (callback) => {
    let casesQ = query(
      collection(this.db, 'Users', await this.getUserFirebaseId(), 'Case'),
      orderBy('CreatedDate', 'desc')
    );
    let unsub;
    if (casesQ) {
      unsub = onSnapshot(casesQ, (snapshot) => {
        let cases = this.dataHelper.getList(snapshot);
        callback(cases);
      });
    }

    return { cases: unsub };
  };

  saveNewGamificationsReceived = async (caseData, inputs) => {
    let aInputs = Object.entries(inputs);
    let requiredFields = [];
    aInputs.every((aInput) => {
      if (aInput[1].isRequired) requiredFields.push(aInput[0]);
      return true;
    });

    if (!FormHelper.validateRequiredFields(inputs, caseData)) {
      return {
        success: false,
      };
    }

    let images = [];
    if (caseData.Images__c) {
      images = JSON.parse(caseData.Images__c);
    }

    caseData.Images__c = images.map((image) => ({
      attachUrl: image.imageUrl,
      fileName: image.imageId + '.jpeg',
    }));

    const userData = await this.getUser();

    let requestBody = {
      Contact: userData.Id,
      DataHoraUpload: caseData.ValidateDocument,
      Nome: caseData.DocumentName,
      Status: caseData.Status,
      Tipo: caseData.Type,
      Observacao: caseData.Description,
      // Validade: case
      // RecordTypeId: await this.dataHelper.getCaseRecordTypeId(),
      Images: JSON.stringify(caseData.Images__c),
    };

    if (!global.isAuthentication) {
      requestBody = {
        Contact: userData.Id,
        DataHoraUpload: caseData.ValidateDocument,
        Nome: caseData.DocumentName,
        Status: caseData.Status,
        Tipo: caseData.Type,
        Observacao: caseData.Description,
        // Validade: case
        // RecordTypeId: await this.dataHelper.getCaseRecordTypeId(),
        Images: JSON.stringify(caseData.Images__c),
      };
    }

    // if(userData?.Id){
    //   requestBody.ContactId = userData.Id;
    //   requestBody.SuppliedEmail = userData.LoyaltyEmail__c;
    // }

    // return {
    //   success: true,
    // }

    let uploadDocsUrl = await this.dataHelper.getHerokuUrl(
      HEROKU_GAMIFICATIONSRECEIVE_URL
    );

    try {
      let response = await fetch(uploadDocsUrl, {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(requestBody),
      });

      return {
        success: true,
      };
    } catch (e) {
      return {
        success: false,
        error: e,
      };
    }
  };

  saveNewCase = async (caseData, inputs) => {
    let aInputs = Object.entries(inputs);
    let requiredFields = [];
    aInputs.every((aInput) => {
      if (aInput[1].isRequired) requiredFields.push(aInput[0]);
      return true;
    });

    if (!FormHelper.validateRequiredFields(inputs, caseData)) {
      return {
        success: false,
      };
    }

    let images = [];
    if (caseData.Images__c) {
      images = JSON.parse(caseData.Images__c);
    }

    caseData.Images__c = images.map((image) => ({
      attachUrl: image.imageUrl,
      fileName: image.imageId + '.jpeg',
    }));

    const userData = await this.getUser();

    let requestBody = {
      Type: caseData.Type,
      Reason: caseData.Reason,
      Subject: caseData.Subject,
      Description: caseData.Description,
      RecordTypeId: await this.dataHelper.getCaseRecordTypeId(),
      Images: JSON.stringify(caseData.Images__c),
      Phone: caseData.LoyaltyPhone__c,
    };

    if (!global.isAuthentication) {
      requestBody = {
        Type: caseData.Type,
        Reason: caseData.Reason,
        Subject: caseData.Subject,
        Description: caseData.Description,
        RecordTypeId: await this.dataHelper.getCaseRecordTypeId(),
        Images: JSON.stringify(caseData.Images__c),
        Phone: caseData.LoyaltyPhone__c,
        ContactName: caseData.Name,
        SuppliedEmail: caseData.EmailFaleConosco.toLowerCase(),
      };
    }

    if (userData?.Id) {
      requestBody.ContactId = userData.Id;
      requestBody.SuppliedEmail = userData.LoyaltyEmail__c.toLowerCase();
    }

    let caseUrl = await this.dataHelper.getHerokuUrl(HEROKU_CASE_URL);

    try {
      // 'http://localhost:3333/addGamificationsReceived'
      let response = await fetch(caseUrl, {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(requestBody),
      });

      return {
        success: true,
      };
    } catch (e) {
      return {
        success: false,
        error: e,
      };
    }
    // userData.Password = userData.Password || Math.random().toString();

    // const auth = getAuth();

    // let user = await this.getUser();
    // if(user?.UID){
    //   userData.UID = user.UID;
    // }

    // if(!userData.UID){
    //   try{
    //     let userCredential = await createUserWithEmailAndPassword(auth,userData.Email,userData.Password);
    //     return this.editUserData(userData,userCredential);
    //   }
    //   catch(error){
    //     return {
    //       success: false,
    //       error,
    //     };
    //   }
    // }
    // else{
    //   return this.editUserData(userData)
    // }
  };

  snapDistributors = (callback, filters = {}) => {
    let distributorsQ = query(
      collection(this.db, 'Account'),
      where('acg_ElegivelNF__c', '==', true)
    );

    if (filters.uf) {
      distributorsQ = query(
        distributorsQ,
        where('BillingAddress.stateCode', '==', filters.uf)
      );
    }

    return onSnapshot(
      distributorsQ,
      (snapshot) => {
        let distributors = this.dataHelper.getList(snapshot);

        if (filters.search) {
          const search = filters.search.toLowerCase() || '';
          const searchCNPJ = search.replace(/[./-]+/g, '');

          // Como firestore não faz OR entre mais de um campo, melhor filtrar aqui mesmo
          distributors = distributors.filter((distributor) => {
            return (
              distributor.Name?.toLowerCase()?.includes(search) ||
              distributor.BillingAddress?.city
                ?.toLowerCase()
                ?.includes(search) ||
              distributor.TaxID__c?.toLowerCase()?.includes(searchCNPJ)
            );
          });
        }

        callback(distributors);
      },
      (error) => {
        console.log('error', error);
      }
    );
  };

  snapInvoices = (callback, filters = {}) => {
    /* * * * * PEGANDO A PARTIR DA COLLECTION */
    // let invoicesQ = query(collection(this.db,'Users', await this.getUserFirebaseId(), 'Invoices'));
    let invoicesQ = query(
      collection(this.db, 'Users', global.userFirebaseId, 'Invoices')
    );
    let hasSetDateOrder = false;
    let field;
    let searchDigits = '';

    if (filters.period) {
      if (filters.period.includes('issue')) {
        field = 'acg_IssueDate__c';
      } else {
        field = 'CreatedDate';
      }

      let startDate;
      let endDate;

      if (filters.period.includes('-last')) {
        startDate = new Date();
        let daysQty = parseInt(filters.period.split('-')[2]);
        startDate.setDate(startDate.getDate() - daysQty);
      } else if (filters.period.includes('-period')) {
        if (filters.startDate) {
          startDate = new Date(
            filters.startDate.split('/').reverse().join('-')
          );
        }

        if (filters.endDate) {
          endDate = new Date(filters.endDate.split('/').reverse().join('-'));
        }
      }

      if (startDate instanceof Date && !isNaN(startDate)) {
        let startOfTheDay = startDate.toISOString().split('T')[0];

        if (field === 'CreatedDate') {
          startOfTheDay += 'T00:00:00';
        }

        invoicesQ = query(invoicesQ, where(field, '>=', startOfTheDay));
        invoicesQ = query(invoicesQ, orderBy(field, 'desc'));
        hasSetDateOrder = true;
      }

      if (endDate instanceof Date && !isNaN(endDate)) {
        let endOfTheDay = endDate.toISOString().split('T')[0];

        if (field === 'CreatedDate') {
          endOfTheDay += 'T23:59:59';
        }

        invoicesQ = query(invoicesQ, where(field, '<=', endOfTheDay));

        if (!startDate) {
          invoicesQ = query(invoicesQ, orderBy(field, 'desc'));
          hasSetDateOrder = true;
        }
      }
    }

    if (filters.uf) {
      invoicesQ = query(invoicesQ, where('acg_UF__c', '==', filters.uf));
    }

    if (filters.status) {
      invoicesQ = query(
        invoicesQ,
        where('acg_Status__c', '==', filters.status)
      );
    }

    if (!hasSetDateOrder || field !== 'CreatedDate')
      invoicesQ = query(invoicesQ, orderBy('CreatedDate', 'desc'));

    return onSnapshot(
      invoicesQ,
      (snapshot) => {
        let invoices = this.dataHelper.getList(snapshot);

        if (filters.search) {
          // Como firestore não faz OR entre mais de um campo, melhor filtrar aqui mesmo
          let searchDigits = ToolHelper.getDigits(filters.search);

          if (searchDigits.length) {
            invoices = invoices.filter((invoice) => {
              return (
                invoice.Name?.includes(searchDigits) ||
                invoice.acg_Distributor__r?.TaxID__c?.includes(searchDigits)
              );
            });
          } else {
            // Se não há digitos, não há o que ser buscado pois
            // invoice.Name e CNPJ são só digitos.
            invoices = [];
          }
        }

        callback(invoices);
      },
      (error) => {
        console.log('error', error);
      }
    );
  };

  snapInvoiceHistory = async (callback, invoiceId) => {
    if (invoiceId?.length === 18) {
      invoiceId = invoiceId.slice(0, 15);
    }
    let invoiceHistoryQ = query(
      collection(
        this.db,
        'Users',
        await this.getUserFirebaseId(),
        'Invoices',
        invoiceId,
        'InvoicesHistories'
      ),
      orderBy('CreatedDate', 'desc')
    );

    let unsub = onSnapshot(
      invoiceHistoryQ,
      (snapshot) => {
        let invoiceHistories = this.dataHelper.getList(snapshot);
        callback(invoiceHistories);
      },
      (error) => {
        console.log('error', error);
      }
    );

    return unsub;
  };

  createInvoice = async (data) => {
    const userData = await this.getUser();
    let result = {
      status: 'Error',
    };

    data.growerId = userData.Id;

    let invoiceUrl = await this.dataHelper.getHerokuUrl(HEROKU_INVOICE_URL);

    try {
      let response = await fetch(invoiceUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        result = response.json();
      }
    } catch (e) {}

    return result;
  };

  reanalysisInvoiceRequest = async (data) => {
    let invoiceUrl = await this.dataHelper.getHerokuUrl(
      HEROKU_INVOICE_REANALYSIS_REQUEST_URL
    );

    try {
      let response = await fetch(invoiceUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(data),
      });

      return {
        success: response?.ok,
      };
    } catch (e) {
      return {
        success: false,
        error: e,
      };
    }
  };

  deleteInvoice = async (data) => {
    let invoiceUrl = await this.dataHelper.getHerokuUrl(
      HEROKU_INVOICE_DELETE_URL
    );

    try {
      let response = await fetch(invoiceUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(data),
      });

      return {
        success: response?.ok,
      };
    } catch (e) {
      return {
        success: false,
        error: e,
      };
    }
  };

  // Novo "snapUserPoints"
  snapPoints = async (filters = {}) => {
    let pointsQ = query(
      collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'),
      orderBy('CreatedDate', 'desc')
    );
    // const queryPoint = await getDoc(doc(collection(this.db,'Users', global.userFirebaseId, 'PointStatement')));
    let hasSetDateOrder = false;
    let field;

    let result = [];
    const querySnapshot = await getDocs(pointsQ);

    querySnapshot.forEach((doc) => {
      result.push(doc.data());
      // doc.data() is never undefined for query doc snapshots
    });

    const converteData = (DataDDMMYY) => {
      const dataSplit = DataDDMMYY.split('/');
      const data = new Date(
        parseInt(dataSplit[2], 10),
        parseInt(dataSplit[1], 10) - 1,
        parseInt(dataSplit[0], 10)
      );
      return data;
    };

    if (filters) {
      if (filters.period) {
        // const periodList = {
        //   'Últimos 7 dias' : 'created-last-7',
        //   'Últimos 15 dias' : 'created-last-15',
        //   'Últimos 30 dias' : 'created-last-30',
        //   'Últimos 60 dias' : 'created-last-60',
        //   'Por período de ação' : 'created-period',
        //   'Por período de expiração' : 'expiration-period',
        // }

        // const period = periodList[filters.period];

        if (filters.period === 'created-period') {
          const initalDate = converteData(filters.startDate);
          const finalDate = converteData(filters.endDate);

          result = result.filter((data) => {
            const date = moment(data.CreatedDate).format('DD/MM/YYYY');
            const dateExtract = converteData(date);
            return dateExtract >= initalDate && dateExtract <= finalDate;
          });
        }
        if (filters.period === 'expiration-period') {
          const initalDate = converteData(filters.startDateEmission);
          const finalDate = converteData(filters.endDateEmission);
          result = result.filter((data) => {
            const dateExtract = converteData(data.expireDate);
            return dateExtract >= initalDate && dateExtract <= finalDate;
          });
        }

        if (filters.period == undefined) {
          return result;
        }

        if (filters.period?.includes('issue')) {
          field = 'acg_IssueDate__c';
        } else {
          field = 'CreatedDate';
        }

        let startDate;
        let endDate;

        if (filters.period.includes('-last')) {
          startDate = new Date();
          let daysQty = parseInt(filters.period.split('-')[2]);
          startDate.setDate(startDate.getDate() - daysQty);
        } else if (filters.period.includes('-period')) {
        }

        if (startDate instanceof Date && !isNaN(startDate)) {
          let startOfTheDay = startDate.toISOString().split('T')[0];

          if (field === 'CreatedDate') {
            startOfTheDay += 'T00:00:00';
          }

          result = result.filter((invoice) => {
            if (field == 'CreatedDate') {
              if (invoice.CreatedDate >= startOfTheDay) {
                return invoice;
              }
              if (invoice.endDate >= startOfTheDay) {
                return invoice;
              }
            }
          });
        }

        if (endDate instanceof Date && !isNaN(endDate)) {
          let endOfTheDay = endDate.toISOString().split('T')[0];

          if (field === 'CreatedDate') {
            endOfTheDay += 'T23:59:59';
          }
          result = result.filter((invoice) => {
            if (field == 'CreatedDate') {
              if (invoice.CreatedDate >= endOfTheDay) {
                return invoice;
              }
              if (invoice.endDate >= endOfTheDay) {
                return invoice;
              }
            }
          });
        }
      }

      // if(filters.uf){
      //   pointsQ = snapPointsQuery.where('acg_GamifiedSalesRecord__r.acg_SalesRecord__r.acg_Invoice__r.acg_UF__c','==',filters.uf)
      // }

      if (filters.action) {
        // const actionOptions = {
        //   'Todas as ações': '',
        //   Resgates : 'Redeem',
        //   Pontuações: 'Earned',
        //   Expirações: 'Expired',
        //   Bônus: 'Bonus',
        //   Fornecidos: 'Manual'
        // }

        // const action = actionOptions[filters.action];

        result = result.filter((data) => {
          return data.Action__c === filters.action;
        });
      }
    }

    // if(filters.period){
    //   if(filters.period.includes('expiration')){
    //     field = 'ExpirationDate__c';
    //   }
    //   else{
    //     field = 'CreatedDate';
    //   }

    //   let startDate;
    //   let endDate;

    //   if(filters.period.includes('-last')){
    //     startDate = new Date();
    //     let daysQty = parseInt(filters.period.split('-')[2])
    //     startDate.setDate(startDate.getDate() - daysQty);
    //   }
    //   else if(filters.period.includes('-period')){
    //     if(filters.startDate){
    //       startDate = new Date(filters.startDate.split('/').reverse().join('-'))
    //     }

    //     if(filters.endDate){
    //       endDate = new Date(filters.endDate.split('/').reverse().join('-'))
    //     }
    //   }

    //   if(startDate){
    //     let startOfTheDay = startDate.toISOString().split('T')[0] + 'T00:00:00';
    //     pointsQ = query(pointsQ,where(field,'>=',startOfTheDay))
    //     pointsQ = query(pointsQ,orderBy(field,'desc'))
    //     hasSetDateOrder = true;
    //   }

    //   if(endDate){
    //     let endOfTheDay = endDate.toISOString().split('T')[0] + 'T23:59:59';
    //     pointsQ = query(pointsQ,where(field,'<=',endOfTheDay))

    //     if(!startDate){
    //       pointsQ = query(pointsQ,orderBy(field,'desc'))
    //       hasSetDateOrder = true;
    //     }
    //   }
    // }

    // if(filters.uf){
    //   pointsQ = query(pointsQ,where('acg_GamifiedSalesRecord__r.acg_SalesRecord__r.acg_Invoice__r.acg_UF__c','==',filters.uf))
    // }

    // if(filters.action){
    //   pointsQ = query(pointsQ,where('Action__c','==',filters.action))
    // }

    // if(!hasSetDateOrder || field !== 'CreatedDate')
    //   pointsQ = query(pointsQ,orderBy('CreatedDate','desc'))

    // return onSnapshot(pointsQ,
    //   (snapshot) => {
    //     let points = this.dataHelper.getList(snapshot);

    //     // removendo linhas que possuem acg_StatusConsultant__c
    //     points = points.filter(point => !point?.acg_StatusConsultant__c?.length)

    //     callback(points);
    //   },(error) => {
    //     console.log('error',error)
    //   })

    return result;
  };
}
