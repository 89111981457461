import CoreLoader from './components/utils/CoreLoader.js';
import firebaseConfig from './config/credentials/firebase';
import LAYOUT from './config/layout/general';
import PRESETS from './config/layout/presets';
import THEME from './config/layout/theme/defaultConfig.js';
import COLORS from './config/layout/colors';
import routes, { routesO } from './config/routes'; // Route list
import Userform from './config/Userform';
import Caseform from './config/Caseform';
import UploadDocument from './config/UploadDocument';
import APPCONFIG from './config/appconfig';

import logo from './assets/logo.png';
import placeholder from './assets/placeholder.png';

function App() {
  const configFiles = {
    firebaseConfig,
    APPCONFIG,
    LAYOUT,
    PRESETS,
    THEME,
    COLORS,
    routes,
    routesO,
    Userform,
    Caseform,
    UploadDocument,
    logo,
    placeholder,
    getConfigFile,
  }

  return (
    <>
      <CoreLoader configFiles={configFiles}/>
    </>
  );
}

export default App;

const getConfigFile = async (path) => {
  let result;

  try{
    result = await import('./config/'+path);
  }
  catch(e){
    result = false;
  }

  return result;
}