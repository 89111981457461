import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import styled from '@emotion/styled';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { onMessage } from 'firebase/messaging';
import { Helmet } from 'react-helmet';
import { Router, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ShowModalLoginContext } from '../../provider/ShowModalLogin.js';
import { CampaignMemberProvider } from '../../provider/CampaignMemberData.js';
import { PartnerProvider } from '../../provider/PartnerProvider.js';
import { GamificationProvider } from '../../provider/GamificationProvider.js';
import { BenefitProvider } from '../../provider/BenefitProvider.js';
import { ProductsProvider } from '../../provider/ProductsProvider.js';
import { FavoritesAndRecommendationsProvider } from '../../provider/FavoritesAndRecommendationsProvider';
import HeaderV2 from '../Header/HeaderV2.js';
import HeaderBack from '../HeaderBack.js';
import FloatingMessageContainer from '../Molecules/FloatingMessageContainer.js';
import FooterLinks from '../Molecules/FooterLinks.js';
import Loader from '../Molecules/Loader.js';
import NotificationContainer from '../Molecules/NotificationContainer.js';
import Popup from '../Molecules/Popup.js';
import RightPanel from '../Molecules/RightPanel';
import SubHeader from '../SubHeader.js';
import DeclareRoutes from '../routes/DeclareRoutes.js';
import DataHelper from '../utils/DataHelper';
import MessageHelper from '../utils/MessageHelper';
import ToolHelper from '../utils/ToolHelper';
import UserHelper from '../utils/UserHelper';

const CoreLoader = (props) => {
  let { configFiles, match } = props;
  ReactGA.initialize(configFiles.firebaseConfig.trackingId);
  let app = initializeApp(configFiles.firebaseConfig);
  let db = getFirestore(app);
  let userHelper = useMemo(() => {
    return new UserHelper(app, db, configFiles.getConfigFile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let dataHelper = useMemo(() => {
    return new DataHelper(app, db, configFiles.getConfigFile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  userHelper.setDataHelper(dataHelper);
  dataHelper.setUserHelper(userHelper);

  const history = useHistory();
  const location = useLocation();
  const popup = ToolHelper.usePopup();
  const rightPanel = ToolHelper.useRightPanel();
  const notification = MessageHelper.useNotification();
  const message = MessageHelper.useMessage();

  const [cases, setCases] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [news, setNews] = useState([]);
  const [banners, setBanners] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [ytVideos, setYTVideos] = useState([]);
  const [unsubFunctions, setUnsubFunctions] = useState({});
  const [user, setUser] = useState({});
  const [hasAuthResponse, setHasAuthResponse] = useState(false);
  const [screenProps, setScreenProps] = useState({});
  const [isLoadedSnapshots, setIsLoadedSnapshots] = useState(false);
  const [doLoadSnapshots, setDoLoadSnapshots] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [authObserver, setAuthObserver] = useState(null);
  const [headerConfig, setHeaderConfig] = useState({});
  const [departments, setDepartments] = useState([]);
  const [startUpMessage, setStartUpMessage] = useState([]);
  const [config, setConfig] = useState({
    appconfig: configFiles.APPCONFIG,
    layout: configFiles.LAYOUT,
    presets: configFiles.PRESETS,
    colors: configFiles.COLORS,
    theme: configFiles.THEME,
    logo: configFiles.logo,
    placeholder: configFiles.placeholder,
    Userform: configFiles.Userform,
    Caseform: configFiles.Caseform,
    UploadDocument: configFiles.UploadDocument,
    firebaseConfig: configFiles.firebaseConfig,
  });
  const path = window.location.pathname.split('/')[1];
  const { showModalLogin, setShowModalLogin } = useContext(
    ShowModalLoginContext
  );

  const unsubscribe = () => {
    if (unsubFunctions && Object.keys(unsubFunctions).length > 0) {
      let unsubArray = Object.entries(unsubFunctions);

      unsubArray.forEach((unsub) => {
        if (unsub[1] && typeof unsub[1] === 'function') {
          unsub[1]();
        }
      });

      setDoLoadSnapshots(false);
      setIsLoadedSnapshots(false);
      setUnsubFunctions({});
      setUser({});
    }

    navigator.serviceWorker.removeEventListener(
      'message',
      windowMessageHandler
    );
  };

  const windowMessageHandler = (params) => {
    if (params?.data?.messageType === 'notification-clicked') {
      notification.add(params.data.notification, params.data.data);
    }
  };

  const loadThemeConfig = async () => {
    const importedFile = await ToolHelper.importByUserType(
      'layout/theme',
      configFiles.getConfigFile
    );
    setConfig((c) => {
      c.theme = importedFile.default;
      return c;
    });
  };

  const loadHeaderConfig = async () => {
    const importedFile = await ToolHelper.importByUserType(
      'header',
      configFiles.getConfigFile
    );
    setHeaderConfig(importedFile.default);
  };

  const loadStrings = async () => {
    const importedFile = await ToolHelper.importByUserType(
      'basics/strings',
      configFiles.getConfigFile
    );
    setConfig((c) => {
      c.strings = importedFile.default;
      return { ...c };
    });
  };

  const startAuthObserver = async () => {
    if (!authObserver) {
      const authState = onAuthStateChanged(getAuth(), async () => {
        let userRef = await userHelper.getUserFirebaseId();

        if (userRef) {
          global.isAuthenticated = true;
          await userHelper.setLastTimeOnline();

          if (!isLoadedSnapshots) {
            setDoLoadSnapshots(true);
          }
        } else {
          if (!isLoadedSnapshots) {
            setDoLoadSnapshots(true);
          }
          setHasAuthResponse(true);
          global.isAuthenticated = false;
        }
      });
      setAuthObserver(authState);
      setUnsubFunctions((us) => {
        us['authState' + new Date().getTime()] = authState;
        return us;
      });
    }
  };

  const loadSnapshots = async () => {
    let unsubT = {};

    await dataHelper.loadCurrentSeasonId();
    if (global.isAuthenticated) {
      unsubT = {
        ...unsubT,
        ...(await userHelper.snapUser((data) => {
          setUser(data);
        })),
      };

      unsubT = {
        ...unsubT,
        ...(await userHelper.snapCases((data) => {
          setCases(data);
        })),
      };

      unsubT = {
        ...unsubT,
        ...(await userHelper.snapDepartment((data) => {
          setDepartments(data);
        })),
      };

      unsubT = {
        ...unsubT,
        ...dataHelper.snapNews((list) => {
          setNews(list);
        }),
      };

      unsubT = {
        ...unsubT,
        ...{
          invoices: userHelper.snapInvoices((list) => {
            setInvoices(list);
          }),
        },
      };
    }

    unsubT = {
      ...unsubT,
      ...dataHelper.snapMilestones((list) => {
        if (list) {
          setMilestones(list);
        }
      }),
    };

    unsubT = {
      ...unsubT,
      ...dataHelper.snapBanners((list) => {
        setBanners(list);
      }),
    };

    unsubT = {
      ...unsubT,
      ...dataHelper.snapYTVideos((list) => {
        setYTVideos(list);
      }),
    };

    unsubT = {
      ...unsubT,
      ...dataHelper.snapTrainings((list) => {
        setTrainings(list);
      }),
    };

    unsubT = {
      ...unsubT,
      ...(await dataHelper.getStartUpMessages()),
    };

    setStartUpMessage(await dataHelper.getStartUpMessages());
    setIsLoadedSnapshots(true);
    setDoLoadSnapshots(false);
    setUnsubFunctions((us) => ({ ...us, ...unsubT }));
  };

  const changeScreenProps = () => {
    setScreenProps({
      match,
      math: Math.random(),
      setShowHeader,
      userHelper,
      dataHelper,
      history,
      unsubscribe,
      routes: configFiles.routesO,
      data: {
        user,
        cases,
        milestones,
        banners,
        news,
        trainings,
        ytVideos,
        startUpMessage,
        showHeader,
        invoices,
        departments
      },
      config,
      headerConfig,
      popup,
      rightPanel,
      notification,
      message,
      getConfigFile: configFiles.getConfigFile,
    });
  };

  useEffect(() => {
    changeScreenProps();
  }, [
    user,
    milestones,
    banners,
    news,
    trainings,
    ytVideos,
    startUpMessage,
    config,
    showHeader,
    departments
  ], []);

  useEffect(async () => {
    if (user?.Id) {
      await userHelper.setUserType(user);
    }
    loadStrings();
    loadThemeConfig();
    loadHeaderConfig();
    setHasAuthResponse(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(async () => {
    if (user?.Id) {
      // TODO - É necessário organizar esta chamada com outras chamadas que dependem do userType.
      // Além disso, estas chamadas precisam de unsubscribe e precisam respeitar isLoadedSnapshots e doLoadSnapshots
      let ta = async () => {
        let tt = (
          await ToolHelper.importByUserType(
            'db/news',
            configFiles.getConfigFile
          )
        ).default;
        dataHelper.snapConfig(tt, (list) => {
          setNews(list);
        });
      };
      ta();
      // ------------------------------------------
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.Id]);

  useEffect(() => {
    setTimeout(() => {
      ReactGA.send('pageview');
    }, 200);

    setHasAuthResponse(false);
    startAuthObserver();
    message.closeAllPageMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(async () => {
    if (user?.Id || user?.UID) {
      const route = ToolHelper.getRouteByLocation(
        location,
        configFiles.routesO
      );

      global.needsToValidateAccessCode = false;
      global.needsToValidateTermsAgreement = false;

      if (route?.access !== 'public') {
        // Tem código de acesso?
        // if(!(await userHelper.isAccessCodeValid(user))){
        //   global.needsToValidateAccessCode = true;
        //   history.push(configFiles.routesO.accessCode.path,{UserFirebaseId: user.UserFirebaseId});
        //   return;
        // }
        // else{
        // }

        // Precisa alterar senha?
        // if(await userHelper.isRequiredPasswordDefinition()){
        //   history.push(configFiles.routesO.definePassword.path,{force: true});
        //   return;
        // }

        // Precisa aceitar termos?
        if (
          (await userHelper.isRequiredTermsAgreement(user)) &&
          location.pathname !== configFiles.routesO.caseNew.path
        ) {
          global.needsToValidateTermsAgreement = true;
          history.push(configFiles.routesO.termsAgreement.path);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.Id, user.UID, location.pathname]);

  useEffect(() => {
    if (navigator?.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', windowMessageHandler);

      onMessage(userHelper.getMessaging(), (payload) => {
        notification.add(payload.notification, payload.data);
      });

      return () => unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadSnapshots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoadSnapshots, isLoadedSnapshots, showModalLogin.isVisible]);

  useEffect(() => {
    if (showModalLogin.isVisible) {
      const notify = () =>
        toast(showModalLogin.message, {
          type: showModalLogin.type,
          theme: 'colored',
        });
      notify();
      setTimeout(() => {
        setShowModalLogin({
          isVisible: false,
          title: '',
          message: '',
          type: 'info',
        });
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalLogin.isVisible]);

  const noShowHeader = ['', 'login', 'cadastro', 'player1', 'lumx'];

  const pathWithoutHeaderBack = [
    '/trilhas/',
    '/missoes/',
    '/produtos/',
    '/resgatar-beneficios/',
  ];
  const noShowHeaderBack = pathWithoutHeaderBack.some((caminho) =>
    location.pathname.startsWith(caminho)
  );

  return (
    <>
      <CampaignMemberProvider user={user} {...userHelper} {...screenProps}>
        <GamificationProvider {...screenProps} {...userHelper}>
          <ProductsProvider {...screenProps} {...userHelper}>
            <PartnerProvider {...screenProps} {...userHelper}>
              <BenefitProvider {...screenProps} {...userHelper}>
                <FavoritesAndRecommendationsProvider user={user} userHelper={userHelper} {...screenProps}>
                  <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                    <script
                      type='text/javascript'
                      id='hs-script-loader'
                      async
                      defer
                      src='//js.hs-scripts.com/2287241.js'
                    ></script>
                  </Helmet>
                  {!hasAuthResponse ? (
                    <Loader />
                  ) : (
                    <Router history={history}>
                      <Suspense fallback={<Loader />}>
                        <Container>
                          <HeaderV2
                            {...screenProps}
                            headerConfig={headerConfig}
                          />
                          {noShowHeader.includes(path) ? null : (
                            <>
                              <SubHeader {...screenProps} />
                              {!noShowHeaderBack && <HeaderBack />}
                            </>
                          )}
                          <ToastContainer />

                          <Content removeFooter={path === 'player1' && true}>
                            <DeclareRoutes
                              screenProps={screenProps}
                              routes={configFiles.routes}
                              routesO={configFiles.routesO}
                              popup={popup}
                              config={config}
                              userHelper={userHelper}
                              history={history}
                            />
                          </Content>
                          <Popup control={popup} />
                          <RightPanel control={rightPanel} />
                          <NotificationContainer
                            control={notification}
                            config={config}
                          />
                          <FloatingMessageContainer
                            control={message}
                            config={config}
                          />
                          <FloatingMessageContainer
                            control={message}
                            config={config}
                          />
                          {path !== 'player1' && (
                            <Footer>
                              <FooterLinks {...screenProps} />
                            </Footer>
                          )}
                        </Container>
                      </Suspense>
                    </Router>
                  )}
                </FavoritesAndRecommendationsProvider>
              </BenefitProvider>
            </PartnerProvider>
          </ProductsProvider>
        </GamificationProvider>
      </CampaignMemberProvider>
    </>
  );
};

export default CoreLoader;

const Container = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Content = styled.main`
  padding-bottom: ${(props) => (props.removeFooter ? 0 : '100px')};
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
