import styled from "@emotion/styled";

const style = (props) => {
  return {
    container: {
      // position:'relative'
    },
    errorMessage:{
      // position:'absolute',
      width:'100%',
      // paddingTop:,
      fontSize:'0.8em',
      color:'red'
    },
  }
}

export default style;

export const StyledCheckbox = styled.label`
  cursor: pointer;
  font-size: 14px;
  user-select: none;

  input {
    display: none;
    margin: 0;
    margin-bottom: 0;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    vertical-align: bottom;

    & + span {
      width: 1em;
      height: 1em;
      display: inline-block;
      background-color: ${props => props.config?.themeLayout?.background};
      border: 1px solid ${props => props.config?.themeLayout?.text};
      margin-right: 0.4em;
      font-size: 1em;
      
      &:after{
        display: block;
        margin-top: -0.3em;
        padding-left: 1px;
        content: '✔';
        color: transparent;
      }
    }

    &:checked + span{
      background-color: ${props => props.config?.themeLayout?.text};
      border: 1px solid ${props => props.config?.themeLayout?.text};

      &:after{
        color: ${props => props.config?.themeLayout?.background || 'black'};
      }
    }
  }

  span.check-text {
    color: ${props => props.config?.themeLayout?.text};

    a {
      color: ${props => props.config?.themeLayout?.link};
    }
  }
`
