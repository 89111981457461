import { collection, onSnapshot, query } from 'firebase/firestore';
import { createContext, useEffect, useState } from 'react';
import db from '../config/db/firebasedb';

export const CampaignMemberContext = createContext({});

export const CampaignMemberProvider = ({ children, user }) => {
  const [userCampaignId, setUserCampaignId] = useState([]);
  const [badgesUser, setBadgesUser] = useState([]);

  useEffect(() => {
    const getCampaignMember = (firebaseId) => {
      const q = query(collection(db, 'Users', firebaseId, 'CampaignMember'));
      onSnapshot(q, (querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          const dataDoc = doc.data();
          data.push(dataDoc?.CampaignId);
        });
        setUserCampaignId(data);
      });
    };
    const getBadgesUser = (firebaseId) => {
      const badgesRef = collection(db, 'Users', firebaseId, 'Badges');

      const unsubscribe = onSnapshot(badgesRef, (querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            const dataDoc = doc.data();
            data.push(dataDoc);
          }
        });
        setBadgesUser(data);
      });

      return unsubscribe;
    };

    if (user && user?.FirebaseId__c) {
      getCampaignMember(user?.FirebaseId__c);
      getBadgesUser(user?.FirebaseId__c);
    }
  }, [user]);

  return (
    <CampaignMemberContext.Provider
      value={{
        user,
        userCampaignId,
        badgesUser,
      }}>
      {children}
    </CampaignMemberContext.Provider>
  );
};
