import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import HeaderDropMenu from './HeaderDropMenu';
import Avatar from '../Molecules/Avatar';
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../configs/consts';
import { SlArrowDown, SlBell } from 'react-icons/sl';
import bellIcon from '../../components/assets/Frame_13.png';
import promoCodeIcon from '../../components/assets/promoCodeIcon.png';
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import moment from 'moment';
import { Input } from '../../components/Input/index.tsx';
import { Button } from '../../components/Button/index.tsx';
import { ShowModalLoginContext } from '../../provider/ShowModalLogin';

function HeaderUserV2(props) {
  const {
    data,
    isOpen,
    toggleIsOpen,
    userHelper,
    notification,
    headerConfig,
    config,
    rightPanel,
    message,
  } = props;
  const thisRef = useRef(null);
  const [historyListNotification, setHistoryListNotification] = useState([]);
  const inputRef = useRef();
  const isAuth = global.isAuthenticated;
  const { setShowModalLogin } = useContext(ShowModalLoginContext);

  const handleOpenDetails = () => {
    props.rightPanel.setMaxWidth(520);
    props.rightPanel.setContent(() => (
      <HistoryNotificationContainer>
        <h2> Histórico de notificações </h2>
        {historyListNotification.length > 0 ? (
          historyListNotification.map((message) => {
            const date = new Date(message?.CreatedDate);
            return (
              <HistoryNotificationItem key={message?.Id}>
                <span className='titlePushNotification'>
                  {message?.Title__c}
                </span>

                <span
                  className='DescriptionPushNotification'
                  dangerouslySetInnerHTML={{ __html: message?.Description__c }}
                />

                <span className='DatePushNotification'>
                  {date?.toLocaleDateString('pt-BR')}
                </span>
              </HistoryNotificationItem>
            );
          })
        ) : (
          <>
            <span className='emptyNotification'>
              Sem notificações no momento.
            </span>
          </>
        )}
      </HistoryNotificationContainer>
    ));
    props.rightPanel.setIsOpen(true);
  };

  const PopupPromotional = () => {
    const [loading, setLoading] = useState(false);

    const [promotionalCode, setPromotionalCode] = useState('');

    const validPromocode = async () => {
      const user = props?.data.user;
      if (promotionalCode.length > 0) {
        const promoCodeList = await props.dataHelper.getPromeCode();

        const promotionalCodeText = promoCodeList.filter(
          (data) => data.Name === promotionalCode
        );

        if (promotionalCodeText.length > 0) {
          if (
            promotionalCodeText[0]?.Remaining__c === 0 ||
            promotionalCodeText[0]?.Valid__c === false ||
            user.promotionalCode === promotionalCode
          ) {
            return {
              valid: false,
            };
          }
          return {
            valid: true,
            promoCode: promotionalCodeText,
          };
        }
        return {
          valid: false,
        };
      }
    };

    const handlePromotionalCode = async (ev) => {
      setLoading(true);
      const user = props?.data.user;
      ev.preventDefault();
      const resultPromoCode = await validPromocode();
      if (resultPromoCode.valid) {
        try {
          const data = {
            CNPJ_CPF__c: user?.CNPJ_CPF__c,
            CPF__c: user?.CPF__c,
            Content1: user?.Content1,
            Content2: user?.Content2,
            Department: user?.Department,
            Email: user?.Email.toLowerCase(),
            FirstName: user?.FirstName,
            LastName: user?.LastName,
            LoyaltyBirthdate__c: user?.LoyaltyBirthdate__c,
            LoyaltyCategory__c: user?.LoyaltyCategory__c,
            LoyaltyCity__c: user?.LoyaltyCity__c,
            LoyaltyPhone__c: user?.LoyaltyPhone__c,
            LoyaltyPostalCode__c: user?.LoyaltyPostalCode__c,
            LoyaltyState__c: user?.LoyaltyState__c,
            loyaltyStreet: user?.loyaltyStreet
              ? user?.loyaltyStreet
              : user?.LoyaltyStreet__c
              ? user?.LoyaltyStreet__c
              : '',
            PersonDepartment: user?.PersonDepartment,
            PrivacyTerms: user?.PrivacyTerms,
            TermsConfirmation: user?.TermsConfirmation,
            Username: user?.Username,
            acg_AddressComplement__c: user?.acg_AddressComplement__c,
            numberApartament: user?.numberApartament,
            promotionalCode,
          };

          const response = await props.userHelper.saveUser(data);

          setLoading(false);
          props.popup.setIsOpen(false);
          if (response.success) {
            return setShowModalLogin({
              isVisible: true,
              title: 'Voucher',
              message: 'Voucher adicionado com sucesso !',
              type: 'success',
            });
            // props.message.add({ type:'success', message: 'Voucher adicionado com sucesso !' });
          }
        } catch (err) {
          props.popup.setIsOpen(false);
          setLoading(false);
          return setShowModalLogin({
            isVisible: true,
            title: 'Voucher',
            message: 'Ocorreu um erro inesperado.',
            type: 'error',
          });
        }
      }
      props.popup.setIsOpen(false);
      setLoading(false);
      // return props.message.add({ type:'error', message: 'Voucher indisponível ou já utilizado!' });
      return setShowModalLogin({
        isVisible: true,
        title: 'Voucher',
        message: 'Voucher indisponível ou já utilizado!',
        type: 'error',
      });
    };

    return (
      <PopupContainer>
        <div className='titleContainer'>
          {/* <RiCoupon3Fill color='#333' size={30} style={{ marginRight: 10 }} /> */}
          <img
            src={promoCodeIcon}
            style={{ marginRight: 10 }}
            alt='icon promocode'
          />
          <h3> Insira um código promocional </h3>
        </div>
        <form onSubmit={(ev) => handlePromotionalCode(ev)}>
          <Input
            inputReference={inputRef}
            label='Código Promocional'
            type='text'
            onChange={(ev) => setPromotionalCode(ev.target.value)}
          />
          <div className='containerButton'>
            <Button disabled={loading} loading={loading}>
              <span> Enviar </span>
            </Button>
          </div>
        </form>
      </PopupContainer>
    );
  };

  const openPopup = () => {
    props.popup.setContent(<PopupPromotional />);
    props.popup.setIsOpen(true);
  };

  const getPushNotification = async () => {
    if (data.user.FirebaseId__c) {
      const pushNotificationQuery = query(
        collection(
          userHelper.db,
          'Users',
          data.user.FirebaseId__c,
          'PushNotification'
        ),
        orderBy('CreatedDate', 'desc')
      );
      const pushNotificationDoc = await getDocs(pushNotificationQuery);

      const res = [];
      pushNotificationDoc.docs.map(async (docData, index) => {
        const dataDoc = docData.data();
        if (docData.exists) {
          const idDocument = dataDoc?.Id?.slice(0, -3);
          const docRef = doc(
            userHelper.db,
            'Users',
            data.user.FirebaseId__c,
            'PushNotification',
            idDocument
          );
          if (!dataDoc.Read__c) {
            // message.add({
            //   title : dataDoc?.Title__c,
            //   body: dataDoc?.Description__c
            // });
            // setShowpopup(false)
            setShowModalLogin({
              isVisible: true,
              title: dataDoc?.Title__c,
              message: dataDoc?.Description__c,
              type: 'success',
            });
            setDoc(docRef, { Read__c: true }, { merge: true });
          }
        }
        res.push(dataDoc);
        return dataDoc;
      });
      setHistoryListNotification(res);
    }
  };

  useEffect(() => {
    getPushNotification();
  }, [props]);

  return (
    <StyledHeaderUserV2 ref={thisRef} config={config} isOpen={isOpen}>
      <div className={'header-user-block'}>
        {isAuth && (
          <img
            src={promoCodeIcon}
            onClick={() => openPopup()}
            style={{ marginRight: 10 }}
            alt='icon promocode'
          />
        )}
        {isAuth && (
          <img
            onClick={() => isAuth && handleOpenDetails()}
            className='header-user-icon'
            src={bellIcon}
            alt='Bell Icon'
          />
        )}
        <div className={'header-user-icon'}>
          <Avatar data={data} config={config} userHelper={userHelper} />
        </div>
        <div
          className={'header-user-label-group'}
          onClick={() => isAuth && props.toggleIsOpen()}>
          <div
            className={'header-user-name'}
            style={{ fontWeight: isAuth ? null : 'bold' }}>
            {isAuth ? data?.user?.FirstName?.substring(0, 15) : 'Login'}
          </div>
          {/*
          <div className={'header-user-points'}>
            <Point config={config} color={'#333'} qty={data?.user?.CurrentContactSeason__r?.TotalAvailablePoints__c} decimal={0} small />
          </div>
          */}
        </div>
        <div
          className={'header-user-arrow-container'}
          onClick={() => isAuth && props.toggleIsOpen()}>
          {/* <ChevronDown height={15} width={15} strokeWidth={3} color={props.config.themeLayout?.buttonText}/> */}
          <SlArrowDown size={20} color='#363636' />
        </div>
      </div>
      <HeaderDropMenu
        parentRef={thisRef}
        menuItems={headerConfig?.headerUser?.menuItems}
        toggleIsOpen={toggleIsOpen}
        isOpen={isOpen}
        config={config}
        userHelper={userHelper}
        notification={notification}
      />
    </StyledHeaderUserV2>
  );
}

export default HeaderUserV2;

const PopupContainer = styled.section`
  width: 450px;
  /* height: 100px; */
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #feac0e;
  /* box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2); */
  .titleContainer {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    h3 {
      color: #363636;
      /* margin: 0 0 10px 0; */
      /* text-align: center; */
    }
  }

  .containerButton {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    /* display: none; */
    width: 350px;
  }
`;

const HistoryNotificationContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: red; */
  margin-top: 60px;
  padding: 10px;
  overflow: auto;

  h2 {
    color: #363636;
  }

  .emptyNotification {
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    margin-top: 10px;
  }
`;
const HistoryNotificationItem = styled.section`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #333;
  width: 100%;
  color: #363636;

  display: flex;
  flex-direction: column;

  margin-bottom: 10px;

  .titlePushNotification {
    /* text-align: center; */
    font-weight: 700;
    font-size: 1.2rem;
  }

  .DescriptionPushNotification {
    margin: 5px 0;
  }

  .DatePushNotification {
    align-self: flex-end;
    font-size: 0.9rem;
  }
`;
const StyledHeaderUserV2 = styled.div`
  /* position: relative;  */
  height: 100%;
  // padding-left: 10px;
  color: ${(props) => props.config?.themeLayout?.link};
  cursor: pointer;
  user-select: none;
  // background: ${(props) => (props.isOpen ? 'rgba(0,0,0,0.1)' : 'none')};
  // padding-right: 20px;

  @media (min-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    position: relative;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    /* display: none; */
  }

  .header-user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    height: 100%;

    .header-user-icon {
      width: 28px;
      height: 28px;
      margin-right: 5px;
    }

    .header-user-label-group {
      flex: 1;
      margin-left: 10px;
      margin-right: 10px;
      text-align: right;

      .header-user-name {
        font-size: 0.9em;
        color: #363636;
        // font-weight: bold;
        font-family: Lato, sans-serif;
      }

      .header-user-points {
        font-weight: bold;
      }
    }

    .header-user-arrow-container {
      // background: ${(props) => props.config.themeLayout?.buttonBackground};
      // width: 25px;
      // height: 25px;
      // border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-left: -5px;
      z-index: 1;
    }
  }

  .header-grafismo-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;

    @media (min-width: ${(props) =>
        props.config?.layout?.CONTAINER_SIZE + 20}px) {
      overflow: initial;
    }

    @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
      /* display: none; */
    }

    .grafismo {
      width: 150px;
    }
  }
`;
