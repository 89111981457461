import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import StyledFormInput from './StyledFormInput';

const TextArea = (props) => {
  useEffect(() => {
    if (props.input?.startValue)
      props.input.ref.current.value = props.input.startValue;
  }, [props.input.startValue]);

  const onChange = () => {
    if (props.input.onChange) {
      props.input.onChange(props.input.ref.current.value);
    }
  };

  return (
    <StyledTextArea config={props.config}>
      <StyledFormInput
        config={props.config}
        className={`input__group input__textarea ${props.layout} ${props.input?.type}`}>
        <textarea
          ref={props.input?.ref}
          onInput={onChange}
          type={props.input?.type}
          {...props.input.inputOptions}
          required
        />
        <label>
          <div className={'label-bg'} />
          <span className={'label-show'}>{props.input?.label || 'Input'}</span>
          <span className={'label-placeholder'}>
            {props.input?.label || 'Input'}
          </span>
        </label>
        <p className='error'>{props.input?.error}</p>
        {props.input?.append && (
          <div className='append'>{props.input?.append}</div>
        )}
      </StyledFormInput>
    </StyledTextArea>
  );
};

export default TextArea;

/* style */

const StyledTextArea = styled.div`
  .input__textarea {
    textarea {
    }
  }
`;
