import { Suspense } from 'react';
import { Switch } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import ToolHelper from '../utils/ToolHelper';
import Cadastro from '../../screens/Login/SignUp'
import SignUpBox from '../../components/Login/SignUpBox'; 

const DeclareRoutes = (props) => {
  return (
    <Switch>
      <Suspense>
        {props.routes.map(({ component: Component, componentByType, path, exact, access = 'public', meta }) => {

          if(componentByType){
            Component = ToolHelper.getOptionByUserType(componentByType)
          }
          let config = {
            path,
            key: path,
            exact,
            routesO: props.routesO,
          };

          switch(access){
            case 'unlogged':
              return <PublicRoute {...config} onlyUnlogged>
                <Component {...props.screenProps}/>
              </PublicRoute>

            case 'logged':
              return <PrivateRoute {...config}>
                <Component {...props.screenProps}/>
              </PrivateRoute>

            case 'public':
            default:
              return <PublicRoute {...config}>
                <Component {...props.screenProps}/>
              </PublicRoute>
          }
        }
      )}
      </Suspense>
    </Switch>
)
};

export default DeclareRoutes;
