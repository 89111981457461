import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { BREAKPOINTS } from '../configs/consts.js';

const FormBox = (props) => {
  const { config, data } = props;
 
  return (
    <StyledFormBox noBorder={props?.noBorder} noShadow={props?.noShadow}  className='ctn-styled-formbox' config={config} logo={data?.logo}>
      {/* <div className={'logo-container'}>
        <Link to="/">
          <div className={'logo'} />
        </Link>
      </div> */}
      {props.title && props.title.length && <p className={'box-title'} dangerouslySetInnerHTML={{__html:props.title}} />}
      {props.description && props.description.length && <p className={'box-description'} dangerouslySetInnerHTML={{__html:props.description}} />}
      {props.inside}
    </StyledFormBox>
  )
}

export default FormBox;

/* style */

const StyledFormBox = styled.div`
  padding: 2em 55px;
  /* padding-top: 1em; */
  width: 700px;
  max-width: 100%;
  z-index: 5;
  /* margin-top: 40px; */
  /* margin-bottom: 40px; */
  overflow-y: auto;
  /* max-height: calc(100vh - 100px); */
  background-color:white;
  box-shadow: ${props => props.noShadow ? '0' : '6px 0px 20px 1px #3636364d'} ;
  border-radius: 10px;
  border: ${props => props.noBorder ? 'none' : '1px solid #0897E9'} ;
  

  .logo-container{
    display: flex;
    justify-content: start;

    .logo{
      display: inline-block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: clamp(100px,calc(15vw),150px);
      height: clamp(60px,calc(7vw),80px);
      background-image: url(${props => props.logo});
    }
  }

  .box-title {
    margin-top: 15px;
    font-size: 2em;
    font-weight: bold;
    color: ${props => props.config?.themeLayout?.title};
  }

  .box-description {
    margin-top: 5px;
    margin-bottom: 15px;
    color: ${props => props.config?.themeLayout?.text};
  }

  @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    width: 500px;
    padding: 2em 1.5em;
    /* width: 100%; */
  }
  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    /* padding: 2em 1.5em; */
    width: 100%;
  }
`