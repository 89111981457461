const style = (props) => {
  return {
    fullContainer: {
      // margin: '20px 0',
      // padding: 15,
      // minHeight: '100vh',
      // display:'flex',
    }
  }
}

export default style;