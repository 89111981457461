import React, { useEffect } from 'react';
import StyledFormInput from './StyledFormInput';

const FormInput = (props) => {
  useEffect(() => {
    if (props.input?.startValue) {
      props.input.ref.current.value = props.input.startValue;
      onChange();
    }
  }, [props.input.startValue]);

  const maskDate = (value) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1');

  const maskPhone = (value) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d{4})(\d)*/, '$1-$2$3')
      .replace(/(\d{4})-(\d{1})(\d{4})(\d)*/, '$1$2-$3');

  const maskLandline = (value) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d{4})\d*/, '$1-$2')
      .replace(/(\d{4})-(\d{1})(\d{4})(\d)*/, '$1$2-$3');

  const maskCPF = (value) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

  const maskCNPJ = (value) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

  const maskCEP = (value) =>
    value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');

  const maskLetters = (value) => value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');

  const maskUsername = (value) => value.replace(/[^a-zA-Z0-9@._-]+/g, '');

  const maskNumbers = (value) => value.replace(/\D/g, '').slice(0, 10);

  const maskUseroptions = (value) => {
    let maskOptions = props.input?.maskOptions || [];

    const removeDotsAndDashes = (str) => str.replace(/[\.\-\/]/g, '');

    const newValue = removeDotsAndDashes(value);
    // Se só tem número e \ e . e -
    if (/^[0-9\.\-\/]+$/g.test(newValue)) {
      // Neste caso pode ser CNPJ
      if (
        maskOptions.includes('cnpj') &&
        // Se não tem cpf nem text, é sempre cnpj
        ((!maskOptions.includes('cpf') && !maskOptions.includes('text')) ||
          // Se também tem text e não tem cpf, espera pelo menos ter 2 caracteres
          (!maskOptions.includes('cpf') &&
            maskOptions.includes('text') &&
            newValue.length >= 2) ||
          // Se também tem cpf, só é cnpj depois que passa da qtd de caracteres de cpf (15)
          (maskOptions.includes('cpf') && newValue.length >= 12))
      ) {
        return maskCNPJ(newValue);
      }
      // Ou CPF
      else if (maskOptions.includes('cpf') && newValue.length === 11) {
        return maskCPF(newValue);
      }
    }

    return maskUsername(value);
  };

  const onChange = () => {
    if (props.input?.mask) {
      let value = props.input.ref.current.value;
      let maskF;
      switch (props.input.mask) {
        case 'phone':
          maskF = maskPhone;
          break;
        case 'landline':
          maskF = maskLandline;
          break;
        case 'date':
          maskF = maskDate;
          break;
        case 'cpf':
          maskF = maskCPF;
          break;
        case 'cep':
          maskF = maskCEP;
          break;
        case 'letter':
          maskF = maskLetters;
          break;
        case 'number':
          maskF = maskNumbers;
          break;
        case 'username':
          maskF = maskUseroptions;
          break;
        default:
          maskF = null;
      }

      if (maskF) {
        props.input.ref.current.value = maskF(value);
      }
    }

    if (props.input.onChange) {
      props.input.onChange(
        props.input.ref.current.value,
        props.input.ref.current
      );
    }
  };

  return (
    <StyledFormInput
      config={props.config}
      className={`input__group ${props.layout} ${props.input?.type}-input`}>
      {!props.input?.isTextArea ? (
        <input
          ref={props.input?.ref}
          onInput={onChange}
          type={props.input?.type}
          {...props.input.inputOptions}
          required
        />
      ) : (
        <textarea
          ref={props.input?.ref}
          rows={2}
          onInput={onChange}
          type={props.input?.type}
          {...props.input.inputOptions}
          required
        />
      )}
      <label>
        <div className={'label-bg'} />
        <span className={'label-show'}>{props.input?.label || 'Input'}</span>
        <span className={'label-placeholder'}>
          {props.input?.label || 'Input'}
        </span>
      </label>
      <p className='error'>{props.input?.error}</p>
      {props.input?.append && (
        <div className='append'>{props.input?.append}</div>
      )}
    </StyledFormInput>
  );
};

export default FormInput;
