import React from 'react';
import { Link } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";
import styled from '@emotion/styled';
import { BREAKPOINTS } from './configs/consts';


function HeaderBack(props) {
  // const isAuth = global?.isAuthenticated;

    return (
      <HeaderBackContainer>
        <Link to="/" >
          <SlArrowLeft size={16} /> VOLTAR PARA A HOME
        </Link>
      </HeaderBackContainer>
    );
}

const HeaderBackContainer = styled.div`
  width: 100%;
  padding: 10px 55px 20px 30px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: flex-end;
    color: #363636;
    font-size: 1.25rem;
    font-weight: 700;
    /* z-index: ${props => !props?.isAuth && 9999 }; */
  }
  @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    padding-left: 20px;
    /* padding-right: 30px; */
  }
`

export default HeaderBack;
