import styled from '@emotion/styled'

const Button = (props) => {
  const { isLoading, display } = props;
  let isDisabled = props.className?.includes('disabled');
  const buttonType = props.href?.length || props.onClick ? 'a' : 'div';
  const background = props.bgTransparente ? 'trasparent' : props.config?.themeLayout?.buttonBackground;
  let borderColor = props.bgTransparente ? props.config?.themeLayout?.buttonBackground : 'trasparent';
  const color = props.bgTransparente ? props.config?.themeLayout?.buttonBackground : props.config?.themeLayout?.buttonText;

  borderColor = props.borderColor !== null ? props.borderColor : borderColor;

  if (props.notBorder) borderColor = 'transparent';

  const StyledButton = styled[buttonType]`
    display: ${props => props.display || 'flex'};
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: ${background};
    color: ${color};
    cursor: pointer;
    user-select: none;
    padding: ${props => props.small ? '15px 20px' : '15px 20px'};
    font-weight: bold;
    border-radius: ${props => props.config?.layout?.BUTTON_RADIUS};
    text-align: center;
    font-size: 0.9em;
    border-width: 1px;
    border-style: solid;
    border-color: ${borderColor};
    width: 100%;
    height: 50px;

    &.secondary{
      background: ${background};
      color: ${color};
    }

    &.disabled{
      background: ${props => props.config?.themeLayout?.buttonInactiveBackground};
      color: ${props => props.config?.themeLayout?.buttonInactiveText};
      cursor: not-allowed;
    }

    .loading{
      margin-left: 0.5em;
    }

    .loading-bar{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      background: repeating-linear-gradient(to right, ${props => props.config?.themeLayout?.title} 0%, ${props => props.config?.themeLayout?.link} 50%, ${props => props.config?.themeLayout?.title} 100%);
      background-size: 200% auto;
      background-position: 0 100%;
      animation: gradient 1s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: linear;

      @keyframes gradient { 
        0%   { background-position: 0 0; }
        100% { background-position: -200% 0; }
      }
    }
  `;

  return <StyledButton
    display={display}
    buttonType={buttonType}
    href={!isDisabled ? props.href : undefined}
    onClick={!isDisabled ? props.onClick : undefined}
    config={props.config}
    className={(props.className || '') + (isLoading ? ' disabled' : '')}
    small={props.small}
    target={props.target}
  >
    {props.children}
    {isLoading ? <span className={'loading-bar'} /> : ''}
  </StyledButton>
}

/* style */



export default Button;