import React from 'react';

import circleBenefitIcon from './../assets/circle-benefit-icon.png';
import circleBlack from './../assets/circle-black.png';
import circleMissaoIcon from './../assets/circle-missao-icon.png';
import circleTrilhaIcon from './../assets/circle-trilhas-icon.png';
import iconProduct from './../assets/icon-product.png';

import headerMaskGroupAcumularbits from './../assets/header_mask_group_acumularbits.png';
import headerMaskGroupBeneficios from './../assets/header_mask_group_beneficios.png';
import headerMaskGroupBlack from './../assets/header_mask_group_black.png';
import headerMaskGroupMissoes from './../assets/header_mask_group_missoes.png';
import headerMaskGroupProdutos from './../assets/header_mask_group_produtos.png';
import headerMaskGroupTrilhas from './../assets/header_mask_group_trilhas.png';

import styled from '@emotion/styled';
import { BREAKPOINTS } from '../components/configs/consts';

const fontFamily = 'Lato,sans-serif';

const CONFIGURACOES_HEADER = [
  {
    hasIcon: true,
    circle: circleBlack,
    icon: circleBenefitIcon,
    title: 'RESGATAR BENEFÍCIOS',
    iconLeft: headerMaskGroupBeneficios,
    bg: '#FEAC0E',
    color: '#363636',
  },
  {
    hasIcon: true,
    circle: circleBlack,
    icon: iconProduct,
    title: 'PRODUTOS TOTVS',
    iconLeft: headerMaskGroupProdutos,
    bg: '#0897E9',
    color: '#363636',
  },

  {
    hasIcon: false,
    title: 'ACUMULAR BITS TOTVS',
    iconLeft: headerMaskGroupAcumularbits,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: true,
    circle: circleBlack,
    icon: circleMissaoIcon,
    title: 'MISSÕES',
    iconLeft: headerMaskGroupMissoes,
    bg: '#0ADBE3',
    color: '#363636',
  },
  {
    hasIcon: true,
    circle: circleBlack,
    icon: circleTrilhaIcon,
    title: 'TRILHAS',
    iconLeft: headerMaskGroupTrilhas,
    bg: '#6B48FF',
    color: '#FFF',
  },
  {
    hasIcon: false,
    title: 'DÚVIDAS',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'MINHAS INFORMAÇÕES',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'MEU PERFIL',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'MEU HISTÓRICO',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'CONTEÚDOS',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'MEU PERFIL',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'FALE CONOSCO',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'TERMOS DE USO',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'UPLOAD DOCUMENTOS',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: false,
    title: 'UPLOAD DOCUMENTOS',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
  {
    hasIcon: true,
    circle: circleBlack,
    icon: iconProduct,
    title: 'PARCEIROS BITS',
    iconLeft: headerMaskGroupBlack,
    bg: '#D9D9D9',
    color: '#363636',
  },
  {
    hasIcon: false,
    title: 'BENEFÍCIO PARCEIRO BITS',
    iconLeft: headerMaskGroupBlack,
    bg: '#D9D9D9',
    color: '#363636',
  },
  {
    hasIcon: false,
    title: 'DOWNLOADS',
    iconLeft: headerMaskGroupBlack,
    bg: '#363636',
    color: 'white',
  },
];

const ContainerStyle = styled.div`
  width: 100%;
  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    .ctn-subheader {
      min-height: 130px;
    }
    .subheader-icon {
      display: none !important;
    }
  }
`;

function SubHeader(props) {
  let index = 0;
  let backgroundColor = '#363636';
  let RIGHT = 72;

  let path = window.location.pathname.split('/')[1];

  if (path === 'resgatar-beneficios') {
    index = 0;
    backgroundColor = '#FEAC0E';
    RIGHT = 83;
  }
  if (path === 'premios') {
    index = 0;
    RIGHT = 82;
  }
  if (path === 'produtos') {
    index = 1;
    backgroundColor = '#0897E9';
  }
  if (path === 'acumular-bits') {
    index = 2;
  }
  if (path.includes('missoes') || path === 'pesquisa' || path === 'quiz') {
    index = 3;
    backgroundColor = '#0ADBE3';
    RIGHT = 67;
  }
  if (path === 'meus-resultados') {
    index = 6;
  }
  if (path === 'extrato') {
    index = 8;
  }
  if (path === 'trilhas') {
    index = 4;
    backgroundColor = '#6B48FF';
  }
  if (path === 'distribuidores') {
    index = 5;
  }
  if (path === 'duvidas') {
    index = 5;
  }
  if (path === 'noticias') {
    index = 9;
  }
  if (path === 'meu-cadastro') {
    index = 10;
  }
  // if (path === 'resgatar-resgatar') {
  //   index = 0;
  // }
  if (path === 'fale-conosco') {
    index = 11;
  }
  if (path === 'termos-de-uso') {
    index = 12;
  }
  if (path === 'upload-documents') {
    index = 13;
  }
  // ITEM QUE NAO EXISTE HEADER
  if (path === 'login') {
    index = 14;
  }

  if (path === 'parceiros') {
    index = 15;
  }
  if (path === 'parceiro') {
    index = 16;
  }
  if (path === 'download') {
    index = 17;
  }

  return (
    <ContainerStyle>
      <div
        className='subheader'
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: CONFIGURACOES_HEADER[index]?.bg,
          backgroundImage: `url("${CONFIGURACOES_HEADER[index]?.iconLeft}")`,
          backgroundPosition: 'center right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '100%',
          paddingLeft: 20,
          paddingRight: 45,
        }}>
        <div
          className='ctn-subheader'
          style={{
            display: 'flex',
            width: '100%',
            fontFamily: fontFamily,
            fontWeight: 900,
          }}>
          {CONFIGURACOES_HEADER[index]?.hasIcon ? (
            <div
              className='subheader-icon'
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 30,
                paddingTop: 20,
                paddingBottom: 20,
                width: 150,
              }}>
              <img
                src={CONFIGURACOES_HEADER[index]?.circle}
                alt='circle blue'
                style={{}}
              />
              <img
                src={CONFIGURACOES_HEADER[index]?.icon}
                alt='silhueta'
                style={{ zIndex: 2, position: 'relative', right: RIGHT }}
              />
            </div>
          ) : null}
          <div
            className='subheader-title'
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              fontFamily: fontFamily,
              fontSize: 40,
              fontWeight: 'bolder',
              paddingLeft: CONFIGURACOES_HEADER[index]?.hasIcon ? 15 : 35,
              paddingTop: 20,
              paddingBottom: 20,
              color: CONFIGURACOES_HEADER[index]?.color,
            }}>
            {CONFIGURACOES_HEADER[index]?.title}
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
}

export default SubHeader;
