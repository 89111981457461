import { createContext, useState } from 'react'

export const ShowModalLoginContext = createContext({})

export const ShowModalLoginProvider = ({ children }) => {
  const [showModalLogin, setShowModalLogin] = useState({
    isVisible: false,
    title: '',
    message: 'Teste',
    type: 'info'
  });

  return (
    <ShowModalLoginContext.Provider
      value={{
        showModalLogin,
        setShowModalLogin,
      }}
    >
      {children}
    </ShowModalLoginContext.Provider>
  )
}