import { Route, Redirect } from 'react-router-dom';

function PublicRoute({ children, onlyUnlogged, ...rest }) {
  return (
    <Route
      {...rest}
      render={
        ({ location }) => (
          onlyUnlogged && global.isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location }
              }}
            />
          ) : (
            children
          ))
      }
    />
  );
}

export default PublicRoute;
