import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import StyledInput from './StyledFormInput';

const FormSelect = (props) => {
  const [ isEmptyDisabled, setIsEmptyDisabled ] = useState(false)
  
  useEffect(() => {
    setIsEmptyDisabled(
      !props.input.validations?.filter(validation => validation.type === 'required').length
    )
  },[])

  useEffect(() => {
    setTimeout(() => {
      if(props.input?.startValue && props.input.ref.current){
        props.input.ref.current.value = props.input.startValue;
        onChange();
      }
    },500)

  },[props.input.startValue])

  const onChange = () => {
    if(props.input.onChange){
      props.input.onChange(props.input.ref.current.value);
    }
  }

  return <StyledFormSelect config={props.config}>
    <StyledInput config={props.config} className={`input__group ${props.layout} ${props.input?.type}-input`}>
      <span className={'select-arrow'}><i className={'fa fa-angle-down'} /></span>
      <select ref={props.input?.ref} onInput={onChange} {...props.input.inputOptions} required>
        <option key={"__empty"} value={""} disabled={isEmptyDisabled}></option>
        {props.input?.options?.map((option) => {
          return <option key={option.value} value={option.value}>{option.label}</option>
        })}
      </select>
      <label>
        <div className={'label-bg'}/>
        <span className={'label-show'}>{props.input?.label || 'Input'}</span>
        <span className={'label-placeholder'}>{props.input?.label || 'Input'}</span>
      </label>
      <p className="error">{props.input?.error}</p>
      {props.input?.append && <div className="append">{props.input?.append}</div>}
    </StyledInput>
  </StyledFormSelect>
}

export default FormSelect;

/* style */

const StyledFormSelect = styled.div`
  .select-input{
    .select-arrow{
      position: absolute;
      z-index: 2;
      right: 0.5em;
      top: 0.4em;
      /* background: ${props => props.config?.themeLayout?.buttonSecondaryBackground}; */
      width: 1.5em;
      height: 1.5em;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      
      i{
        font-weight: bold;
        color: ${props => props.config?.themeLayout?.buttonSecondaryText};
      }
    }

    select{
      position: relative;
      appearance: none;
      cursor: pointer;
    }

    label{
      top: 0.6em;
    }
  }
`