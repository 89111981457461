import React, { useEffect, useMemo, useState } from 'react';

import ToolHelper from '../utils/ToolHelper';
import FormHelper from '../utils/FormHelper';
import Form from '../Molecules/Form';
import StyledLink from '../Molecules/StyledLink';
import FormBox from '../Molecules/FormBox';
import SignUpFull from '../../screens/Login/SignUpFull';
import ForgetPasswordBox from './ForgotPasswordBox';
import { HEROKU_AUTHORIZATION, HEROKU_VALIDATE_USER_URL, HEROKU_EMAIL_VALIDATE_CODE_URL } from '../utils/URLconsts';
import { Button as FunctionButton } from '../../components/Button/index.tsx';
import { BREAKPOINTS } from '../configs/consts';
import styled from '@emotion/styled';
import { useEnanbleRegister } from '../../hooks/useEnableRegister.js';

const LoginBox = (props) => {
  const { enableRegister } = useEnanbleRegister();
  const { dataHelper, history, message } = props;
  const [loading, setLoading] = useState(0);
  const [userData, setUserData] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const [emailValidateCode, setEmailValidateCode] = useState(['', '', '', '', '', '']);
  const [errorEmailValidateCode, setErrorEmailValidateCode] = useState('');

  const [showLoginBox, setShowLoginBox] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorRevalidationAccess, setShowErrorRevalidationAccess] = useState(false);
  const [showAlertRedefinitionAccess, setShowAlertRedefinitionAccess] = useState(false);
  const [showEmailValidateCode, setShowEmailValidateCode] = useState(false);
  const [showRedefinePassword, setShowRedefinePassword] = useState(false);
  const [showConfirmationRevalidationSuccess, setShowConfirmationRevalidationSuccess] = useState(false);

  const config = useMemo(() => ToolHelper.getThemedConfig(props, 'LoginBox.preset'), [props.config]);

  useEffect(() => {
    if (showPassword) {
      inputs.Username.setInputOptions({
        disabled: 'disabled',
        spellCheck: false,
      })
      inputs.Password.setType('password');
      setTimeout(() => {
        inputs.Password.setFocus();
      }, 50)
    }
    else {
      inputs.Username.setInputOptions({
        spellCheck: false,
      })
      inputs.Password.setType('hidden');
    }
  }, [showPassword])

  const onChangeEmailValidateCode = (event, index) => {
    const value = event.target.value;

    if (/^[0-9]*$/.test(value)) {
      const newCode = [...emailValidateCode];
      newCode[index] = value;

      if (index < 5 && value !== '') {
        document.getElementById(`code-input-${index + 1}`).focus();
      }

      setEmailValidateCode(newCode);
    }
  };

  const showResetPassword = () => {
    props.popup.setIsOpen(false);
    props.popup.setContent(
      // <SignUpFull
      //   {...props}
      //   data={props.data}
      //   routes={props.routes}
      //   showHeader={props.showHeader}
      //   userHelper={dataHelper.userHelper}
      // />
      <ForgetPasswordBox
        {...props}
        // data={props.data}
        // routes={props.routes}
        // showHeader={props.showHeader}
        // userHelper={dataHelper.userHelper}
        username={inputs.Username.getValue()}
      />
    );
    props.popup.setIsOpen(true);
  }

  const inputs = Loginform({ routes: props.routes, config, showPassword, showResetPassword });

  const showPopupSignUpFull = () => {
    props.popup.setIsOpen(false);
    props.popup.setContent(
      <SignUpFull
        {...props}
        data={props.data}
        routes={props.routes}
        showHeader={props.showHeader}
        userHelper={dataHelper.userHelper}
      />
    );
    props.popup.setIsOpen(true);
  }

  const handleLogin = async (formData) => {
    const username = formData['Username'];

    if (!showPassword) {
      let result = await props.userHelper.checkUserExists({ username }, 'login');

      if (result?.success) {
        switch (result.code) {
          case 'user-exists':
            setShowPassword(true);
            break;
          case 'user-needs-password-definition':
            // history.push(routes.accessCode.path,{UserFirebaseId:result.UserFirebaseId});
            setShowPassword(true);
            return;
          case 'user-does-not-exist':
            message.add({ type: 'error', message: config.strings.form.message.USER_NOT_FOUND, persist: true });
            // history.push(routes.signUpFull.path,{username});

            props.popup.setIsOpen(false);

            props.popup.setContent(
              <SignUpFull
                {...props}
                data={props.data}
                routes={props.routes}
                showHeader={props.showHeader}
                userHelper={dataHelper.userHelper}
              />
            );

            props.popup.setIsOpen(true);

            return;
          default:
            message.add({ type: 'error', message: config.strings.form.message.GENERIC_ERROR });
        }
      }
    }
    else {
      const password = formData['Password'];
      let response = await props.userHelper.userLogin(username, password);

      if (response.userData) {
        setUserData(response.userData);
      }
      
      if (response?.success) {
        if (response.hasUserDoc) {
          let userData = await props.userHelper.getUser();
          global.isAuthenticated = true;

          if(userData.Account.Active__c == "No") {
            message.add({ type: 'error', message: 'Esta conta está desativada !' });
            global.isAuthenticated = false;
            return {
              success: false,
              redirect: '/logout',
            }
          } else {
            return {
              success: true,
              redirect: '/',
            }
          }
        }
        else {
          return {
            success: true,
            redirect: [props.routes.signUpFull.path, { username, password, UID: response.user.user.uid }],
          }
        }
      }

      else {
        if (response?.error?.code) {
          switch (response.error.code) {
            case 'auth/user-not-found':
              inputs.Username.focusError(config.strings.form.message.USER_NOT_FOUND);
              break;
            case 'auth/wrong-password':
              inputs.Password.focusError(config.strings.form.validation.INVALID_PASSWORD);
              break;
            case 'auth/user-disabled':
              inputs.Username.focusError(config.strings.form.message.USER_IS_DISABLED);
              break;
            case 'auth/password-expired':
              setCurrentPassword(password);
              setShowLoginBox(false);
              setShowAlertRedefinitionAccess(true);
              break;
            case 'auth/too-many-requests':
            default:
              message.add({ type: 'error', message: config.strings.form.message.USER_CANT_LOGIN });
          }
        }
        else if (response?.message) {
          message.add({ type: 'error', message: response.message });
        }
        else {
          message.add({ type: 'error', message: config.strings.form.message.GENERIC_ERROR });
        }
      }
    }
  }

  const validateUser = async () => {
    const dataRequest = {
      name: userData.FirstName,
      cpf: userData.CPF__c,
      cnpj: userData.CNPJ_CPF__c.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5'),
      email: userData.Email,
    }

    const valideUser = await props.userHelper.dataHelper.getHerokuUrl(HEROKU_VALIDATE_USER_URL);

    const request = await fetch(valideUser, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': HEROKU_AUTHORIZATION
      },
      body: JSON.stringify(dataRequest)
    });

    const result = await request.json();

    return result;
  }

  const varifyEmailValidateCode = async () => {
    const requestBody = {
      email: userData.Email,
      validateCode: emailValidateCode.join('')
    };

    const validateCode = await props.userHelper.dataHelper.getHerokuUrl(HEROKU_EMAIL_VALIDATE_CODE_URL);

    const request = await fetch(validateCode, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': HEROKU_AUTHORIZATION
      },
      body: JSON.stringify(requestBody)
    });

    const result = await request.json();

    return result;
  }

  const handleValidateUser = async () => {
    setLoading(true)
    const result = await validateUser();

    if (result.status !== 'Success') {
      setLoading(false)
      setShowAlertRedefinitionAccess(false);
      setShowErrorRevalidationAccess(true);
      return;
    }

    setLoading(false)
    setShowAlertRedefinitionAccess(false);
    setShowEmailValidateCode(true);
  }

  const handleEmailValidate = async () => {
    setLoading(true);
    setErrorEmailValidateCode('');

    const result = await varifyEmailValidateCode();

    if (result.status === 'error') {
      setLoading(false);
      setEmailValidateCode(['', '', '', '', '', '']);
      setErrorEmailValidateCode('Código inválido! Tente novamente.');
      return
    }

    setLoading(false);
    setShowEmailValidateCode(false);
    setShowRedefinePassword(true);
  }

  const handleRedefinePassword = async (formData) => {
    setLoading(true);
    const NewPassword = formData['NewPassword'];

    const responseLogin = await props.userHelper.loginRedefinePassword(userData.Email, currentPassword);

    if (responseLogin.success) {
      global.isAuthenticated = true;

      const responseChangePassword = await props.userHelper.changePassword({ NewPassword });

      if (responseChangePassword.success) {
        setShowRedefinePassword(false);
        setShowConfirmationRevalidationSuccess(true);
      }

      setLoading(false);
    }
    setLoading(false);
  }

  const modalLogin = () => {
    return (
      <FormBox
        {...props}
        config={config}
        title={config.strings.screen.login.TITLE}
        inside={
          <>
            <Form
              popup={props.popup}
              history={history}
              dataHelper={dataHelper}
              config={config}
              inputs={inputs}
              handleSend={handleLogin}
              loading={loading}
              setLoading={setLoading}
              asideSend={showPassword ?
                // <StyledLink to={props.routes.login.path} onClick={() => setShowPassword(false)} type={'aside'} config={config}>{config.strings.BACK}</StyledLink>
                <div />
                :
                <span style={{ color: '#777' }}>
                  {config.strings.screen.login.BEFORE_SIGN_UP_LINK}
                  <div />
                  {/* <StyledLink to={props.routes.signUp.path} type={'aside'} config={config}>{config.strings.screen.login.SIGN_UP_LINK}</StyledLink> */}
                </span>
              }
              sendLabel={config.strings.form.label.NEXT}
            />
            {
              enableRegister === "true" && (
                <p style={{ marginTop: '1em', color: '#777' }}>Não é cadastrado?
                  <StyledLink onClick={() => showPopupSignUpFull()} to={'#'} config={config}> Cadastre-se</StyledLink>
                </p>
              )
            }
            <p style={{ marginTop: '1em', color: '#777' }}>Fale conosco?
              <StyledLink
                onClick={() => props.popup.setIsOpen(false)}
                to={{ pathname: '/fale-conosco' }}
                config={config}> Envie perguntas
              </StyledLink>
            </p>
            <p style={{ marginTop: '1em', color: '#777' }}>Dúvidas?
              <StyledLink
                onClick={() => props.popup.setIsOpen(false)}
                to={{ pathname: '/duvidas' }}
                config={config}> Consulte as perguntas frequentes
              </StyledLink>
            </p>
            {/* <SocialLogin {...props} config={config} /> */}
          </>
        }
      />
    )
  }

  const modalErrorRevalidationAccess = () => {
    return (
      <>
        <h1>Erro ao redefinir acesso!</h1>
        <span
          className='text'
          style={{
            textAlign: 'center',
            marginBottom: '1.7em',
            fontSize: '1.2em',
            fontWeight: 'bold',
          }}
        >
          Houve um erro ao redefinir seu acesso, tente novamente mais tarde.
        </span>
        <div>
          <FunctionButton
            loading={loading}
            onClick={() => { window.location.reload(true) }}
          >
            <span>Fechar</span>
          </FunctionButton>
        </div>
      </>
    )
  }

  const modalAlertRedefinitionAccess = () => {
    return (
      <>
        <h1>Sua senha de acesso expirou!</h1>
        <span
          className='text'
          style={{
            textAlign: 'center',
            marginBottom: '1.7em',
            fontSize: '1.2em',
            fontWeight: 'bold',
          }}
        >
          Para sua segurança, é necessário realizar o processo de redefinição de acesso.
        </span>
        <div>
          <FunctionButton
            loading={loading}
            onClick={() => { handleValidateUser() }}
          >
            <span>Redefinir Acesso</span>
          </FunctionButton>
        </div>
      </>
    )
  }

  const modalEmailValidateCode = () => {
    return (
      <>
        <h1 style={{ textAlign: 'center' }}>
          Falta pouco para revalidar seu acesso!
        </h1>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0.3em',
            marginBottom: '0.8em',
          }}
        >
          Por favor, valide seu e-mail. Confira o código
          <br />
          {`que enviamos para ${userData?.Email} e digite abaixo:`}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1em',
            marginBottom: '0.3em',
          }}
        >
          {emailValidateCode.map((digit, index) => (
            <input
              key={index}
              type="text"
              id={`code-input-${index}`}
              value={digit}
              onChange={(e) => onChangeEmailValidateCode(e, index)}
              maxLength="1"
              style={{
                width: '40px',
                height: '55px',
                marginRight: '5px',
                textAlign: 'center',
                fontSize: '1.5em',
                borderRadius: '4px',
                border: '1px solid #999',
              }}
            />
          ))}
        </div>
        <p style={{
          color: 'red',
          textAlign: 'center',
          fontSize: '0.8em',
          marginBottom: '2em',
        }}>{errorEmailValidateCode}</p>
        <FunctionButton
          loading={loading}
          onClick={() => handleEmailValidate()}
        >
          <span>Enviar</span>
        </FunctionButton>
        <p style={{ marginTop: '1em', color: '#777' }}>Não recebeu o código?
          <StyledLink onClick={() => validateUser()} to={'#'} config={config}> Reenviar código</StyledLink>
        </p>
      </>
    )
  }

  const inputsRedefinePassword = Changepasswordform(props, currentPassword);

  const modalRedefinePassword = () => {
    return (
      <>
        <h1 style={{ textAlign: 'center' }}>
          Falta pouco para revalidar seu acesso!
        </h1>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0.3em',
            marginBottom: '0.8em',
          }}
        >
          Por favor, redefina sua senha de acesso preenchendo os campos abaixo:
        </p>
        <Form
          history={history}
          dataHelper={dataHelper}
          config={config}
          layout={'compressed'}
          inputs={inputsRedefinePassword}
          handleSend={handleRedefinePassword}
          loading={loading}
          setLoading={setLoading}
          asideSend={
            <StyledLink to={props.routes.home.path} type={'aside'} config={config}>{config.strings.BACK}</StyledLink>
          }
          sendLabel={config.strings.form.label.SEND}
        />
      </>
    )
  }

  const modalConfirmationRevalidationSuccess = () => {
    return (
      <>
        <h1>Seu acesso ao BITS foi redefinido com sucesso!</h1>
        <span
          className='text'
          style={{
            textAlign: 'center',
            marginBottom: '1.7em',
            fontSize: '1.2em',
            fontWeight: 'bold',
          }}
        >
          A partir de agora, você já pode acessar a plataforma e continuar aproveitando.
        </span>
        <div>
          <FunctionButton
            loading={loading}
            onClick={() => { window.location.reload(true) }}
          >
            <span>Acessar</span>
          </FunctionButton>
        </div>
      </>
    )
  }

  return (
    <>
      <div style={showLoginBox ? { display: 'block' } : { display: 'none' }}>{modalLogin()}</div>
      <ModalRedefinitionAccess showModal={showErrorRevalidationAccess}>{modalErrorRevalidationAccess()}</ModalRedefinitionAccess>
      <ModalRedefinitionAccess showModal={showAlertRedefinitionAccess}>{modalAlertRedefinitionAccess()}</ModalRedefinitionAccess>
      <ModalRedefinitionAccess showModal={showEmailValidateCode}>{modalEmailValidateCode()}</ModalRedefinitionAccess>
      <ModalRedefinitionAccess showModal={showRedefinePassword}>{modalRedefinePassword()}</ModalRedefinitionAccess>
      <ModalRedefinitionAccess showModal={showConfirmationRevalidationSuccess}>
        {modalConfirmationRevalidationSuccess()}
        <style>{`.css-zhqgif .close-button { display: ${showConfirmationRevalidationSuccess ? 'none' : 'flex'}}`}</style>
      </ModalRedefinitionAccess>
    </>
  )
}

export default LoginBox;

/* form */
const Loginform = (props) => {
  return {
    Username: FormHelper.useFormInput({
      label: props.config.strings.form.label.USERNAME,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_USERNAME,
        },
        {
          type: 'email',
          errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
        },
      ],
      inputOptions: {
        spellCheck: false,
      },
      mask: 'username',
      maskOptions: ['text', 'cpf', 'cnpj'],
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),
    Password: FormHelper.useFormInput({
      label: props.config.strings.form.label.PASSWORD,
      type: props.showPassword ? 'password' : 'hidden',
      validations: props.showPassword ? [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_PASSWORD,
        },
      ] : [],
      size: {
        desktop: 8,
        tablet: 8,
      },
      // append: <StyledLink to={'#'} onClick={(e) => props.goToForgotPassword(e)} type={'aside'} config={props.config} size={'s'}>{props.config.strings.screen.login.FORGOT_PASSWORD_LINK}</StyledLink>
      append: <StyledLink to={'#'} onClick={() => props.showResetPassword()} type={'aside'} config={props.config} size={'s'}>{props.config.strings.screen.login.FORGOT_PASSWORD_LINK}</StyledLink>

    })
  }
}

const Changepasswordform = (props, currentPassword) => {
  return {
    NewPassword: FormHelper.useFormInput({
      id: 'new-password',
      label: props.config.strings.form.label.NEW_PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.NEW_EMPTY_PASSWORD,
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.IDENTICAL_CURRENT_PASSWORD,
          customFunction: async (formInput, validation, aInputs) => {
            let isValid = true;

            aInputs.every((input) => {
              if (input.getValue() === currentPassword) {
                isValid = false;
                return false;
              }

              return true;
            })

            return isValid;
          }
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.WEAK_PASSWORD,
          customFunction: async (formInput, validation, aInputs) => {
            let isValid = true;

            aInputs.every((input) => {
              if (formInput.getValue().length < 6) {
                isValid = false;
                return false;
              }

              return true;
            })

            return isValid;
          }
        },
      ],
    }),
    PasswordConfirmation: FormHelper.useFormInput({
      id: 'password-confirmation',
      label: props.config.strings.form.label.NEW_CONFIRM_PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CONFIRM_PASSWORD,
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.IDENTICAL_PASSWORD,
          customFunction: async (formInput, validation, aInputs) => {
            let isValid = true;

            aInputs.every((input) => {
              if (input.id === 'new-password' && input.getValue() !== formInput.getValue()) {
                isValid = false;
                return false;
              }

              return true;
            })

            return isValid;
          }
        },
      ],
    }),
  }
}

const ModalRedefinitionAccess = styled.div`
  padding: 20px;
  border-radius: 10px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  background-color: #FFF;
  height: 100%;
  display: ${props => props.showModal ? 'flex' : 'none'};
  border: 1px solid #0897E9;

  /* margin: 0 auto; */

  h1 {
    margin-top: 0;
    text-align: center;
    color: #363636;
  }

  .text {
    color: #363636;
    text-align: justify;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }

  .sectionButtons {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    /* justify-content: space-between; */
    gap: 15px
  }
  
  .sectionButton {
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    .sectionButtons{
      /* flex-direction: column; */
      grid-template-columns: repeat(1, 1fr);
      
      /* button {
        width: 100%;
      } */
    }
  }

`