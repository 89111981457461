import styled from '@emotion/styled';
import { BREAKPOINTS, GUTTER } from '../configs/consts';

const GridContainer = styled.div((props) => {
  let fGutter = props.config?.layout?.GUTTER || GUTTER;
  let columnGutter =
    props.columnGutter === undefined ? fGutter : props.columnGutter;
  let rowGutter = props.rowGutter === undefined ? fGutter : props.rowGutter;

  let style = {
    display: 'grid',
    marginTop: props.listSpacing || 20,
    gridTemplateColumns: 'repeat(' + (props.desktopQty || 4) + ', 1fr)',
    gridColumnGap: columnGutter,
    gridRowGap: rowGutter,
  };

  // style[`@media (max-width: 1570px)`] = {
  //   gridTemplateColumns: 'repeat(' + 2 + ', 1fr)',
  // };

  style[`@media (max-width: 1070px)`] = {
    gridTemplateColumns: 'repeat(' + 1 + ', 1fr)',
  };

  return style;
});

export default GridContainer;
