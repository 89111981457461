import styled from "@emotion/styled";

const ImageThumb = (props) => {
  const { elementKey, imageUrl, thumbUrl, loading, removeImage, config } = props;

  const onClickRemove = (e) => {
    e.preventDefault();
    removeImage();
  }

  return <StyledImageThumb href={imageUrl} target={'_blank'} config={config}>
    {loading && <div className={'loading-overlay'}>
      <i className={'fa fa-spinner fa-spin'}/>
    </div>}
    {removeImage && <div className={'remove-image'} onClick={onClickRemove}>
      &times;
    </div>}
    <img src={thumbUrl} />
  </StyledImageThumb>
}

export default ImageThumb;

const StyledImageThumb = styled.a`
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  background: ${props => props.config.themeLayout?.imageBackground};

  .loading-overlay{
    position: absolute;
    background: rgba(255,255,255,0.8);
    width: 100%;
    height: 100%;
    z-index: 2;

    i{
      margin-top: calc(50% - 10px);
    }
  }

  .remove-image{
    position: absolute;
    z-index: 1;
    top: 2px;
    right: 2px;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 1em;
    border-radius: 1em;
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.config?.themeLayout?.link};
    background: ${props => props.config?.themeLayout?.background};
    cursor: pointer;
    user-select: none;
  }

  &:not(:last-of-type){
    margin-right: 15px;
  }

  img{
    max-width: 100px;
    max-height: 100px;
    margin-top: 50%;
    transform: translateY(-50%);
  }
`