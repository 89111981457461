export const providerHelper = {
  getList: (snapshot, userData) => {
    let list = [];

    snapshot.forEach((doc, index) => {
      let document = doc.data();
      document.Available = false;

      if (userData && Object.keys(userData).length > 0) {
        // Valida por: LoyaltyAvailable__c ou MilestoneAvailable__c
        const managerAvailable = [
          document?.LoyaltyAvailable__c ||
          document?.HowToEarn__r?.LoyaltyAvailable__c,
          document?.MilestoneAvailable__c ||
          document?.HowToEarn__r?.MilestoneAvailable__c,
        ];
        const validAvailable =
          managerAvailable[0] || managerAvailable[1] || false;
        if (validAvailable) {
          let isVal_LoyaltyAvailable__c = false;
          let isVal_MilestoneAvailable__c = false;

          // Valida por: LoyaltyAvailable__c
          if (
            managerAvailable[0] &&
            managerAvailable[0].indexOf(userData.LoyaltyCategory__c) > -1
          ) {
            isVal_LoyaltyAvailable__c = true;
          }

          // Valida por: MilestoneCategory__c
          if (
            managerAvailable[1] &&
            managerAvailable[1].indexOf(userData.MilestoneCategory__c) > -1
          ) {
            isVal_MilestoneAvailable__c = true;
          }

          if (isVal_LoyaltyAvailable__c && isVal_MilestoneAvailable__c) {
            document.Available = true;
          }
        }

        // Valida por: LoyaltyVisible__c ou MilestoneVisible__c
        const managerVisible = [
          document?.LoyaltyVisible__c ||
          document?.HowToEarn__r?.LoyaltyVisible__c,
          document?.MilestoneVisible__c ||
          document?.HowToEarn__r?.MilestoneVisible__c,
        ];
        const validVisible = managerVisible[0] || managerVisible[1] || false;

        if (validVisible) {
          let isVal_LoyaltyVisible__c = false;
          let isVal_MilestoneCategory__c = false;

          // Valida por: LoyaltyVisible__c
          if (
            managerVisible[0] &&
            managerVisible[0].indexOf(userData.LoyaltyCategory__c) > -1
          ) {
            isVal_LoyaltyVisible__c = true;
          }

          // Valida por: MilestoneCategory__c
          if (
            managerVisible[1] &&
            managerVisible[1].indexOf(userData.MilestoneCategory__c) > -1
          ) {
            isVal_MilestoneCategory__c = true;
          }

          if (isVal_LoyaltyVisible__c && isVal_MilestoneCategory__c) {
            list.push(document);
          }
        } else list.push(document);
      } else list.push(document);
    });
    return list;
  },

  groupBy: (arr, prop) => {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  },
};
