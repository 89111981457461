import { useState, useMemo, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

import HeaderUserV2 from './HeaderUserV2.js';
import HeaderMobile from './HeaderMobile.js';
import ToolHelper from '../utils/ToolHelper';
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../configs/consts';
import ondaCinza from '../assets/onda_cinza2.png';
import plataforma from '../assets/plataforma.png';
// import logo from '../../assets/logo.png';
import logo from '../../assets/logo-bits - cropped.png';
import logoTotvs from '../../assets/logo_TOTVS.png';
import LoginBox from '../../components/Login/LoginBox';
import HeaderUser from './HeaderUser.js';

// import { ShowModalLoginContext } from '../../provider/ShowModalLogin'

const fontFamily = 'Lato,sans-serif';

function HeaderV2Base(props) {
  const location = useLocation();
  const [isMenuUserOpen, setIsMenuUserOpen] = useState(false);
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  // const { showModalLogin, setShowModalLogin } = useContext(ShowModalLoginContext);

  const { data, userHelper, notification, headerConfig } = props;

  const config = useMemo(
    () => ToolHelper.getThemedConfig(props, 'Header.preset'),
    [props]
  );

  const toggleMenuUser = (doOpen) => {
    let willOpen = !isMenuUserOpen;

    if (doOpen !== undefined) {
      willOpen = doOpen;
    }

    if (willOpen) {
      toggleMenuMobile(false);
    }

    setIsMenuUserOpen(willOpen);
  };

  const toggleMenuMobile = (doOpen) => {
    let willOpen = !isMenuMobileOpen;

    if (doOpen !== undefined) {
      willOpen = doOpen;
    }

    if (willOpen) {
      toggleMenuUser(false);
    }

    setIsMenuMobileOpen(willOpen);
  };

  const clickHomeNLoguada = () => {
    props.popup.setContent(
      <LoginBox
        {...props}
        data={props.data}
        routes={props.routes}
        showHeader={props.showHeader}
        userHelper={props.userHelper}
        popup={props.popup}
      />
    );
    // props.popup.setContent(<SignUpBox {...props}  data={props.data} routes={props.routes} message={props.message} />);
    props.popup.setIsOpen(true);
  };

  const isAuth = global.isAuthenticated;

  return (
    <>
      <StyledHeader
        headerConfig={headerConfig}
        showHeader={props.data.showHeader}
        config={config}
        data={props.data}>
        <div
          className={'links-line'}
          onClick={() => !isAuth && clickHomeNLoguada()}>
          <div className={'links-container'}>
            {headerConfig?.links?.map((link, index) =>
              link.isLogo ? (
                <div style={{ minWidth: 85 }} key={index}>
                  <Link to={isAuth ? '/' : '#'}>
                    <img src={logo} alt='logo' height={32} />
                    <h6>
                      O programa de<span>fidelidade da TOTVS</span>
                    </h6>
                  </Link>
                </div>
              ) : (
                <div className={'header-links'} key={index}>
                  <Link
                    className={
                      'menu-link ' +
                      (link.url === location?.pathname ? 'active' : '')
                    }
                    to={isAuth ? link.url : '#'}
                    target={link.target}>
                    {link.label}
                  </Link>
                </div>
              )
            )}

            <div className='menuDesktop'>
              <HeaderUserV2
                {...props}
                isOpen={isAuth && isMenuUserOpen}
                toggleIsOpen={toggleMenuUser}
                data={data}
                config={config}
                userHelper={userHelper}
                notification={notification}
                headerConfig={headerConfig}
              />
            </div>

            <div
              className='menuPoints'
              style={{ display: props.data?.user?.FirstName ? null : 'none' }}>
              {props.data?.user?.CurrentContactSeason__r
                ?.TotalRedeemableAvailable__c
                ? Math.floor(
                    Math.abs(
                      props.data?.user?.CurrentContactSeason__r
                        ?.TotalRedeemableAvailable__c || 0
                    )
                  ).toLocaleString('pt')
                : 0}{' '}
              BITS TOTVS
              {/* {props.data?.user?.CurrentContactSeason__r?.TotalRedeemableAvailable__c || 0} */}
            </div>

            <div className='logoTotvs'>
              <div
                style={{
                  color: config.colors.TOTVS.C,
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: fontFamily,
                }}>
                <Link to={isAuth ? '/' : '#'}>
                  <img src={logoTotvs} alt='logo' />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {global.isAuthenticated ? (
          <div className='points'>
            {props.data?.user?.CurrentContactSeason__r
              ?.TotalRedeemableAvailable__c ? (
              <span>
                {' '}
                {Math.floor(
                  Math.abs(
                    props.data?.user?.CurrentContactSeason__r
                      ?.TotalRedeemableAvailable__c || 0
                  )
                ).toLocaleString('pt')}{' '}
                BITS TOTVS
              </span>
            ) : (
              <span> 0 BITS TOTVS</span>
            )}
          </div>
        ) : null}
      </StyledHeader>
    </>
  );
}

const HeaderV2 = withRouter(HeaderV2Base);

export default HeaderV2;

/* style */

const StyledHeader = styled.div`
  /* display: ${(props) => (props.showHeader ? 'block' : 'none')}; */
  /* display: flex; */
  /* flex-direction: column; */
  /* position: sticky; */
  top: 0;
  z-index: 9999;
  padding: 30px 55px;
  background: #fff; // ${(props) =>
    props.config?.themeLayout?.background || 'transparent'};

  .points {
    display: none;
    text-align: right;
    color: ${(props) => props.config.colors.TOTVS.C};
    font-size: 15px;
    font-weight: 700;
    span {
      font-size: 15px;
    }
  }

  .links-line {
    flex: 1;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    display: flex;
    /* padding-left: 20px;
    padding-right: 20px; */

    .links-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      /* padding: 30px 27px; */
      /* padding: 30px 0px; */

      .header-links {
        display: inline-block;

        .menu-link {
          padding-top: 10px;
          font-size: 16px;
          text-decoration: none;
          color: #363636; // ${(props) => props.config?.themeLayout?.link};
          font-family: Lato, sans-serif;

          &.active {
            color: #0897e9;
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }

      a {
        color: #363636;
      }

      a h6 {
        margin: 0;
        text-transform: uppercase;
        text-align: center;
        font-size: 10px;
      }
      a h6 span {
        display: block;
        font-size: 7.7px;
      }

      .menuPoints {
        color: ${(props) => props.config.colors.TOTVS.C};
        font-size: 20px;
        font-weight: 700;
      }
      .side-container {
        display: none;
      }
    }
    .links-container a img {
      min-width: 100%;
    }
  }

  .header-line {
    // background: #f6f6f6;
    background: #fff;
    flex: 1;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    display: flex;
    // padding: 20px 0;

    .header-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: ${(props) =>
        props.config?.layout?.CONTAINER_SIZE + 'px' || 'none'};
      margin-left: auto;
      margin-right: auto;
      margin-top: 8px;
      margin-bottom: 5px;

      /* padding-left: 20px; */
      /* padding: 0 110px; */

      .link-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;

        .logo {
          display: inline-block;
          width: clamp(100px, calc(20vw), 225px);
        }
      }

      .slogan {
        font-weight: bold;
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: max(2vw, 10px);

        img {
          width: clamp(150px, 20vw, 300px);
        }
      }

      .side-container {
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
  }

  .wave-line {
  }

  @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    .points {
      display: block;
    }
    .links-line {
      .links-container {
        .menuDesktop {
          img {
            width: 30px;
          }
        }
        .logoTotvs {
          display: none;
        }
        img {
          width: 70px;
        }
        .menuPoints {
          display: none;
        }
        .side-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .menuPoints {
            display: block;
            font-size: 15px;
            span {
              font-size: 10px;
            }
          }
        }
        .header-links {
          display: none;
        }
      }
    }
  }
`;
