import styled from '@emotion/styled';
import { useContext, useEffect, useState } from 'react';

import { forwardRef } from 'react';
import { Button as FunctionButton } from '../../components/Button/index.tsx';
import { ShowModalLoginContext } from '../../provider/ShowModalLogin';
import SignUpFull from '../../screens/Login/SignUpFull';
import FormHelper from '../utils/FormHelper';
import Checkbox from './Checkbox';
import FormContent from './FormContent';
import FormFile from './FormFile';
import FormImage from './FormImage';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import GridContainer from './GridContainer';
import GridItem from './GridItem';
import TextArea from './TextArea';

const Form = forwardRef((props, ref) => {
  const { showModalLogin, setShowModalLogin } = useContext(
    ShowModalLoginContext
  );
  const [aInputs, setAInputs] = useState([]);
  const [hasSetFocus, setHasSetFocus] = useState(false);
  const [images, setImages] = useState([]);
  const { config, layout, dataHelper, resetAfterSend } = props;

  useEffect(() => {
    setAInputs(
      props.inputs &&
        Object.entries(props.inputs).map(([key, input]) => {
          if (!hasSetFocus && !input.getValue()) {
            setHasSetFocus(true);
            input.setFocus();
          }

          if (
            input.type === 'hidden' ||
            (props.mode && input.hideOn?.includes(props.mode))
          ) {
            input.setIsVisible(false);
          } else {
            input.setIsVisible(true);
          }

          return {
            id: key,
            ...input,
          };
        })
    );
  }, [props.inputs]);

  const validationFcs = {
    required: (formInput, validation) => {
      if (formInput.type === 'checkbox') {
        if (!parseInt(formInput.getValue())) {
          formInput.focusError(validation.errorMessage);
          return false;
        }
      } else {
        if (!formInput.getValue().length) {
          formInput.focusError(validation.errorMessage);
          return false;
        }
      }

      return true;
    },
    age: (formInput, validation) => {
      let value = formInput.getValue();
      if (
        value?.length &&
        !FormHelper.validateAge({ ...validation.config, date: value })
      ) {
        formInput.focusError(validation.errorMessage);
        return false;
      }

      return true;
    },
    cnpj: (formInput, validation) => {
      const valueInput = formInput.getValue();

      // if(valueInput.length === 14) {
      //   if(!FormHelper.validateCPF(valueInput)){
      //     formInput.focusError(validation.errorMessage)
      //     return false;
      //   }
      //   return true
      // }

      if (valueInput.length > 14) {
        if (!FormHelper.validateCNPJ(valueInput)) {
          formInput.focusError(validation.errorMessage);
          return false;
        }
        return true;
      }

      // formInput.focusError('Digite um CPF ou CNPJ válido');
      formInput.focusError(validation.errorMessage);
      return false;
    },
    cpf: (formInput, validation) => {
      const valueInput = formInput.getValue();

      if (valueInput.length === 14) {
        if (!FormHelper.validateCPF(valueInput)) {
          formInput.focusError(validation.errorMessage);
          return false;
        }
        return true;
      }

      formInput.focusError(validation.errorMessage);
      return false;
    },
    cnpjAndCpf: (formInput, validation) => {
      const valueInput = formInput.getValue();

      if (valueInput.length >= 14) {
        if (!FormHelper.validateCPFOrCNPJ(valueInput)) {
          formInput.focusError(validation.errorMessage);
          return false;
        }
        return true;
      }

      // formInput.focusError('Digite um CPF ou CNPJ válido');
      formInput.focusError(validation.errorMessage);
      return false;
    },
    email: (formInput, validation) => {
      const email = formInput.getValue();
      const lowerEmail = email.toLowerCase();
      if (email?.length && !FormHelper.validateEmail(lowerEmail)) {
        formInput.focusError(validation.errorMessage);
        return false;
      }

      return true;
    },
    custom: async (formInput, validation, aInputs) => {
      let isValid = await validation.customFunction(
        formInput,
        validation,
        aInputs
      );

      if (!isValid) {
        formInput.focusError(validation.errorMessage);
        return false;
      }

      return true;
    },
    custom1: async (formInput, validation, aInputs) => {
      let isValid = await validation.customFunction(
        formInput,
        validation,
        aInputs
      );

      if (!isValid) {
        formInput.focusError(validation.errorMessage);
        return false;
      }

      return true;
    },
    name: (formInput, validation) => {
      const name = formInput.getValue();
      if (!FormHelper.validateName(name)) {
        formInput.focusError(validation.errorMessage);
        return false;
      }
      return true;
    },

    phone: (formInput, validation) => {
      const phone = formInput.getValue();
      if (phone.length < 14) {
        formInput.focusError(validation.errorMessage);
        return false;
      }
      return true;
    },

    // landline: (formInput, validation) => {
    //   const landline = formInput.getValue();
    //   if (landline.length < 2) {
    //     formInput.focusError(validation.errorMessage);
    //     return false;
    //   }
    //   return true;
    // },
  };

  const checkSend = (e) => {
    if (!props.loading) {
      var keyCode = e.which || e.keyCode;
      if (keyCode === 13)
        // enter
        submit();
    }
  };

  const clearValidation = () => {
    Object.entries(props.inputs).map(([key, input]) => {
      input.setError('');
    });
  };

  const validateForm = async () => {
    let isValid = true;
    // reverse - valida de baixo para cima, assim, o último input a ser validado será o primeiro,
    //           fazendo com que o cursor termine no primeiro input inválido.
    let validateInputs = [...aInputs].reverse();

    // É necessário usar loop nativo para que 'await' seja respeitado
    for (let i = 0; i < validateInputs.length; i++) {
      let input = validateInputs[i];

      if (input.isVisible && input.validations?.length) {
        for (let j = 0; j < input.validations.length; j++) {
          let validation = input.validations[j];
          let inputValid = await validationFcs[validation.type](
            input,
            validation,
            aInputs
          );
          if (inputValid) {
            continue;
          } else {
            isValid = false;
            props.setLoading(false);
            break;
          }
        }
        props.setLoading(false);
      }
    }

    //validação custom para TOTOVS por email
    // const userName = props.inputs.Username;
    // const email = userName?.getValue();
    // if (email?.indexOf('@') > 0) {
    //   const domain = email.split('@')[1];
    //   var jsonRegistrationFilter = await dataHelper.getRegistrationFilter();
    //   //debugger
    //   const hasDomain = jsonRegistrationFilter?.writelist?.find(element => element.domain === `@${domain}`);
    //   if (!hasDomain) {
    //     const msg = 'Domínio Inválido!';
    //     userName.setError(msg);
    //     isValid = false;
    //   }
    // }

    // por CNPJ
    // const CNPJ_CPF__c = props.inputs.CNPJ_CPF__c;
    // const CNPJ_CPF = CNPJ_CPF__c ? CNPJ_CPF__c.getValue() : '';

    // if(FormHelper.validateCNPJ(CNPJ_CPF)) {
    //   const jsonRegistrationFilter2 = await dataHelper.getRegistrationFilter();
    //   const hasDomain = jsonRegistrationFilter2?.writelist?.find(element => element.cnpj === CNPJ_CPF);
    //   if (!hasDomain) {
    //     setErros(oldValue => ({...oldValue,
    //       cnpjError: true,
    //     }))
    //     const msg = 'CNPJ não encontrado!';
    //     CNPJ_CPF__c.setError(msg);
    //     // setShowModalLogin(true);
    //     // setErros(true);
    //     // isValid = true;
    //     // setShowModalLogin(oldValue => ({...oldValue,
    //     //   cnpjError: true,
    //     // }))

    //     if(props.setErros) {
    //       props.setErros(oldValue => ({...oldValue,
    //         cnpjError: true,
    //       }))
    //     }
    //     return
    //   }
    //   setErros(oldValue => ({...oldValue,
    //     cnpjError: false,
    //   }));

    //   if(props.setErros) {
    //     props.setErros(oldValue => ({...oldValue,
    //       cnpjError: false,
    //     }))
    //   }
    // }

    // if(!FormHelper.validateCNPJ(CNPJ_CPF)) {
    //   const msg = 'CNPJ inválido!';
    //   CNPJ_CPF__c?.setError(msg);
    //   isValid = false;
    // }

    // const emailFaleConsco = props.inputs.EmailFaleConosco;
    // if(emailFaleConsco?.getValue()) {
    //   if(!FormHelper.validateEmail( emailFaleConsco.getValue()) ) {
    //     const msg = 'Digite um email válido !';
    //     emailFaleConsco.setError(msg);
    //     isValid = false;
    //   }
    // }

    // const Email = props.inputs.Email;
    // const emailValue = Email?.getValue();

    // if(props.mode === 'create') {
    //   if(FormHelper.validateEmail(emailValue)) {
    //     if (emailValue?.indexOf('@') > 0) {
    //       const domain = emailValue?.split('@')[1];
    //       var  jsonRegistrationFilter3 = await dataHelper.getRegistrationFilter();
    //       const hasDomain = jsonRegistrationFilter3?.writelist?.find(element => element.domain === `@${domain}`);
    //       if (!hasDomain) {
    //         setErros(oldValue => ({...oldValue,
    //           emailError: true,
    //         }))
    //         // setShowModalLogin(oldValue => ({...oldValue,
    //         //   emailError: true,
    //         // }))
    //         const msg = 'Domínio Inexistente, ou já utilizado!';
    //         Email.setError(msg);
    //         // setShowModalLogin(true);
    //         if(props.setErros) {
    //           props.setErros(oldValue => ({...oldValue,
    //             emailError: true,
    //           }))
    //         }
    //         // if( props?.setFormValid ) {
    //         //   props?.setFormValid(true);
    //         // }
    //         // setErros(true);

    //         // isValid = true;
    //         return
    //       }
    //       if(props.setErros) {
    //         props.setErros(oldValue => ({...oldValue,
    //           emailError: false,
    //         }))
    //       }

    //       setErros(oldValue => ({...oldValue,
    //         emailError: false,
    //       }))
    //     }
    //   }

    //   // if(emailValue) {
    //   //   if(!FormHelper.validateEmail(emailValue)) {
    //   //     const msg = 'Digite um email válido !';
    //   //     Email?.setError(msg);
    //   //     isValid = false;
    //   //   }
    //   // }
    // }

    return isValid;
  };

  const resetForm = () => {
    // Object.entries(props.inputs).map(([key,input]) => {

    //   input.setValue('');
    // })
    setImages([]);
  };
  // const submit = async ({e,addAnother = false, ...rest}) => {
  const submit = async (ev) => {
    // ev.preventDefault();
    props.setLoading(true);
    clearValidation();

    const validationForm = await validateForm();

    if (validationForm) {
      props.setLoading(true);
      // if(true){
      let formData = {};
      Object.entries(props.inputs).map(([key, input]) => {
        formData[key] = input.getValue();
      });

      const result = await props.handleSend(formData);
      if (result?.redirect) {
        if (result?.redirect === '/') {
          // props.setLoading(true);
          window.location.reload(false);
          return;
        }
        props.popup.setIsOpen(false);
        props.popup.setContent(
          <SignUpFull
            {...props}
            data={props.data}
            routes={props.routes}
            showHeader={props.showHeader}
            userHelper={dataHelper.userHelper}
          />
        );
        props.popup.setIsOpen(true);
      }
      if (resetAfterSend) {
        // props.setLoading(true);
        console.log('caiu 3');
        resetForm();
        props.setLoading(false);
        return;
      }
      // SE CHEGOU ATE AQUI, O USER EXISTE E DEVE
      // SER REDIRECIONADO PARA PAG DE LOGIN
      //  props.popup.setIsOpen(false);

      if (props.inputs.Username?.ref?.current?.value.toLowerCase() !== null) {
        // props.setLoading(true);
        console.log('caiu 4');
        props.setLoading(false);
        return;
      }
      props.setLoading(false);
      props.popup.setContent(
        <SignUpFull
          {...props}
          data={props.data}
          routes={props.routes}
          showHeader={props.showHeader}
          userHelper={dataHelper.userHelper}
        />
      );
      props.popup.setIsOpen(true);
      props.setLoading(false);
      return;
    }
    props.setLoading(false);
  };
  // return <StyledForm className='ctn-styled-form' ref={ref} config={config} layout={layout} onSubmit={submit} onKeyDown={checkSend}>
  // return <StyledForm className='ctn-styled-form' ref={ref} config={config} onSubmit={(e) => (e.preventDefault())} layout={layout} onKeyDown={checkSend}>
  // return <StyledForm className='ctn-styled-form' ref={ref} config={config} onSubmit={submit} layout={layout} onKeyDown={checkSend}>
  return (
    // <StyledForm className='ctn-styled-form' ref={ref} onSubmit={(ev) => submit(ev)} config={config} layout={layout}>
    <StyledForm
      className='ctn-styled-form'
      ref={ref}
      config={config}
      layout={layout}>
      <GridContainer
        className='ctn-grid'
        desktopQty={8}
        tabletQty={4}
        phoneQty={4}
        columnGutter={undefined}
        config={config}>
        {aInputs.map((input, index) => {
          if (props.mode && input.hideOn?.includes(props.mode)) {
            return false;
          }

          // if(isElement(input)){
          //   return input;
          // }
          const inputProps = {
            config,
            input,
            layout,
            dataHelper,
          };

          let inputElement;
          switch (input.type) {
            case 'checkbox':
              inputElement = <Checkbox {...inputProps} />;
              break;
            case 'textarea':
              inputElement = <TextArea {...inputProps} />;
              break;
            case 'select':
              inputElement = <FormSelect {...inputProps} />;
              break;
            case 'image':
              inputElement = (
                <FormImage
                  images={images}
                  setImages={setImages}
                  {...inputProps}
                />
              );
              break;
            case 'file':
              inputElement = (
                <FormFile
                  images={images}
                  maxFileSize={props.maxFileSize}
                  showMaxFileSizeError={props.showMaxFileSizeError}
                  setImages={setImages}
                  {...inputProps}
                />
              );
              break;
            case 'content':
              inputElement = <FormContent {...inputProps} />;
              break;
            case 'text':
            default:
              inputElement = <FormInput {...inputProps} />;
          }

          return (
            <GridItem
              key={index}
              {...input.size}
              className={input.type === 'hidden' ? 'hide' : 'show'}>
              {inputElement}
            </GridItem>
          );
        })}
      </GridContainer>
      <div className={'button-container'}>
        <FunctionButton
          onClick={() => submit()}
          loading={props.loading}
          disabled={props.disabled}>
          <span>{props.saveLabel || 'Enviar'}</span>
        </FunctionButton>
      </div>
    </StyledForm>
  );
});

export default Form;

/* style */

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* position: 'relative'; */

  .form-message {
    margin-top: 20px;
    align-self: start;

    &.error {
      color: ${(props) => props.config?.layout?.ERROR_COLOR || '#c62828'};
    }

    &.success {
      color: ${(props) => props.config?.layout?.SUCCESS_COLOR || '#558b2f'};
    }
  }

  .header-user-icon {
    text-align: center;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  .button-container {
    width: 100%;
    margin-top: 15px;
  }
`;
