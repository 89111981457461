import styled from "@emotion/styled"
import { useContext, useMemo } from "react";
import { BREAKPOINTS } from "../configs/consts";
import ToolHelper from "../utils/ToolHelper";
import SectionContainer from "./SectionContainer";
import StyledLink from "./StyledLink"
import { ShowModalLoginContext } from '../../provider/ShowModalLogin'
import LoginBox from "../Login/LoginBox";
import { Container } from '../StyleContainer'

const footerLinks = [
  {
    text: 'Sobre o BITS',
    url: 'duvidas',
  },
  {
    text: 'Termos de Uso e Privacidade',
    url: 'termos-de-uso',
  },
  {
    text: 'Fale Conosco',
    url: 'fale-conosco',
  },
  {
    text: 'Produtos TOTVS',
    url: 'produtos',
  },
]

const FooterLinks = (props) => {

  const config = useMemo(() => ToolHelper.getThemedConfig(props,'FooterLinks.preset'),[props.config]);

  const { setShowModalLogin } = useContext(ShowModalLoginContext)

  /*
  return <SectionContainer config={config} verticalMargin={0}>
    <StyledFooterLinks config={config}>
      {footerLinks.map((link,index) => <StyledLink key={index} config={config} to={link.url}>
        {link.text}
      </StyledLink>)}
    </StyledFooterLinks>
  </SectionContainer>
  */
  const padding = 35;

  const isAuth = global.isAuthenticated;

  const clickHomeNLoguada = () => {
    props.popup.setContent(
      <LoginBox
        {...props}
        data={props.data}
        routes={props.routes}
        showHeader={props.showHeader}
        userHelper={props.userHelper}
        popup={props.popup}
      />
    );
    // props.popup.setContent(<SignUpBox {...props}  data={props.data} routes={props.routes} message={props.message} />);
    props.popup.setIsOpen(true);
  }

  return (
    <Container>
      <StyledFooterLinks config={config}>
        {footerLinks.map((link,index) => {

          if (link.url === 'produtos') {
            return (
              <div key={index} >
                <StyledLink onClick={() => !isAuth && clickHomeNLoguada()} key={index} config={config} to={ isAuth ? `/${link.url}` : '#'}>
                  {link.text}
                </StyledLink>
              </div>
            )
          }

          return (
            <StyledLink key={index} config={config} to={`/${link.url}`}>
              {link.text}
            </StyledLink>
          )
        })}
      </StyledFooterLinks>
    </Container>
  )
}

export default FooterLinks;

/* style */

const StyledFooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  /* width: 100%;
  flex-direction: row;
   */
  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    text-align: center;
    padding-top: 15px
  }

`