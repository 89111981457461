import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import db from '../config/db/firebasedb';

export const useGetDataGamification = (userData) => {
  const [challenges, setChallenges] = useState([]);
  const [simplePoint, setSimplePoint] = useState([]);
  const [pointsPerDollar, setPointsPerDollar] = useState([]);
  const [challengesOnlyCampaignMember, setChallengesOnlyCampaignMember] = useState([]);
  const [simplePointOnlyCampaignMember, setSimplePointOnlyCampaignMember] = useState([]);

  const arrangeChallengeList = (challenges, userProgresses) => {
    let objectOutput = {};

    challenges.forEach((challenge) => {
      if (!objectOutput[challenge.Id]) objectOutput[challenge.Id] = {};
      objectOutput[challenge.Id] = challenge;
    });

    userProgresses.forEach((userProgress) => {
      if (!objectOutput[userProgress.CampaignItem__c])
        objectOutput[userProgress.CampaignItem__c] = {};
      objectOutput[userProgress.CampaignItem__c].userProgress = userProgress;
    });

    let arrayOutput = [];

    Object.entries(objectOutput).forEach((object) => {
      arrayOutput.push(object[1]);
    });

    return arrayOutput;
  };

  const getList = useCallback((snapshot) => {
    let list = [];

    snapshot?.forEach((doc, index) => {
      let document = doc.data();
      document.Available = false;

      if (userData) {
        // Valida por: LoyaltyAvailable__c ou MilestoneAvailable__c
        const managerAvailable = [
          document?.LoyaltyAvailable__c ||
          document?.HowToEarn__r?.LoyaltyAvailable__c,
          document?.MilestoneAvailable__c ||
          document?.HowToEarn__r?.MilestoneAvailable__c,
        ];
        const validAvailable = managerAvailable[0] || managerAvailable[1] || false;

        if (validAvailable) {
          let isVal_LoyaltyAvailable__c = false;
          let isVal_MilestoneAvailable__c = false;

          // Valida por: LoyaltyAvailable__c
          if (
            managerAvailable[0] &&
            managerAvailable[0].indexOf(userData.LoyaltyCategory__c) > -1
          ) {
            isVal_LoyaltyAvailable__c = true;
          }

          // Valida por: MilestoneCategory__c
          if (
            managerAvailable[1] &&
            managerAvailable[1].indexOf(userData.MilestoneCategory__c) > -1
          ) {
            isVal_MilestoneAvailable__c = true;
          }

          if (isVal_LoyaltyAvailable__c && isVal_MilestoneAvailable__c) {
            document.Available = true;
          }
        }

        // Valida por: LoyaltyVisible__c ou MilestoneVisible__c
        const managerVisible = [
          document?.LoyaltyVisible__c ||
          document?.HowToEarn__r?.LoyaltyVisible__c,
          document?.MilestoneVisible__c ||
          document?.HowToEarn__r?.MilestoneVisible__c,
        ];

        const validVisible = managerVisible[0] || managerVisible[1] || false;

        if (validVisible) {
          let isVal_LoyaltyVisible__c = false;
          let isVal_MilestoneCategory__c = false;

          // Valida por: LoyaltyVisible__c
          if (
            managerVisible[0] &&
            managerVisible[0].indexOf(userData.LoyaltyCategory__c) > -1
          ) {
            isVal_LoyaltyVisible__c = true;
          }

          // Valida por: MilestoneCategory__c
          if (
            managerVisible[1] &&
            managerVisible[1].indexOf(userData.MilestoneCategory__c) > -1
          ) {
            isVal_MilestoneCategory__c = true;
          }

          if (isVal_LoyaltyVisible__c && isVal_MilestoneCategory__c) {
            list.push(document);
          }
        } else list.push(document);
      } else list.push(document);
    });

    return list;
  }, [userData]);

  const snapChallenges = useCallback(async () => {
    let challengesQ = query(
      collection(db, '/HowToEarn/Progress/records/'),
      where('Valid__c', '==', true)
    );

    let simplePointsQ = query(
      collection(db, '/HowToEarn/SimplePoint/records/'),
      where('Valid__c', '==', true)
    );

    let pointsPerDollarQ = query(
      collection(db, '/HowToEarn/PointPerDollar/records/'),
      where('Valid__c', '==', true)
    );

    const challengesOnlyCampaignMemberQuery = query(
      collection(db, '/HowToEarn/Progress/OnlyCampaignMembers/'),
      where('Valid__c', '==', true)
    );

    const simplePointsOnlyCampaignMemberQuery = query(
      collection(db, '/HowToEarn/SimplePoint/OnlyCampaignMembers/'),
      where('Valid__c', '==', true)
    );

    // Busca progressos do usuário
    let userProgressesQ = query(
      collection(db, '/Users/' + userData?.FirebaseId__c + '/CampaignProgress/')
    );

    const userProgresss = [];

    // NOVO METODO
    onSnapshot(userProgressesQ, (snapshot) => {
      userProgresss.push(...getList(snapshot));
    });

    onSnapshot(challengesQ, (snapshot) => {
      const arrangedChallengeList = arrangeChallengeList(
        getList(snapshot),
        userProgresss
      );

      setChallenges(arrangedChallengeList);
    });

    onSnapshot(simplePointsQ, (snapshot) => {
      const arrangedChallengeList = arrangeChallengeList(
        getList(snapshot),
        userProgresss
      );

      setSimplePoint(arrangedChallengeList);
    });

    onSnapshot(pointsPerDollarQ, (snapshot) => {
      const arrangedChallengeList = arrangeChallengeList(
        getList(snapshot),
        userProgresss
      );

      setPointsPerDollar(arrangedChallengeList);
    });

    onSnapshot(challengesOnlyCampaignMemberQuery, (snapshot) => {
      const arrangedChallengeList = arrangeChallengeList(
        getList(snapshot),
        userProgresss
      );

      setChallengesOnlyCampaignMember(arrangedChallengeList);
    });

    onSnapshot(simplePointsOnlyCampaignMemberQuery, (snapshot) => {
      const arrangedChallengeList = arrangeChallengeList(
        getList(snapshot),
        userProgresss
      );

      setSimplePointOnlyCampaignMember(arrangedChallengeList);
    });
  }, [getList]);

  useEffect(() => {
    snapChallenges();
  }, [snapChallenges]);

  const gamificationData = useMemo(() => {
    return [
      ...challenges,
      ...simplePoint,
      ...pointsPerDollar,
      ...challengesOnlyCampaignMember,
      ...simplePointOnlyCampaignMember,
    ];
  }, [
    challenges,
    simplePoint,
    pointsPerDollar,
    challengesOnlyCampaignMember,
    simplePointOnlyCampaignMember,
  ]);

  return {
    gamificationData,
  };
};
