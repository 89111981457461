
const COLORS = {
  TOTVS: {
    A: '#363636',
    B: '#EEEEEE',
    C: '#0897E9',
    D: '#FEAC0E',
    E: '#0ADBE3',
    F: '#6B48FF',
  },
  LIGHT: {
    A: '#ffffff',
    B: '#EEE',
    C: '#DDD',
    D: '#CCC',
    BG: {
      A: '#FFF',
      B: '#FD0',
      C: '#EEE',
      D: 'url("https://setecnet.com.br/home/wp-content/uploads/2018/02/betfair-pattern.png") bottom #FFF',
      E: '#f3f7e0',
    }
  },
  DARK: {
    A: '#363636',
    B: '#363636',
    C: '#363636',
    D: '#EEEEEE',
    E: '#363636',
    F: '#ABC432',
    G: '#363636',
    SUCCESS: '#54BF33',
    WARNING: '#FAB416',
    ERROR: '#E63B3B',
    BG: {
      A: '#ff0000',
      B: '#0897E9',
      C: '#777',
      D: '#0897E9',
      E: '#000',
      F: 'rgba(0,0,0,0.8)',
      G: '#122444',
      H: '#21417C',
      I: '#0897E9',
    }
  },
  MISC: {
    TRANSPARENT: 'transparent',
    IMAGE_BG: 'url("data:image/webp;base64,UklGRmwAAABXRUJQVlA4WAoAAAAQAAAABAAABAAAQUxQSBYAAAABDzAhERFCadtIbAwea/+oRfQ/GvwBVlA4IDAAAACQAQCdASoFAAUAAgA0JaQAApplHeAA/tPpPS2F/PjfDX7Mr9vZ2Xy98v/pAAAAAAA=")',

  }
}

export default COLORS;