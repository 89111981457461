import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ children, routesO, ...rest }) {
  return (
    <Route
      {...rest}
      render={
        ({ location }) => {
          let result;
          if(global.isAuthenticated && rest.path === routesO.logout.path){
            result = children;
          }
          // else if(global.needsToValidateAccessCode && rest.path !== routesO.accessCode.path){
          //   result = <Redirect
          //     to={{pathname: routesO.accessCode.path}}
          //   />
          // }
          // else if(global.needsToValidateTermsAgreement && rest.path !== routesO.termsAgreement.path){
          //   result = <Redirect
          //     to={{pathname: routesO.termsAgreement.path}}
          //   />
          // }
          else if(global.isAuthenticated){
            if(global.redirectAfterLogin){
              const newLocation = global.redirectAfterLogin;

              result = <Redirect
                to={newLocation}
              />

              delete global.redirectAfterLogin;
            }
            else{
              result = children;
            }
          }
          else{
            global.redirectAfterLogin = location;

            result = <Redirect
              to={{
                // pathname: '/login',
                pathname: '/',
                // state: { from: location }
              }}
            />
          }

          return result;
        }
      }
    />
  );
}

export default PrivateRoute;