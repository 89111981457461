import styled from "@emotion/styled";
import StyledLink from "../Molecules/StyledLink";
import Button from "./Button";


const SectionLink = (props) => {
  let {config, link} = props;
  return link?.display ?
    <StyledSectionLink config={config}>
      {link.type === 'button' ?
        <Button config={config} href={link.url} onClick={link.onClick} small>
          {link.title}
        </Button>
        :
        <StyledLink config={config} to={link.url} onClick={link.onClick}>
          {link.title} <i className={'fa fa-chevron-right'}/>
        </StyledLink>
      }
    </StyledSectionLink>
    :
    <></>
}

export default SectionLink;

/* style */

const StyledSectionLink = styled.div`
  font-size: 1em;
  color: ${props => props.config.themeLayout?.link};

  .fa{
    margin-left: 10px;
    font-size: 1em;
  }
`