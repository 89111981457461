import React, { useState, useMemo } from 'react';

import Form from '../Molecules/Form';
import StyledLink from '../Molecules/StyledLink';
import FormHelper from '../utils/FormHelper';
import ToolHelper from '../utils/ToolHelper';
import FormBox from '../Molecules/FormBox';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const ForgetPasswordBox = (props) => {
  const { dataHelper, history, message, routes, username } = props;
  const [loading, setLoading] = useState(0);

  const values = {
    Username: history?.location?.state?.username || username,
  };

  const config = useMemo(
    () => ToolHelper.getThemedConfig(props, 'LoginBox.preset'),
    [props.config]
  );
  const inputs = Forgotpasswordform({ routes: props.routes, config }, values);

  const forgotPassword = async (formData) => {
    let username = formData['Username'];
    let result = await props.userHelper.recoverPassword(username);

    if (result.success) {
      switch (result.code) {
        case 'user-exists':
        case 'user-needs-password-definition':
          const auth = getAuth();
          await sendPasswordResetEmail(auth, username, null)
            .then(() => {
              props.popup.setIsOpen(false);
              message.add({
                type: 'success',
                message: config.strings.form.message.FORGOT_PASSWORD_SUCCESS,
              });
            })
            .catch(function (e) {
              console.log('Erro ao redefenir senha: ', e);
            });
          break;
        case 'user-does-not-exist':
          inputs.Username.focusError(
            config.strings.form.message.USER_NOT_FOUND
          );
          break;
        default:
          message.add({
            type: 'success',
            message: config.strings.form.message.FORGOT_PASSWORD_SUCCESS,
          });
      }
    } else {
      if (result?.error?.code) {
        switch (result?.error?.code) {
          case 'auth/user-not-found':
            inputs.Username.focusError(
              config.strings.form.message.USER_NOT_FOUND
            );
            break;
          default:
            message.add({
              type: 'error',
              message: config.strings.form.message.GENERIC_ERROR,
            });
        }
      } else if (result?.message) {
        message.add({ type: 'error', message: result.message });
      } else {
        message.add({
          type: 'error',
          message: config.strings.form.message.GENERIC_ERROR,
        });
      }
    }
  };

  return (
    <FormBox
      {...props}
      config={config}
      title={config.strings.screen.forgotPassword.TITLE}
      inside={
        <Form
          {...props}
          history={history}
          dataHelper={dataHelper}
          config={config}
          inputs={inputs}
          handleSend={forgotPassword}
          loading={loading}
          setLoading={setLoading}
          asideSend={
            <StyledLink
              to={props.routes.login.path}
              type={'aside'}
              config={config}>
              {config.strings.screen.forgotPassword.BACK_TO_LOGIN}
            </StyledLink>
          }
          sendLabel={config.strings.form.label?.SEND}
        />
      }
    />
  );
};

export default ForgetPasswordBox;

/* form */

const Forgotpasswordform = (props, startValues) => {
  return {
    Username: FormHelper.useFormInput({
      label: props.config.strings.form.label.USERNAME,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_USERNAME,
        },
      ],
      inputOptions: {
        spellCheck: false,
      },
      mask: 'username',
      maskOptions: ['text', 'cpf', 'cnpj'],
      startValue: startValues?.Username,
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),
  };
};
