import styled from "@emotion/styled";
import { useState, useEffect, useRef } from "react";
import ImageThumb from "./ImageThumb";

const FormImage = (props) => {
  const { input, config, dataHelper, images, setImages} = props;
  const inputRef = useRef(null);

  useEffect(() => {
    input.setValue(JSON.stringify(images));
  },[images])

  const onImageChange = async (ev) => {
    if (ev.target.files && ev.target.files[0]) {
      let img = ev.target.files[0]; // <-- upload

      let aImages = [...images];
      
      const imageBlob = URL.createObjectURL(img);
      const imageId = input.inputOptions.imageIdGenerator();
      const path = input.inputOptions.path;

      setImages([...aImages,{
        loading: true,
        imageBlob,
        path,
        imageId,
      }])

      const imageUrl = await dataHelper.uploadImage(img,path,imageId);

      setImages([...aImages,{
        loading: false,
        imageBlob,
        path,
        imageId,
        imageUrl,
      }])
    }    
  }

  const labelClick = (ev) => {
    ev.stopPropagation();
    inputRef.current.click();
  }

  const removeImage = (image) => {
    setImages(a => a.filter(i => i !== image))
    dataHelper.deleteImage(image.path,image.imageId);
  }

  return <StyledFormImage config={config}>
    <input 
      type={'hidden'}
      id={props.id || 'formimage' + Math.floor(Math.random() * 10000)}
      ref={input?.ref}
    />
    <a className={'label'} onClick={labelClick}>
      {input.label} <i className={"fa fa-paperclip"} />
    </a>
    <input ref={inputRef} className={'file-input'} type="file" name="myImage" accept="image/*" onChange={onImageChange}/>
    <div className={'images'}>
      {images?.map((image) => 
        <ImageThumb 
          key={image.imageId}
          config={config}
          imageUrl={image.imageUrl} 
          thumbUrl={image.imageBlob}
          loading={image.loading}
          removeImage={() => removeImage(image)}
        />
      )}
    </div>
  </StyledFormImage>
}

export default FormImage;

const StyledFormImage = styled.div`
  .label{
    color: ${props => props.config?.themeLayout?.link};
    cursor: pointer;
  }

  .file-input{
    position: absolute;
    bottom: -30px;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .images{
    display: flex;
    margin-top: 15px;
  }
`