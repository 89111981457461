import { useState, useRef, useEffect } from 'react';
import moment from "moment";

const FormHelper = {
  useFormInput: (data) => {

    const ref = useRef(null);

    const [ error, setError ] = useState('');
    const [ options, setOptions ] = useState([]); // opções de um <select>
    const [ inputOptions, setInputOptions ] = useState({}); // configurações de um input (ie disabled="disabled")
    const [ isRequired, setIsRequired ] = useState(false);
    const [ isVisible, setIsVisible ] = useState(false);
    const [ type, setType ] = useState(data.type);

    useEffect(() => {
      const isRequired = !!data?.validations?.filter((validation) => validation?.type === 'required').length;
      setIsRequired(isRequired);
    },[data.validations])

    const getValue = () => ref?.current?.value || '';

    const setValue = (value) => {
      if(value !== undefined && ref?.current){
        ref.current.value = value;
        ref.current.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }

    const setFocus = () => ref?.current?.focus();

    const focusError = (message) => {
      setError(message);
      setFocus();
    }

    useEffect(() => {
      if(data.options){
        setOptions(data.options);
      }

      if(data.inputOptions){
        setInputOptions(data.inputOptions);
      }
    },[])

    return {
      ...data,

      inputOptions,
      ref,
      error,
      options,

      setValue,
      getValue,
      type,
      setType,
      setInputOptions,
      setError,
      setFocus,
      focusError,
      setOptions,
      isRequired,
      setIsVisible,
      isVisible,
    }
  },
  validateEmail: (email) => {
    const lowerEmail = email.toLowerCase()
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(lowerEmail);
  },
  
  validateCNPJ : (cnpj) => {
    var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
    var c = String(cnpj).replace(/[^\d]/g, '')
    
    if(c.length !== 14)
        return false

    if(/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
  },

  isNumeric : (value) => {
    return /^\d+(?:\.\d+)?$/.test(value);
  },

  validateCPF: (cpf) => {
    if (typeof cpf !== 'string') return false
    
    cpf = cpf.replace(/[^\d]+/g, '')
    
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
    
    cpf = cpf.split('')
    
    const validator = cpf
        .filter((digit, index, array) => index >= array.length - 2 && digit)
        .map( el => +el )
        
    const toValidate = pop => cpf
        .filter((digit, index, array) => index < array.length - pop && digit)
        .map(el => +el)
    
    const rest = (count, pop) => (toValidate(pop)
        .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) 
        % 11 
        % 10
        
    return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1])
  },

  validateCPFOrCNPJ: (value) => {
    // aqui conta os pontos 18 é cnpj e 14 é cpf
    if (value.length === 18) {
      return FormHelper.validateCNPJ(value);
    } else if (value.length === 14) {
      return FormHelper.validateCPF(value);
    }

    return false;
  },
  validateAge: ({date,minAge,maxAge}) => {
    if(!date){
      return false;
    }
    // verifica se a data é valida com Moment
    const isValidDate = moment(date, 'DD-MM-YYYY', false).isValid();
    if (!isValidDate) {
      return false;
    }

    if(date.search('-') === -1)
      date = date.split('/').reverse().join('-');

    // precisa ter formato YYYY-MM-DD
    if(date.match(/^\d{4}-\d{2}-\d{2}$/) === null){
      return false;
    }
      
    var today = new Date();
    var birthDate = new Date(date);
    let day = birthDate.getDate();
    // ajusta a data para + 1 (nao sei pq quando insere a data vem 1 dia a menos - no DD/)
    birthDate.setDate(day + 1);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    
    // verifica se completou os meses
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    // verifica se a idade corresponde aos parametros inseridos.
    return (minAge === undefined || minAge < age) && (maxAge === undefined || age < maxAge)
    },
  // verifica todo tipo de firstname e lastname (somente texto com min e max de caracteres )
  validateName: (name) => {
    if(FormHelper.isNumeric(name) || name.length < 2 || name.length >= 40){
      return false
      }
    return name
    },

  validateRequiredFields: (inputs,data) => {
    let aInputs = Object.entries(inputs);
    let requiredFields = [];
    aInputs.every(aInput => {
      if(aInput[1].isRequired)
      requiredFields.push(aInput[0]);
      return true;
    })
    
    let aData = Object.entries(data);

    for(let i=0;i<aData.length;i++){
      let index = requiredFields?.indexOf(aData[i][0]);
      if(index !== -1){
        requiredFields.splice(index,1);
      }
    }
    
    if(requiredFields.length > 0){
      return false;
    }

    return true;
  },
  // Detecta se string tem padrão de e-mail, cpf, cnpj ou um simples texto
  detectStringContent: (string) => {
    // cpf?
    if(/^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/g.test(string)){
      // parece cpf
      return 'cpf';
    }
    // cnpj?
    else if(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/g.test(string)){
      // parece cnpj
      return 'cnpj';
    }
    else if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(string)){
      // parece e-mail
      return 'email';
    }

    return 'text';
  },
  getAddressByCEP: async (cep) => {
    let result = {
      isComplete: false,
      isValid: false,
      address: {},
    }

    if(!cep || typeof cep !== 'string')
      return result;
    
    cep = cep.replace(/\D/g,'');

    if(cep.length !== 8)
      return result;
    
    var response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    
    if(response.ok){
      let jresponse = await response.json();

      if(jresponse.localidade){
        let address = {
          street: jresponse.logradouro,
          complement: jresponse.complemento,
          neighborhood: jresponse.bairro,
          city: jresponse.localidade,
          uf: jresponse.uf,
        }

        return {
          isComplete: true,
          isValid: true,
          address,
        }
      }
    }

    return {
      isComplete: true,
      isValid: false,
      address: {},
    }
  }
}

export default FormHelper;