import { collection, onSnapshot, query } from 'firebase/firestore';
import { createContext, useState, useEffect, useCallback } from 'react';
import { useCreateContactAction } from '../hooks/useCreateContactAction';
import db from '../config/db/firebasedb';

export const FavoritesAndRecommendationsContext = createContext({});

export const FavoritesAndRecommendationsProvider = ({ children, user, userHelper }) => {
  const [favoritesAndRecommendations, setFavoritesAndRecommendations] = useState({});
  const { createContactAction } = useCreateContactAction();

  const fieldName = {
    campaigns: 'Campaign__c',
    campaignItems: 'CampaignItem__c',
    products: 'Product__c',
    partners: 'tvs_Partner__c',
  }

  const getFavoritesAndRecommendations = useCallback(async () => {
    const favoritesAndRecommendations = query(
      collection(db, '/Users/' + user.FirebaseId__c + '/FavoritesAndRecommendations/')
    );

    onSnapshot(favoritesAndRecommendations, (snapshot) => {
      const favorites = {
        campaigns: [],
        campaignItems: [],
        products: [],
        partners: [],
      }

      const recommendations = {
        campaigns: [],
        campaignItems: [],
        products: [],
        partners: [],
      }

      snapshot.forEach((doc) => {
        const item = doc.data();

        if (item?.Type__c === 'Favorito') {
          item?.Campaign__c && favorites.campaigns.push(item?.Campaign__c);
          item?.CampaignItem__c && favorites.campaignItems.push(item?.CampaignItem__c);
          item?.Product__c && favorites.products.push(item?.Product__c);
          item?.Partner__c && favorites.partners.push(item?.Partner__c);
        }

        if (item?.Type__c === 'Recomendação') {
          item?.Campaign__c && recommendations.campaigns.push(item?.Campaign__c);
          item?.CampaignItem__c && recommendations.campaignItems.push(item?.CampaignItem__c);
          item?.Product__c && recommendations.products.push(item?.Product__c);
          item?.Partner__c && recommendations.partners.push(item?.Partner__c);
        }
      });

      setFavoritesAndRecommendations({
        favorites,
        recommendations,
      });
    });
  }, [user.FirebaseId__c]);

  useEffect(() => {
    if (user) getFavoritesAndRecommendations();
  }, [getFavoritesAndRecommendations]);

  const addFavorite = (type, object) => {
    setFavoritesAndRecommendations({
      ...favoritesAndRecommendations,
      favorites: {
        ...favoritesAndRecommendations.favorites,
        [type]: [...favoritesAndRecommendations.favorites[type], object?.Id]
      },
    });

    const dataAction = {
      userDataHelper: userHelper,
      cgny2__Action__c: 'ACTION_FAVORITE',
      cgny2__GenericId__c: object?.Id,
      cgny2__Value__c: object?.Name,
      [fieldName[type]]: object?.Id,
    };

    createContactAction(dataAction);
  }

  const removeFavorite = (type, object) => {
    setFavoritesAndRecommendations({
      ...favoritesAndRecommendations,
      favorites: {
        ...favoritesAndRecommendations.favorites,
        [type]: favoritesAndRecommendations.favorites[type].filter((item) => item !== object?.Id),
      },
    });

    const dataAction = {
      userDataHelper: userHelper,
      cgny2__Action__c: 'ACTION_UNFAVORITE',
      cgny2__GenericId__c: object?.Id,
      cgny2__Value__c: object?.Name,
      [fieldName[type]]: object?.Id,
    };

    createContactAction(dataAction);
  }

  return (
    <FavoritesAndRecommendationsContext.Provider
      value={{
        favoritesAndRecommendations,
        addFavorite,
        removeFavorite,
      }}
    >
      {children}
    </FavoritesAndRecommendationsContext.Provider>
  );
}
