import FormHelper from '../components/utils/FormHelper';

/* configuração para formulário do usuário */
const Userform = (props, startValues) => {
  // let { editProfile, data = {}, message } = props;
  let { editProfile, userUniversoTotvs } = props;
  let usernameType = startValues?.Username
    ? FormHelper.detectStringContent(startValues.Username)
    : 'email';
  // let profileCode = data.user?.acg_ProfileCode__c;

  switch (usernameType) {
    case 'cpf':
    case 'cnpj':
      startValues.CNPJ_CPF__c = startValues.Username;
      break;
    case 'email':
      startValues.Email = startValues.Username;
      break;
  }

  let form = {
    Content1: FormHelper.useFormInput({
      type: 'content',
      content: 'Os itens com * são obrigatórios',
      // size: {
      //   desktop: 4,
      //   tablet: 4,
      // },
    }),
    // CNPJ_CPF__c: FormHelper.useFormInput({
    //   id: 'cnpj',
    //   label: props.config.strings.form.label.CNPJ,
    //   type: 'text',
    //   validations: [
    //     {
    //       type: 'required',
    //       errorMessage: props.config.strings.form.validation.EMPTY_CNPJ,
    //     },
    //     {
    //       type: 'cnpj',
    //       errorMessage: props.config.strings.form.validation.INVALID_CNPJ,
    //     },
    //   ],
    //   // inputOptions: {
    //   //   title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
    //   //   disabled: editProfile || usernameType === 'cpf' || usernameType === 'cnpj' ? 'disabled' : false,
    //   //   inputMode: 'numeric',
    //   //   spellCheck: false,
    //   // },
    //   mask: 'username',
    //   maskOptions: ['cnpj'],
    //   startValue: startValues?.CNPJ_CPF__c,
    //   size: {
    //     desktop: 8,
    //     tablet: 8,
    //   },
    //   inputOptions: {
    //     title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
    //     disabled: editProfile ? 'disabled' : false,
    //     spellCheck: false,
    //   },
    // }),

    CNPJ_CPF__c: FormHelper.useFormInput({
      id: 'cnpjAndCpf',
      label: props.config.strings.form.label.CNPJ_CPF,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CPFCNPJ,
        },
        {
          type: 'cnpjAndCpf',
          errorMessage: props.config.strings.form.validation.INVALID_CPFCNPJ,
        },
      ],
      mask: 'username',
      maskOptions: ['text', 'cpf', 'cnpj'],
      startValue: startValues?.CNPJ_CPF__c,
      size: {
        desktop: 8,
        tablet: 8,
      },
      inputOptions: {
        title: editProfile
          ? 'Para alterar esta informação, por favor, entre em contato.'
          : null,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
      },
    }),

    FirstName: FormHelper.useFormInput({
      id: 'firstName',
      label: props.config.strings.form.label.FIRST_NAME,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_FIRST_NAME,
        },
        {
          type: 'name',
          errorMessage: props.config.strings.form.validation.INVALID_NAME,
        },
      ],
      size: {
        desktop: 8,
        tablet: 8,
      },
      inputOptions: {
        title: editProfile
          ? 'Para alterar esta informação, por favor, entre em contato.'
          : null,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
      },
      startValue: startValues?.FirstName,
    }),

    LastName: FormHelper.useFormInput({
      id: 'lastName',
      label: props.config.strings.form.label.LAST_NAME,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_LAST_NAME,
        },
        {
          type: 'name',
          errorMessage: props.config.strings.form.validation.INVALID_NAME,
        },
      ],
      size: {
        desktop: 8,
        tablet: 8,
      },
      inputOptions: {
        title: editProfile
          ? 'Para alterar esta informação, por favor, entre em contato.'
          : null,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
      },
      startValue: startValues?.LastName,
    }),

    CPF__c: FormHelper.useFormInput({
      id: 'cpf',
      label: props.config.strings.form.label.CPF,
      type: 'cpf',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CPF,
        },
        {
          type: 'cpf',
          errorMessage: props.config.strings.form.validation.INVALID_CPF,
        },
      ],
      inputOptions: {
        disabled: editProfile ? 'disabled' : false,
      },
      // inputOptions: {
      //   title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
      //   disabled: editProfile || usernameType === 'cpf' || usernameType === 'cnpj' ? 'disabled' : false,
      //   inputMode: 'numeric',
      //   spellCheck: false,
      // },
      mask: 'username',
      maskOptions: ['cpf'],
      startValue: startValues?.CNPJ_CPF__c,
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),

    LoyaltyBirthdate__c: FormHelper.useFormInput({
      id: 'age',
      label: props.config.strings.form.label.BIRTH_DATE,
      type: 'text',
      validations: [
        //   {
        //     type: 'required',
        //     errorMessage: props.config.strings.form.validation.EMPTY_BIRTH_DATE,
        //   },
        {
          type: 'age',
          config: { minAge: 18, maxAge: 103 },
          errorMessage: props.config.strings.form.validation.INVALID_DATE,
        },
      ],
      mask: 'date',
      inputOptions: {
        spellCheck: false,
        inputMode: 'numeric',
      },
      startValue: startValues?.LoyaltyBirthdate__c,
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),

    Email: FormHelper.useFormInput({
      id: 'email',
      label: props.config.strings.form.label.EMAIL_REQUIRED,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_EMAIL,
        },
        {
          type: 'email',
          errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
        },
      ],
      inputOptions: {
        // disabled: usernameType === 'email' ? 'disabled' : false,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
      },
      mask: 'email',
      // inputOptions: {
      //   title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
      //   disabled: editProfile ? 'disabled' : false,
      //   spellCheck: false,
      // },
      startValue: startValues?.Email,
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),

    LoyaltyPhone__c: FormHelper.useFormInput({
      id: 'phone',
      label: props.config.strings.form.label.PHONE,
      type: 'text',
      inputOptions: {
        inputMode: 'numeric',
        spellCheck: false,
      },
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_PHONE,
        },
        {
          type: 'phone',
          errorMessage: props.config.strings.form.validation.INVALID_PHONE,
        },
      ],
      mask: 'phone',
      startValue: startValues?.LoyaltyPhone__c,
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),

    LoyaltyPostalCode__c: FormHelper.useFormInput({
      id: 'postalCode',
      hideOn: ['create'],
      label: props.config.strings.form.label.CEP,
      type: 'text',
      validations: [
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.INVALID_ZIPCODE,
          customFunction: async (formInput, validation, aInputs) => {
            let cep = formInput.getValue();
            let result = await FormHelper.getAddressByCEP(cep);

            return cep.length === 0 || result.isValid;
          },
        },
      ],
      inputOptions: {
        spellCheck: false,
      },
      size: {
        desktop: 4,
        tablet: 4,
      },
      mask: 'cep',
      startValue: startValues?.LoyaltyPostalCode__c,
    }),

    loyaltyStreet: FormHelper.useFormInput({
      id: 'street',
      hideOn: ['create'],
      label: props.config.strings.form.label.STREET,
      type: 'text',
      inputOptions: {
        spellCheck: false,
      },
      size: {
        desktop: 4,
        tablet: 4,
      },
      startValue: startValues?.loyaltyStreet,
    }),

    // acg_AddressDistrict__c: FormHelper.useFormInput({
    //   id: 'district',
    //   hideOn: ['create'],
    //   label: props.config.strings.form.label.DISTRICT,
    //   type: 'text',
    //   inputOptions: {
    //     spellCheck: false,
    //   },
    //   size: {
    //     desktop: 4,
    //     tablet: 4,
    //   },
    //   startValue: startValues?.acg_AddressDistrict__c,
    // }),

    numberApartament: FormHelper.useFormInput({
      id: 'numberApartament',
      hideOn: ['create'],
      label: 'Número',
      type: 'text',
      inputOptions: {
        spellCheck: false,
      },
      size: {
        desktop: 4,
        tablet: 4,
      },
      // startValue: startValues?.acg_AddressDistrict__c,
    }),

    Username: FormHelper.useFormInput({
      id: 'username',
      label: props.config.strings.form.label.USERNAME_TEXT,
      type: usernameType === 'text' ? 'text' : 'hidden',
      inputOptions: {
        disabled: 'disabled',
        spellCheck: false,
      },
      size: {
        desktop: 4,
        tablet: 4,
      },
      startValue: startValues?.Username,
    }),

    acg_AddressComplement__c: FormHelper.useFormInput({
      id: 'addressComplement',
      hideOn: ['create'],
      label: props.config.strings.form.label.COMPLEMENT,
      type: 'text',
      inputOptions: {
        spellCheck: false,
      },
      size: {
        desktop: 4,
        tablet: 4,
      },
      startValue: startValues?.acg_AddressComplement__c,
    }),

    LoyaltyState__c: FormHelper.useFormInput({
      id: 'state',
      label: props.config.strings.form.label.STATE,
      type: 'select',
      // validations: [{
      //   type: 'required',
      //   errorMessage: props.config.strings.form.validation.EMPTY_STATE,
      // }],
      options: [
        { value: 'AC', label: 'Acre' },
        { value: 'AL', label: 'Alagoas' },
        { value: 'AP', label: 'Amapá' },
        { value: 'AM', label: 'Amazonas' },
        { value: 'BA', label: 'Bahia' },
        { value: 'CE', label: 'Ceará' },
        { value: 'DF', label: 'Distrito Federal' },
        { value: 'ES', label: 'Espírito Santo' },
        { value: 'GO', label: 'Goías' },
        { value: 'MA', label: 'Maranhão' },
        { value: 'MT', label: 'Mato Grosso' },
        { value: 'MS', label: 'Mato Grosso do Sul' },
        { value: 'MG', label: 'Minas Gerais' },
        { value: 'PA', label: 'Pará' },
        { value: 'PB', label: 'Paraíba' },
        { value: 'PR', label: 'Paraná' },
        { value: 'PE', label: 'Pernambuco' },
        { value: 'PI', label: 'Piauí' },
        { value: 'RJ', label: 'Rio de Janeiro' },
        { value: 'RN', label: 'Rio Grande do Norte' },
        { value: 'RS', label: 'Rio Grande do Sul' },
        { value: 'RO', label: 'Rondônia' },
        { value: 'RR', label: 'Roraíma' },
        { value: 'SC', label: 'Santa Catarina' },
        { value: 'SP', label: 'São Paulo' },
        { value: 'SE', label: 'Sergipe' },
        { value: 'TO', label: 'Tocantins' },
      ],
      inputOptions: {
        spellCheck: false,
      },
      size: {
        desktop: 8,
        tablet: 8,
      },
      startValue: startValues?.LoyaltyState__c,
    }),

    LoyaltyCity__c: FormHelper.useFormInput({
      id: 'city',
      label: props.config.strings.form.label.CITY,
      type: 'select',
      // validations: [{
      //   type: 'required',
      //   errorMessage: props.config.strings.form.validation.EMPTY_CITY,
      // }],
      inputOptions: {
        spellCheck: false,
      },
      size: {
        desktop: 8,
        tablet: 8,
      },
      startValue: startValues?.LoyaltyCity__c,
    }),

    Department: FormHelper.useFormInput({
      id: 'Department',
      label: 'Departamento *',
      type: editProfile ? 'text' : 'select',
      options: [
        {
          value: 'Comercial',
          label: 'Comercial',
        },
        {
          value: 'Financeiro',
          label: 'Financeiro',
        },
        {
          value: 'Marketing',
          label: 'Marketing',
        },
        {
          value: 'Operações',
          label: 'Operações',
        },
        {
          value: 'Produção',
          label: 'Produção',
        },
        {
          value: 'RH',
          label: 'RH',
        },
        {
          value: 'TI',
          label: 'TI',
        },
        {
          value: 'Outros',
          label: 'Outros',
        },
      ],
      validations: editProfile
        ? null
        : [
            {
              type: 'required',
              errorMessage:
                props.config.strings.form.validation.EMPTY_DEPARTMENT,
            },
          ],
      inputOptions: {
        title: editProfile
          ? 'Para alterar esta informação, por favor, entre em contato.'
          : '',
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
      },
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),

    PersonDepartment: FormHelper.useFormInput({
      id: 'userCategory',
      label: 'Cargo *',
      type: editProfile ? 'text' : 'select',
      options: [
        {
          value: 'Analista',
          label: 'Analista',
        },
        {
          value: 'Especialista',
          label: 'Especialista',
        },
        {
          value: 'Coordenador (ou Líder de Equipe)',
          label: 'Coordenador (ou Líder de Equipe)',
        },
        {
          value: 'Supervisor (ou Chefe de Setor)',
          label: 'Supervisor (ou Chefe de Setor)',
        },
        {
          value: 'Gerente (ou Gerente de Departamento)',
          label: 'Gerente (ou Gerente de Departamento)',
        },
        {
          value: 'Diretor (ou Diretor de Área)',
          label: 'Diretor (ou Diretor de Área)',
        },
        {
          value: 'Vice-presidente',
          label: 'Vice-presidente',
        },
        {
          value: 'Presidente',
          label: 'Presidente',
        },
        {
          value: 'CEO (Chief Executive Officer)',
          label: 'CEO (Chief Executive Officer)',
        },
        {
          value: 'CFO (Chief Financial Officer)',
          label: 'CFO (Chief Financial Officer)',
        },
        {
          value: 'CMO (Chief Marketing Officer)',
          label: 'CMO (Chief Marketing Officer)',
        },
        {
          value: 'CTO (Chief Technology Officer)',
          label: 'CTO (Chief Technology Officer)',
        },
        {
          value: 'COO (Chief Operating Officer)',
          label: 'COO (Chief Operating Officer)',
        },
        {
          value: 'CIO (Chief Information Officer)',
          label: 'CIO (Chief Information Officer)',
        },
        {
          value: 'CHRO (Chief Human Resources Officer)',
          label: 'CHRO (Chief Human Resources Officer)',
        },
        {
          value: 'CCO (Chief Communications Officer)',
          label: 'CCO (Chief Communications Officer)',
        },
      ],
      validations: editProfile
        ? null
        : [
            {
              type: 'required',
              errorMessage:
                props.config.strings.form.validation.EMPTY_USER_CATEGORY,
            },
          ],
      size: {
        desktop: 8,
        tablet: 8,
      },
      inputOptions: {
        title: editProfile
          ? 'Para alterar esta informação, por favor, entre em contato.'
          : '',
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
      },
      startValue: startValues?.LoyaltyCategory__c,
    }),

    LoyaltyCategory__c: FormHelper.useFormInput({
      id: 'userCategory',
      label: props.config.strings.form.label.USER_CATEGORY,
      type: editProfile ? 'text' : 'select',
      options: [
        {
          value: 'Colaborador',
          label: 'Colaborador',
        },
        {
          value: 'Gestor',
          label: 'Gestor',
        },
        {
          value: 'Diretor',
          label: 'Diretor',
        },
      ],
      validations: editProfile
        ? null
        : [
            {
              type: 'required',
              errorMessage:
                props.config.strings.form.validation.EMPTY_USER_CATEGORY,
            },
          ],
      size: {
        desktop: 8,
        tablet: 8,
      },
      inputOptions: {
        title: editProfile
          ? 'Para alterar esta informação, por favor, entre em contato.'
          : '',
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
      },
      startValue: startValues?.LoyaltyCategory__c,
    }),
    promotionalCode: FormHelper.useFormInput({
      id: 'promotionalCode',
      label: 'Código Promocional',
      type: userUniversoTotvs ? 'hidden' : 'text',
      hideOn: ['update'],
      size: {
        desktop: 8,
        tablet: 8,
      },
      startValue: props?.promotionalCode,
    }),

    Password: FormHelper.useFormInput({
      id: 'password',
      hideOn: ['update'],
      label: props.config.strings.form.label.PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_PASSWORD,
        },
      ],
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),

    PasswordConfirmation: FormHelper.useFormInput({
      id: 'password-confirmation',
      hideOn: ['update'],
      label: props.config.strings.form.label.CONFIRM_PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage:
            props.config.strings.form.validation.EMPTY_CONFIRM_PASSWORD,
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.IDENTICAL_PASSWORD,
          customFunction: async (formInput, validation, aInputs) => {
            let isValid = true;

            aInputs.every((input) => {
              if (
                input.id === 'password' &&
                input.getValue() !== formInput.getValue()
              ) {
                isValid = false;
                return false;
              }

              return true;
            });

            return isValid;
          },
        },
      ],
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),

    Content2: FormHelper.useFormInput({
      hideOn: ['update'],
      type: 'content',
      content:
        '* A senha deve conter oito caracteres, letra maiúscula, número e símbolo',
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),

    TermsConfirmation: FormHelper.useFormInput({
      id: 'terms-confirmation',
      hideOn: ['update'],
      label: props.config.strings.form.label.TERMS_AND_PRIVACY,
      type: 'checkbox',
      validations: [
        {
          type: 'required',
          errorMessage:
            props.config.strings.form.validation.EMPTY_TERMS_CHECKBOX,
        },
      ],
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),
    PrivacyTerms: FormHelper.useFormInput({
      id: 'terms-confirmation',
      hideOn: ['update'],
      // label: props.config.strings.form.label.TERMS_AND_PRIVACY,
      label: props.config.strings.form.label.PRIVACY_TERMS,
      type: 'checkbox',
      validations: [
        {
          type: 'required',
          errorMessage:
            props.config.strings.form.validation.EMPTY_TERMS_CHECKBOX,
        },
      ],
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),
  };

  form.LoyaltyPostalCode__c.onChange = async (value) => {
    form.LoyaltyPostalCode__c.setError('');
    let result = await FormHelper.getAddressByCEP(value);

    if (result.isValid) {
      // let streetInfo = [result.address.street,result.address.neighborhood];
      // streetInfo = streetInfo.filter(s => s).join(', ');
      form.loyaltyStreet?.setValue(result.address.street);
      form.acg_AddressDistrict__c?.setValue(result.address.neighborhood);
      form.LoyaltyState__c?.setValue(result.address.uf);

      setTimeout(() => {
        form.LoyaltyCity__c?.setValue(result.address.city);
      }, 500);
    } else if (result.isComplete) {
      // está completo e inválido
      form.LoyaltyPostalCode__c.setError('CEP inválido!');
    }
  };

  // if(window.location.pathname == '/meu-cadastro'){
  //   form.LoyaltyCity__c.size.desktop = 4
  //   form.LoyaltyCity__c.size.tablet = 4
  // }

  return form;
};

export default Userform;
