import styled from "@emotion/styled";
import { useState, useEffect, useRef } from "react";
import ImageThumb from "./ImageThumb";

const FormFile = (props) => {
  const { input, config, dataHelper, images, setImages, message, showMaxFileSizeError, maxFileSize} = props;
  const inputRef = useRef(null);
  const [ isImage, setIsImage ] = useState(true);
  const [ filesTypes, setFilesTypes ] = useState([]);

    
 useEffect(async() => {
  const  fileExtensionsUpload = await dataHelper.getGamificationsReceivedExtensionFileUpload();
    const fileExtensionsArray = [];
    fileExtensionsUpload.forEach((fileExtension) => {
      fileExtensionsArray.push(fileExtension.value);
    });
   
    setFilesTypes(fileExtensionsArray);
 },[])
  useEffect(() => {
     input.setValue(JSON.stringify(images));
  },[images])

  const onImageChange = async (ev) => {
   if (ev.target.files && ev.target.files[0]) {
      let img = ev.target.files[0]; // <-- upload
      if(img.size / 1000000 > maxFileSize){
        showMaxFileSizeError(maxFileSize);
        return;
      }
     
      let aImages = [...images];
      
      const imageBlob = URL.createObjectURL(img);
      const imageId = input.inputOptions.imageIdGenerator(img.name);
      const path = input.inputOptions.path;

     setImages([...aImages,{
        loading: true,
        imageBlob,
        path,
        imageId,
      }])

      
      const imageUrl = await dataHelper.uploadFile(img,path,imageId);

     setImages([...aImages,{
        loading: false,
        imageBlob,
        path,
        imageId,
        imageUrl,
      }])
    }    
  }

  const labelClick = (ev) => {
    ev.stopPropagation();
    inputRef.current.click();
  }

  const removeImage = (image) => {
    setImages(a => a.filter(i => i !== image))
    dataHelper.deleteFile(image.path,image.imageId);
  }

  return <StyledFormImage config={config}>
     <input 
      type={'hidden'}
      id={props.id || 'formimage' + Math.floor(Math.random() * 10000)}
      ref={input?.ref}
    /> 
     <a className={'label'} onClick={labelClick}>
      {input.label} <i className={"fa fa-paperclip"} />
      
    </a> 
   
    <input ref={inputRef} className={'file-input'} type="file" name="myImage" accept={filesTypes} onChange={onImageChange}/>
     <div className={'images'}>
      {images?.map((image) => 
        <ImageThumb 
          key={image.imageId}
          config={config}
          imageUrl={image.imageUrl} 
          thumbUrl={image.imageBlob}
          loading={image.loading}
          isImage={image.isImage}
          removeImage={() => removeImage(image)}
        />
         )}
         
    </div> 
        
    
    
  </StyledFormImage>
}

export default FormFile;

const StyledFormImage = styled.div`
  .label{
    color: ${props => props.config?.themeLayout?.link};
    cursor: pointer;
  }

  .file-input{
    position: absolute;
    bottom: -30px;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .images{
    display: flex;
    padding: 15px;
    margin-top: 15px;
  }

  .buttonUpload{
    display: flex;
    border-radius: 50px;
    font-weight: 700;
    padding: 10px 20px;
    font-size: 15px;
    text-align: center;
    width: 100%;

  }
`