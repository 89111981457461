import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  doc,
  getDoc,
  getDocs,
  limit,
  startAfter,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';

export default class DataHelper {
  constructor(app, db, getConfigFile) {
    this.app = app;
    this.db = db;

    getConfigFile(`utils/DataHelper/config.js`).then((importedFile) => {
      this.customCfg = importedFile?.default;
    });
  }

  setUserHelper(userHelper) {
    this.userHelper = userHelper;
    this.userHelper.snapUser((data) => (this.userData = data));
  }

  getList(snapshot, userData = this.userHelper.dataHelper.userData) {
    let list = [];

    snapshot.forEach((doc, index) => {
      let document = doc.data();
      document.Available = false;

      if (userData) {
        // Valida por: LoyaltyAvailable__c ou MilestoneAvailable__c
        const managerAvailable = [
          document?.LoyaltyAvailable__c ||
          document?.HowToEarn__r?.LoyaltyAvailable__c,
          document?.MilestoneAvailable__c ||
          document?.HowToEarn__r?.MilestoneAvailable__c,
        ];
        const validAvailable =
          managerAvailable[0] || managerAvailable[1] || false;
        if (validAvailable) {
          let isVal_LoyaltyAvailable__c = false;
          let isVal_MilestoneAvailable__c = false;

          // Valida por: LoyaltyAvailable__c
          if (
            managerAvailable[0] &&
            managerAvailable[0].indexOf(userData.LoyaltyCategory__c) > -1
          ) {
            isVal_LoyaltyAvailable__c = true;
          }

          // Valida por: MilestoneCategory__c
          if (
            managerAvailable[1] &&
            managerAvailable[1].indexOf(userData.MilestoneCategory__c) > -1
          ) {
            isVal_MilestoneAvailable__c = true;
          }

          if (isVal_LoyaltyAvailable__c && isVal_MilestoneAvailable__c) {
            document.Available = true;
          }
        }

        // Valida por: LoyaltyVisible__c ou MilestoneVisible__c
        const managerVisible = [
          document?.LoyaltyVisible__c ||
          document?.HowToEarn__r?.LoyaltyVisible__c,
          document?.MilestoneVisible__c ||
          document?.HowToEarn__r?.MilestoneVisible__c,
        ];
        const validVisible = managerVisible[0] || managerVisible[1] || false;

        if (validVisible) {
          let isVal_LoyaltyVisible__c = false;
          let isVal_MilestoneCategory__c = false;

          // Valida por: LoyaltyVisible__c
          if (
            managerVisible[0] &&
            managerVisible[0].indexOf(userData.LoyaltyCategory__c) > -1
          ) {
            isVal_LoyaltyVisible__c = true;
          }

          // Valida por: MilestoneCategory__c
          if (
            managerVisible[1] &&
            managerVisible[1].indexOf(userData.MilestoneCategory__c) > -1
          ) {
            isVal_MilestoneCategory__c = true;
          }

          if (isVal_LoyaltyVisible__c && isVal_MilestoneCategory__c) {
            list.push(document);
          }
        } else list.push(document);
      } else list.push(document);
    });
    return list;
  }

  snapMilestones(callback) {
    let milestonesQ = query(
      collection(this.db, 'Milestone'),
      where('Season__c', '==', global.season)
    );

    let unsub = onSnapshot(milestonesQ, (snapshot) => {
      let milestones = this.getList(snapshot);
      callback(milestones);
    });

    return { milestones: unsub };
  }

  async getReferFriendDoc() {
    let simplePointsQ = query(
      collection(this.db, '/HowToEarn/SimplePoint/records/')
    );
    let simplePointsDoc = await getDocs(simplePointsQ);
    let out = null;

    if (simplePointsDoc.docs.length) {
      simplePointsDoc.docs.every((doc) => {
        let docData = doc.data();

        if (docData?.HowToEarn__r?.AppScreen__c === 'ReferFriend') {
          out = docData.HowToEarn__r;
          return false;
        }

        return true;
      });
    }

    return out;
  }

  snapBanners(callback) {
    let bannersQ = query(
      collection(this.db, 'AdvertisingArea'),
      where('Active__c', '==', true)
    );

    let unsub = onSnapshot(bannersQ, (snapshot) => {
      let banners = this.getList(snapshot);
      callback(banners);
    });

    return { banners: unsub };
  }

  snapNews(callback) {
    let newsQ = query(
      collection(this.db, 'News'),
      orderBy('PublishDate__c', 'desc')
    );

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    });

    return { news: unsub };
  }

  snapYTVideos(callback) {
    let newsQ = query(
      collection(this.db, 'Youtube'),
      orderBy('PublishDate__c', 'asc')
    );

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    });

    return { ytvideos: unsub };
  }

  snapTrainings(callback) {
    let newsQ = query(
      collection(this.db, 'Training'),
      orderBy('PublishDate__c', 'desc')
    );

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    });

    return { trainings: unsub };
  }

  snapConfig(config, callback) {
    let configQ = query(collection(this.db, config.table));

    if (config.where?.length) {
      config.where.map((condition) => {
        configQ = query(
          configQ,
          where(condition[0], condition[1], condition[2])
        );
      });
    }

    if (config.order?.length) {
      config.order.map((order) => {
        configQ = query(configQ, orderBy(order[0], order[1]));
      });
    }

    let unsub = onSnapshot(configQ, (snapshot) => {
      let list = this.getList(snapshot);
      callback(list);
    });

    return unsub;
  }

  async getStartUpMessages() {
    const messages = query(
      collection(this.db, 'StartUpMessages'),
      where('Active__c', '==', true)
    );

    const messagesDoc = await getDocs(messages);
    const dataMessage = [];

    messagesDoc?.docs?.map(async (docData) => {
      const dataDoc = docData?.data();
      if (dataDoc) {
        dataMessage.push(dataDoc);
      }
      // return dataMessage
    });

    const sortedDataMessage = dataMessage
      ?.sort((a, b) => a?.Priority__c - b?.Priority__c)
      .slice(0, 1);

    return sortedDataMessage;
  }

  async getNews(qty = 10, startAfterDoc = null) {
    let newsQ = query(
      collection(this.db, 'News'),
      orderBy('PublishDate__c', 'desc'),
      limit(qty)
    );
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      });
    }

    return { data, lastDoc };
  }

  async getYTVideos(qty = 10, startAfterDoc = null) {
    let newsQ = query(
      collection(this.db, 'Youtube'),
      orderBy('PublishDate__c', 'desc'),
      limit(qty)
    );
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      });
    }

    return { data, lastDoc };
  }

  async getTrainings(qty = 10, startAfterDoc = null) {
    let newsQ = query(
      collection(this.db, 'Training'),
      orderBy('PublishDate__c', 'desc'),
      limit(qty)
    );
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      });
    }

    return { data, lastDoc };
  }

  async getSurveys(isQuiz, qty = 10, startAfterDoc = null) {
    let elQ = query(
      collection(this.db, isQuiz ? 'Quiz' : 'Survey'),
      orderBy('Order__c', 'asc'),
      limit(qty)
    );
    if (startAfterDoc) {
      elQ = query(elQ, startAfter(startAfterDoc));
    }
    let elDoc = await getDocs(elQ);

    let data = [];
    let lastDoc = null;

    if (elDoc.docs.length) {
      data = elDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      });
    }

    return { data, lastDoc };
  }

  async getArticle(articleId) {
    let firebaseId = articleId.substr(0, 15);
    let articleQ = doc(this.db, 'News', firebaseId);
    let articleDoc = await getDoc(articleQ);
    if (articleDoc?.exists()) {
      return articleDoc.data();
    }

    articleQ = doc(this.db, 'Youtube', firebaseId);
    articleDoc = await getDoc(articleQ);
    if (articleDoc?.exists()) {
      return articleDoc.data();
    }

    articleQ = doc(this.db, 'Survey', firebaseId);
    articleDoc = await getDoc(articleQ);
    if (articleDoc?.exists()) {
      return articleDoc.data();
    }

    articleQ = doc(this.db, 'Quiz', firebaseId);
    articleDoc = await getDoc(articleQ);
    if (articleDoc?.exists()) {
      return articleDoc.data();
    }

    return false;
  }

  async getSurvey(surveyId, category = 'Survey') {
    if (!surveyId) return false;

    let firebaseId = surveyId.substr(0, 15);
    let surveyQ = doc(this.db, category, firebaseId);
    let surveyDoc = await getDoc(surveyQ);

    if (surveyDoc?.exists()) {
      return surveyDoc.data();
    }

    return false;
  }

  async getSurveyQuestions(surveyId, category = 'Survey') {
    if (!surveyId) return false;

    let firebaseId = surveyId.substr(0, 15);
    let questionsQ = query(
      collection(this.db, '/' + category + '/' + firebaseId + '/Question/'),
      orderBy('Order__c')
    );
    let questionsDoc = await getDocs(questionsQ);
    let data = [];

    if (questionsDoc.docs.length) {
      data = questionsDoc.docs.map((doc) => {
        return doc.data();
      });
    }

    return data;
  }

  async getPrivacyText(callback) {
    let privacyQ = doc(this.db, 'AppSetting', 'PrivacyPolicy');
    let privacyDoc = await getDoc(privacyQ);

    if (privacyDoc?.exists() && privacyDoc?.data()['HtmlValue__c']) {
      callback(privacyDoc.data()['HtmlValue__c']);
    } else {
      callback('');
    }
  }

  async getTermsText(callback) {
    let termsQ = doc(this.db, 'AppSetting', 'TermsOfService');
    let termsDoc = await getDoc(termsQ);

    if (termsDoc?.exists() && termsDoc?.data()['HtmlValue__c']) {
      callback(termsDoc.data()['HtmlValue__c']);
    } else {
      callback('');
    }
  }

  async getHerokuDomain() {
    let urlQ = doc(this.db, 'AppSetting', 'HerokuUrl');
    let urlDoc = await getDoc(urlQ);

    if (urlDoc.exists() && urlDoc.data().TextValue__c) {
      return urlDoc.data().TextValue__c;
    }

    return false;
  }

  async getHerokuUrl(path, isRest = true) {
    if (!global.herokuDomain) {
      global.herokuDomain = await this.getHerokuDomain();
    }

    let url = '';

    if (global.herokuDomain) {
      url = global.herokuDomain;

      if (isRest) {
        url += '/restHelper';
      }

      url += path;
    }

    return url;
  }

  async loadCurrentSeasonId() {
    let seasonQ = doc(this.db, 'AppSetting', 'CurrentSeason');
    let seasonDoc = await getDoc(seasonQ);

    let season = '';

    if (seasonDoc.exists() && seasonDoc.data().TextValue__c) {
      season = JSON.parse(seasonDoc.data().TextValue__c)?.Id;
    }

    global.season = season;

    return season;
  }

  async getRewardFilters() {
    let filtersQ = doc(this.db, 'AppSetting', 'FilterProductOptions');
    let filtersDoc = await getDoc(filtersQ);

    let filters = [];

    if (filtersDoc?.exists() && filtersDoc?.data()['TextValue__c']) {
      filters = JSON.parse(filtersDoc.data()['TextValue__c']);
    }

    return filters;
  }

  async getCaseTypes(callback) {
    let casesQ = doc(this.db, 'AppSetting', 'CaseSettings');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      let objTextValue = JSON.parse(casesDoc.data()['TextValue__c']);
      if (objTextValue.types) {
        callback(objTextValue.types);
        return true;
      }
    }

    callback([]);
    return false;
  }

  async getGamificationsReceivedDataMaxFileSize() {
    let gamificationsReceivedQ = doc(
      this.db,
      'AppSetting',
      'GamificationsReceivedSettings'
    );
    let gamificationsReceivedDoc = await getDoc(gamificationsReceivedQ);
    if (
      gamificationsReceivedDoc?.exists() &&
      gamificationsReceivedDoc?.data()['TextValue__c']
    ) {
      let objTextValue = JSON.parse(
        gamificationsReceivedDoc.data()['TextValue__c']
      );

      return objTextValue?.fileMaxSize?.size;
    }
  }

  async getGamificationsReceivedExtensionFileUpload() {
    let gamificationsReceivedQ = doc(
      this.db,
      'AppSetting',
      'GamificationsReceivedSettings'
    );
    let gamificationsReceivedDoc = await getDoc(gamificationsReceivedQ);
    if (
      gamificationsReceivedDoc?.exists() &&
      gamificationsReceivedDoc?.data()['TextValue__c']
    ) {
      let objTextValue = JSON.parse(
        gamificationsReceivedDoc.data()['TextValue__c']
      );

      return objTextValue?.extensionFileUpload;
    }
  }

  async getGamificationsReceivedData(callback, isType, fileMaxSize) {
    let gamificationsReceivedQ = doc(
      this.db,
      'AppSetting',
      'GamificationsReceivedSettings'
    );
    let gamificationsReceivedDoc = await getDoc(gamificationsReceivedQ);
    if (
      gamificationsReceivedDoc?.exists() &&
      gamificationsReceivedDoc?.data()['TextValue__c']
    ) {
      let objTextValue = JSON.parse(
        gamificationsReceivedDoc.data()['TextValue__c']
      );
      if (fileMaxSize) return objTextValue.fileMaxSize;
      if (isType) {
        callback(objTextValue.types);
        return true;
      } else {
        callback(objTextValue.status);
        return true;
      }
    }
    callback([]);
    return false;
  }

  async getCaseRecordTypeId() {
    let casesQ = doc(this.db, 'AppSetting', 'CaseSettings');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      let objTextValue = JSON.parse(casesDoc.data()['TextValue__c']);
      return objTextValue.recordType;
    }

    return false;
  }

  async getQuestions() {
    let casesQ = doc(this.db, 'AppSetting', 'Questions');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      // if (casesDoc?.exists() && casesDoc?.data()['HtmlValue__c']) {
      // const caseHTMLValue = casesDoc?.data().HtmlValue__c;
      const caseTextValue__c = casesDoc?.data().TextValue__c;
      // let objTextValue = JSON.parse(casesDoc.data()['HtmlValue__c']);
      return caseTextValue__c;
    }

    return [];
  }

  async getRegistrationFilter() {
    let topicsQ = query(collection(this.db, 'Department'));
    let topicsDocs = await getDocs(topicsQ);
    let topics = { writelist: [] };

    if (topicsDocs.size > 0) {
      topicsDocs.forEach((doc) => {
        let docData = doc.data();
        topics.writelist.push({
          cnpj: docData.tvs_Account__r.CNPJ_CPF__c,
          domain: docData.tvs_Account__r.Tvs_EmailDomain__c,
        });
      });
    }
    return topics;
  }

  async getPromeCode() {
    const collectionVoucher = query(collection(this.db, 'Voucher'));
    const querySnapshot = await getDocs(collectionVoucher);
    const promocodes = [];
    querySnapshot.forEach((doc) => {
      promocodes.push(doc.data());
    });
    return promocodes;
  }

  async uploadFile(img, path, imageId, contentType) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}`);
    const metadata = { contentType: contentType };

    const snapshot = await uploadBytes(storageRef, img, metadata);
    const url = await getDownloadURL(storageRef);

    return url;
  }

  async deleteFile(path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}`);

    try {
      await deleteObject(storageRef);
      return true;
    } catch (e) {
      return false;
    }
  }

  async uploadImage(img, path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}.jpeg`);
    const metadata = { contentType: 'image/jpeg' };

    const snapshot = await uploadBytes(storageRef, img, metadata);
    const url = await getDownloadURL(storageRef);

    return url;
  }

  async deleteImage(path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}.jpeg`);

    try {
      await deleteObject(storageRef);
      return true;
    } catch (e) {
      return false;
    }
  }

  async getAccountByCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    let accountQ = query(
      collection(this.db, 'Account'),
      where('TaxID__c', '==', cnpj)
    );
    let cnpjDoc = await getDocs(accountQ);

    let data = this.docsToArray(cnpjDoc);

    if (data.length) return data[0];

    return false;
  }

  // Uma conta raíz é aquela que possui os 8 primeiros dígitos iguais às suas contas filhas
  // e possui ParentId === null (ou sequer possui tal parâmetro)
  // Além disso, a conta deve ser elegível para registrar NFs (acg_ElegivelNF__c)
  async getParentAccountByCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    let rootCnpj = cnpj.slice(0, 8);
    let accountQ = query(
      collection(this.db, 'Account'),
      where('acg_TaxIdStarted__c', '==', rootCnpj),
      where('acg_ElegivelNF__c', '==', true)
    );
    let cnpjDoc = await getDocs(accountQ);

    let data = this.docsToArray(cnpjDoc);

    if (data.length) {
      let result = false;

      data.map((item) => {
        if (!item.ParentId) {
          result = item;
        }
      });
      return result;
    }

    return false;
  }

  async getTermVersions(loyaltyCategory) {
    let termsQ = query(
      collection(this.db, 'AcceptanceTermVersion'),
      where('acg_IsActive__c', '==', true)
      // ,where('Loyalty_Category__c','==',loyaltyCategory)
      // ,orderBy('CreatedDate','asc')
    );
    let termsDoc = await getDocs(termsQ);

    let data = [];

    if (termsDoc.docs.length) {
      data = termsDoc.docs.map((doc) => {
        let docData = doc.data();

        if (docData?.LoyaltyCategory__c?.includes(loyaltyCategory))
          return docData;
      });
    }

    data = data.filter((d) => d);

    return data;
  }

  dateDisplayFormat(date) {
    if (date?.length && date?.search('-') !== -1) {
      return date.split('-').reverse().join('/');
    }

    return date;
  }

  appScreenToUrl(routes, appScreen, id = '') {
    switch (appScreen) {
      case 'Profile':
        return routes.profile.path + '/' + id;
      case 'Survey':
        return routes.survey.path + '/' + id;
      case 'ReceiptHistory':
        return '/';
      case 'NewsScreen':
        return '/';
      case 'ProfileUpdate':
        return routes.profile.path + '/' + id;
      case 'News':
        return routes.news.path + '/' + id;
      case 'Quiz':
        return routes.quiz.path + '/' + id;
      case 'Video':
        return routes.news.path + '/' + id;
      case 'Upload':
        return routes.uploadDocs.path;
      case 'AccountUpdate':
        return routes.profile.path + '/' + id;
      case 'DownloadFiles':
        return routes.download.path + '/' + id;
      case 'Redemption':
        return routes.reward.path + '/' + id;
      default:
        return '/';
    }
  }

  nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    var breakTag =
      is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
    return (str + '').replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      '$1' + breakTag + '$2'
    );
  }

  docsToArray(elDocs) {
    let data = [];

    if (elDocs.docs.length) {
      data = elDocs.docs.map((doc) => {
        return doc.data();
      });
    }

    return data;
  }
}
