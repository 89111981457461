import { useEffect, useState } from "react";
import FormHelper from "../components/utils/FormHelper";
import moment from "moment";

/* configuração para formulário do componente de upload de documentos */

const UploadDocument = (props,startValues) => {
  let maxFileSize = 0;
  
  let statusInput = FormHelper.useFormInput({
    id: 'status',
    label: props.config.strings.form.label.STATUS,
    type: 'select',
    validations: [{
      type: 'required',
      errorMessage: props.config.strings.form.validation.EMPTY_STATUS,
    }],
    startValue: "Pendente",
  })

  let typeInput = FormHelper.useFormInput({
    id: 'type',
    label: props.config.strings.form.label.TYPE,
    type: 'select',
    validations: [{
      type: 'required',
      errorMessage: props.config.strings.form.validation.EMPTY_TYPE,
    }],
    startValue: startValues?.Type,
  })

  useEffect(() => {
   props.dataHelper.getGamificationsReceivedData((caseTypes) => {
     statusInput.setOptions(caseTypes)
     }, false, false);
     props.dataHelper.getGamificationsReceivedData((caseTypes) => {
      typeInput.setOptions(caseTypes)
      }, true, false);
    maxFileSize =  props.dataHelper.getGamificationsReceivedDataMaxFileSize();
    
    
   },[])

  return {
    Content1: FormHelper.useFormInput({
      type: 'content',
      content: 'Os itens com * são obrigatórios',
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),
    DocumentName: FormHelper.useFormInput({
      id: 'name',
      label: props.config.strings.form.label.NAME_DOCUMENT,
      type: 'text',
      validations: [{
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_NAME_DOCUMENT,
      }],
      inputOptions: {
        spellCheck: false,
      },
      startValue: startValues?.Name,
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),
    Type: typeInput,
    Status: statusInput,
    ValidateDocument: FormHelper.useFormInput({
      id: 'subject',
      label: "Validade",
      type: 'date',
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_VALIDATE_DOCUMENT,
      }],
      startValue: moment().format("YYYY-MM-DD"),
      inputOptions: {
        maxLength: 50,
      },
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),
    Images__c: FormHelper.useFormInput({
      id: 'images',
      label: props.config?.strings?.components?.uploadDocs.TITLE_ATTACHMENT,
      type: 'file',
     
     inputOptions: {
        path: 'GamificationsReceivedFiles',
        imageIdGenerator: (FileName) => {
          return "file_gamificationsReceived_" + moment().format("YYYYMMDDhhmmss") + "-" + Math.random().toString().slice(2,11)
          + FileName;
        },
        maxImages: 4,
        maxFileSize: maxFileSize,
      },
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_DOCUMENT,
    }],
    }),
    
    Description: FormHelper.useFormInput({
      id: 'description',
      label: props.config.strings.form.label.DESCRIPTION,
      type: 'textarea',
      validations: [{
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_DESCRIPTION,
      }],
      inputOptions: {
        spellCheck: false,
        maxLength: 250,
      },
      startValue: startValues?.Description,
      size: {
        desktop: 8,
        tablet: 8,
      },
    }),
   
  }
}

export default UploadDocument;